//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsFrequencyAuto extends AbstractParent {

  /**
   * @type {string}
   */
  label = `${___('Deliver and charge every')}:`;

  /**
   * @type {string}
   */
  label_prepaid = `${___('Deliver every')}:`;

  /**
   * @type  {string}
   */
  modal = ___('Are you sure? Your next order date will be recalculated based on the last order date and the new frequency.');

  /**
   * @type  {string}
   */
  success = ___('Your order frequency has been updated');

  /**
   * @type {string}
   */
  prepaid_label = `${___('Deliver every')}:`;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}