//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';

export const NOT_REGULATED = 'NOT_REGULATED';
export const EXACT = 'EXACT';
export const RANGE = 'RANGE';

export default class BoxSize extends AbstractParent {

  /**
   * @type {number}
   */
  id;

  /**
   * @type {number}
   */
  box_id;

  /**
   * @type {EXACT|RANGE|'EXACT'|'RANGE'}
   */
  type = EXACT;

  /**
   * @type {string}
   */
  label;

  /**
   * @type {number|null}
   */
  min = null;

  /**
   * @type {number|null}
   */
  max = null;

  /**
   * @type {boolean}
   */
  disabled = false;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}