/**
 *
 * @type {{add: string, mutate: string, change: string, clear: string, update: string}}
 */
export const CartEvents = {
  add: 'ros-cart:add',
  update: 'ros-cart:update',
  change: 'ros-cart:change',
  clear: 'ros-cart:clear',
  mutate: 'ros-cart:mutate',
};

/**
 *
 * @type {string[]}
 */
const ShopifyCartURLs = [
  '/cart/add',
  '/cart/update',
  '/cart/change',
  '/cart/clear',
  '/cart/add.js',
  '/cart/update.js',
  '/cart/change.js',
  '/cart/clear.js',
];

/**
 *
 * @param url
 * @returns {boolean}
 */
function isShopifyCartURL (url) {
  if (!url) {
    return false;
  }
  const path = url.split('/').pop();
  return ShopifyCartURLs.includes(`/cart/${path}`);
}

/**
 *
 * @param url
 * @returns {string|boolean}
 */
function updateType (url) {
  if (!url) {
    return false;
  }
  if (url.includes('cart/add')) {
    return 'add';
  }
  if (url.includes('cart/update')) {
    return 'update';
  }
  if (url.includes('cart/change')) {
    return 'change';
  }
  if (url.includes('cart/clear')) {
    return 'clear';
  }

  return false;
}

/**
 *
 * @param url
 * @param detail
 */
function dispatchEvent (url, detail) {
  if (typeof detail === 'string') {
    try {
      detail = JSON.parse(detail);
    } catch {
      //
    }
  }

  window.dispatchEvent(new CustomEvent(CartEvents.mutate, { detail }));
  const type = updateType(url);
  switch (type) {
    case 'add':
      window.dispatchEvent(new CustomEvent(CartEvents.add, { detail }));
      break;
    case 'update':
      window.dispatchEvent(new CustomEvent(CartEvents.update, { detail }));
      break;
    case 'change':
      window.dispatchEvent(new CustomEvent(CartEvents.change, { detail }));
      break;
    case 'clear':
      window.dispatchEvent(new CustomEvent(CartEvents.clear, { detail }));
      break;
    default:
      break;
  }
}

/**
 *
 */
function listenXMLHttpRequest () {
  if (!window.XMLHttpRequest) {
    return;
  }

  const originalOpen = window.XMLHttpRequest.prototype.open;
  window.XMLHttpRequest.prototype.open = function (...params) {
    const [, url] = params;
    this.addEventListener('load', function () {
      if (isShopifyCartURL(url)) {
        dispatchEvent(url, this.response);
      }
    });
    return originalOpen.apply(this, params);
  };
}

/**
 *
 */
function listenFetch () {
  if (!window.fetch || typeof window.fetch !== 'function') {
    return;
  }

  const originalFetch = window.fetch;
  window.fetch = function (...params) {
    const response = originalFetch.apply(this, params);

    if (isShopifyCartURL(params[0])) {
      response.then((res) => {
        res
          .clone()
          .json()
          .then((data) => dispatchEvent(res.url, data));
      });
    }

    return response;
  };
}

/**
 *
 */
export function initCartEventListener () {
  listenXMLHttpRequest();
  listenFetch();
}