//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsNextPaymentAuto extends AbstractParent {


  /**
   * @type  {string}
   */
  label = `${___('Next order')}:`;

  /**
   * @type  {string}
   */
  modal = ___('Are you sure? All future order dates will be updated based on the selected date');

  /**
   * @type  {string}
   */
  success = ___('Your next order date has been updated');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}