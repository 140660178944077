import Mustache from 'mustache';
import template from '../templates/subscriptions/popups/common.html';
import closeIcon from '../templates/icons/close.html';
import {CSS_CLASSES} from '../components/subscriptions/TemplatesManeger';

const POPUP_OVERLAY_CLASS = 'spurit-ros__overlay';

export class Popup {

  /**
   *  @type {HTMLElement}
   */
  #overlay;

  /**
   * @type {string}
   */
  #heading;

  /**
   * @type {string}
   */
  #content;

  /**
   * @type {Object[]}
   */
  #actions = [];

  /**
   * @type {function|null}
   */
  #onCloseHandler = null;

  /**
   *
   */
  init = () => {
    this.#overlay = this.#makeOverlay();
    document.body.appendChild(this.#overlay);
    this.#setListeners();
  }

  /**
   * @param {string} heading
   * @returns {Popup}
   */
  setHeading = heading => {
    this.#heading = heading;
    return this;
  };

  /**
   * @param {string} content
   * @returns {Popup}
   */
  setContent = content => {
    this.#content = content;
    return this;
  };

  /**
   * @param {{action: string, primary?: boolean, full_width?: boolean, content: string}[]} actions
   * @returns {Popup}
   */
  setActions = actions => {
    this.#actions = actions;
    return this;
  };

  /**
   * @param {function} handler
   */
  onClose = handler => {
    this.#onCloseHandler = handler;
    return this;
  }

  /**
   * @returns {Popup}
   */
  show = () => {
    if (!this.#overlay) {
      this.init();
    }

    this.#overlay.innerHTML = this.#renderPopup();
    this.#overlay.style.display = 'flex';

    return this;
  }

  /**
   * @returns {Popup}
   */
  hide = () => {
    this.#overlay.style.display = 'none';
    return this;
  }

  /**
   *
   */
  #setListeners = () => {
    this.#overlay.addEventListener('click', e => {
      if (e.target.classList.contains(POPUP_OVERLAY_CLASS) || e.target.closest('[data-action="close-popup"]')) {
        this.hide();
        this.#runOnCloseHandler();
      }
    });
  }

  /**
   * @returns {string}
   */
  #renderPopup = () => {
    return Mustache.render(template, {
      hasActions: this.#actions?.length,
      heading: this.#heading,
      content: this.#content,
      actions: this.#actions,
      classes: CSS_CLASSES,
      close: closeIcon,
      header: true,
    });
  }

  /**
   *
   */
  #runOnCloseHandler = () => {
    if (typeof this.#onCloseHandler === 'function') {
      this.#onCloseHandler();
    }
  }

  /**
   * @returns {HTMLDivElement}
   */
  #makeOverlay () {
    const overlay = document.createElement('div');
    overlay.className = POPUP_OVERLAY_CLASS;
    overlay.style.display = 'none';

    return overlay;
  }
}