/* eslint func-names: 0 */
/**
 * @constructor
 */
export const Env = function () {

  /**
   * @type {string}
   */
  this.appId = process.env.MIX_APP_ID;

  /**
   * @type {string}
   */
  this.appName = process.env.MIX_APP_NAME;

  /**
   * @type {string}
   */
  this.appUrl = process.env.MIX_APP_URL;

  /**
   * @type {string}
   */
  this.initialOrderAtributePrefix = process.env.MIX_INITIAL_ORDER_ATTRIBUTE_PREFIX;

  /**
   * @type {string}
   */
  this.cdnCommonPath = process.env.MIX_CDN_COMMON_URL.replace(/\/+$/u, '') + '/';

  /**
   * @type {string}
   */
  this.cdnStorePath = process.env.MIX_CDN_STORE_URL.replace(/\/+$/u, '') + '/';

  /**
   * @type {string}
   */
  this.cdnJsLibPath = process.env.MIX_CDN_JS_LIBS_PATH.replace(/\/+$/u, '') + '/';
};