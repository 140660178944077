/* eslint func-names: 0 */
import { defaultsDeep } from "../helpers/utils";

/**
 * @param {string} appName
 * @constructor
 */
export const Settings = function (appName) {
  const defaultSettings = {};

  /**
   *
   */
  this.init = () => {
    const isSettingsSet = Settings.isSettingsSet(appName);
    let settings = defaultSettings;
    if (isSettingsSet) {
      settings = defaultsDeep(Spurit[appName].settings, settings);
    } else {
      throw new Error('Snippet settings is empty');
    }

    for (const key in settings) {
      if ({}.hasOwnProperty.call(settings, key)) {
        this[key] = settings[key];
      }
    }
  };
};

/**
 *
 * @param {string} appName
 * @returns {boolean}
 */
Settings.isSettingsSet = appName => (
  typeof (Spurit) !== 'undefined'
  && typeof (Spurit[appName]) !== 'undefined'
  && typeof (Spurit[appName].settings) !== 'undefined'
);