//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SettingsGeneral from './SettingsGeneral';
import SettingsSubscription from './SettingsSubscription';
import SettingsCustomerPortalLabels from './SettingsCustomerPortalLabels';
import SettingsNotifications from './SettingsNotifications';
import SettingsFailedPaymentFlow from './SettingsFailedPaymentFlow';
import SettingsTextLabels from './SettingsTextLabels';
import SettingsCancellationFlow from './SettingsCancellationFlow';
import Integration from "./Integration";

export default class Settings extends AbstractParent {


  /**
   * @type  {SettingsGeneral}
   */
  general;

  /**
   * @type  {SettingsSubscription}
   */
  subscription;

  /**
   * @type  {SettingsCustomerPortalLabels}
   */
  customer_portal_labels;

  /**
   * @type  {SettingsNotifications}
   */
  notifications;

  /**
   * @type  {SettingsFailedPaymentFlow}
   */
  failed_payment_flow;

  /**
   * @type  {SettingsTextLabels}
   */
  text_labels;

  /**
   * @type  {SettingsCancellationFlow}
   */
  cancellation_flow;

  /**
   * @type {Integration[]}
   */
  integrations = [];

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      general: SettingsGeneral,
      subscription: SettingsSubscription,
      customer_portal_labels: SettingsCustomerPortalLabels,
      notifications: SettingsNotifications,
      failed_payment_flow: SettingsFailedPaymentFlow,
      text_labels: SettingsTextLabels,
      cancellation_flow: SettingsCancellationFlow,
      integrations: Integration
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}