import Widget from "./Widget";
import Selectors, {
  CUSTOM_FREQUENCY_NUMBER_SELECTOR, CUSTOM_FREQUENCY_UNIT_SELECTOR, CUSTOM_SELLING_PLAN_SELECTOR,
  SELLING_PLAN_CHECKBOX_SELECTOR, SELLING_PLAN_SELECT_SELECTOR,
  SUBSCRIBE_OPTION_SELECTOR, NOTES_FOR_CUSTOMER_SELECTOR, SELECTED_SELLING_PLAN_SELECTOR, THEME_APP_BLOCK_SELECTOR,
} from "../Selectors";
import resolve from "../../resolve";
import Atc from "../atc/WidgetAtc";
import Buttons from "./Buttons";
import AppConfig from "../../config/config";
import Prices from "./Prices";
import FastCheckout from "./FastCheckout";
import { AppLibrary } from "../../helpers/AppLibrary";
import Storage from "./Storage";
import { WIDGET_TYPES } from "../../classes/SettingsGeneral";
import Api from '../api/Api';

export const CUSTOM_SELLING_PLAN = 'custom';
export const POSITION_BEFORE = 'before';

const widgetStubTemplate = require('../../templates/widget-stub.html');

/**
 *
 */
export default class WidgetManager {

  /**
   *
   * @type {string}
   */
  static WIDGET_WRAPPER_CLASS = 'spurit-ros__wrapper';

  /**
   *
   * @type {string}
   */
  static NOTES_FOR_CUSTOMER_VISIBLE_CLASS = 'spurit-ros__notes-for-customer--visible';

  /**
   *
   * @type {string}
   */
  static DATA_RULE_ID = 'data-rule-id';

  /**
   * @type {number}
   */
  lastSelectedVariant;

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Selectors}
   */
  #selectors;

  /**
   * @type {Buttons}
   */
  #buttons;

  /**
   * @type {Prices}
   */
  #prices;

  /**
   * @type {Storage}
   */
  #storage;

  /**
   * @type {Api}
   */
  #api;

  /**
   * @type {Atc}
   */
  #atc;

  /**
   * @type {FastCheckout}
   */
  #fastCheckout;

  /**
   * @type {number}
   */
  #id;

  /**
   * @type {HTMLElement}
   */
  container;

  /**
   * @type {string}
   */
  position;

  /**
   * @param {HTMLElement} target
   * @param {HTMLElement} atcButton
   * @param {HTMLElement} buyNow
   * @param {string} position
   */
  constructor (target, atcButton, buyNow, position) {
    this.#api = resolve(Api);
    this.#atc = resolve(Atc);
    this.#buttons = resolve(Buttons);
    this.#config = resolve(AppConfig);
    this.#prices = resolve(Prices);
    this.#selectors = resolve(Selectors);
    this.#storage = resolve(Storage);
    this.#fastCheckout = resolve(FastCheckout, [this.#atc]);

    this.#atc.setElements(atcButton);
    this.#init(target, atcButton, buyNow, position);
  }

  /**
   * @returns {HTMLElement}
   */
  get appBlock() {
    return this.container.querySelector(THEME_APP_BLOCK_SELECTOR);
  }

  /**
   * @return {null|HTMLElement}
   */
  get atc () {
    return this.#selectors.getAtcInContainer(this.container);
  }

  /**
   * @return {null|HTMLElement}
   */
  get buyNow () {
    return this.#selectors.getSingleElementInContainer(this.container, Selectors.BUY_NOW_SELECTOR);
  }

  /**
   * @return {HTMLElement[]}
   */
  get prices () {
    const selectors = this.#selectors.get(Selectors.PRICE_INDIVIDUAL_SELECTOR);
    return selectors.length ? Spurit.global.utils.closestElements(this.atc, selectors) : null;
  }

  /**
   * @returns {number}
   */
  get ruleId () {
    const widget = this.#widget;
    return widget ? +widget.dataset.ruleId : null;
  }

  /**
   * @returns {number}
   */
  get selectedSellingPlanId () {
    const widget = this.#widget;
    return widget ? +widget.querySelector(SELECTED_SELLING_PLAN_SELECTOR).value : null;
  }

  /**
   * @return {null|HTMLElement}
   */
  get target () {
    return this.appBlock || this.targetBySelector;
  }

  /**
   * @returns {HTMLElement}
   */
  get targetBySelector() {
    const singleProductSelector = this.#selectors.getSingleProductWidgetSelector();
    const quickViewSelector = this.#selectors.getQuickViewWidgetSelector();
    const selector = quickViewSelector ? `${singleProductSelector}, ${quickViewSelector}` : singleProductSelector;

    return this.container.querySelector(selector);
  }

  /**
   * @param {{callback: Function, event: string}[]} listeners
   * @param {HTMLElement} widget
   */
  addListeners (listeners, widget = null) {
    widget = widget || this.#widget;
    if (!widget) {
      console.error('Unable to attach widget listeners. Can\'t find widget on this page');
      return;
    }

    listeners.forEach(item => widget.addEventListener(item.event, item.callback));
  }

  /**
   * @param {Rule} rule
   * @param {Object} product
   */
  showWidget (rule, product) {
    if (this.#widget) {
      return;
    }

    this.lastSelectedVariant = product.selected_variant.id;

    this.#insertWidget(rule, product);
    this.#initWidget(rule);

    if (!this.#storage.isRuleShown(rule.id)) {
      this.#api.widget.trackView(rule.id, product.id);
      this.#storage.rememberRule(rule.id);
    }
  }

  /**
   * @param {Rule} rule
   * @param {Object} product
   */
  refresh (rule, product) {
    if (!product) {
      this.removeWidget();
      return;
    }

    const widget = this.#widget;

    // if variant not changed
    if (this.lastSelectedVariant === product.selected_variant.id) {
      this.#prices.updateOnPage(this.selectedSellingPlanId, this.prices, this.atc, this.#isSubscriptionOn());
      return;
    }

    this.lastSelectedVariant = product.selected_variant.id;

    if (widget && rule) {
      // if rule and variant changed
      if (rule.id !== this.ruleId) {
        this.resetWidget(rule, product);
        return;
      }

      // if only variant changed update only prices
      this.#prices.updateOnPage(this.selectedSellingPlanId, this.prices, this.atc, this.#isSubscriptionOn());
      this.#prices.updateWidget(this.selectedSellingPlanId, this.#widget, this.atc);
      return;
    }

    // if current variant is linked with rule but corresponding widget is not shown
    if (rule && !widget) {
      this.showWidget(rule, product);
      return;
    }

    // if current variant is not linked with any rule
    if (!rule) {
      if (widget) {
        this.removeWidget();
      }

      this.constructor.showWidgetStub(this.container);
    }
  }

  /**
   *
   */
  removeWidget () {
    const widget = this.#widget;
    if (!widget) {
      return;
    }

    widget.parentElement.remove();
    this.#prices.setRule(null);
    this.#atc.setElements(this.atc);
    this.#updateLinkedElements(false);
  }

  /**
   * @param {Rule} rule
   * @param {Object} product
   */
  resetWidget (rule, product) {
    this.removeWidget();
    this.showWidget(rule, product);
  }

  /**
   * @param {HTMLElement} widget
   * @returns {boolean}
   */
  static isSubscriptionOn (widget) {
    if (!widget) {
      return false;
    }
    if (widget.dataset.type === WIDGET_TYPES.TILES) {
      return +widget.querySelector(SELECTED_SELLING_PLAN_SELECTOR).value !== 0;
    }
    return widget.querySelector(SUBSCRIBE_OPTION_SELECTOR).checked;
  }

  /**
   * @param {HTMLElement|null} container
   */
  static showWidgetStub (container = null) {
    const themeAppBlocks = (container || document).querySelectorAll(THEME_APP_BLOCK_SELECTOR);
    if (!Shopify.designMode || !themeAppBlocks.length) {
      return;
    }

    for (let i = 0; i < themeAppBlocks.length; i++) {
      if (themeAppBlocks[i].firstElementChild) {
        themeAppBlocks[i].firstElementChild.remove();
      }

      themeAppBlocks[i].insertAdjacentHTML('afterbegin', widgetStubTemplate);
    }
  }

  /**
   * @returns {[{callback: function, event: string}, {callback: function, event: string}]}
   */
  get #defaultListeners () {
    return [{
      event: 'input',
      callback: this.#onFrequencyNumberInput,
    }, {
      event: 'change',
      callback: this.#onPurchaseTypeChange,
    }];
  }

  /**
   * @return {HTMLElement}
   */
  get #widget () {
    let wrapper;
    
    const {target} = this;
    if (target.matches(THEME_APP_BLOCK_SELECTOR)) {
      wrapper = target.firstElementChild;
    } else if (this.position === POSITION_BEFORE) {
      wrapper = target.previousElementSibling;
    } else {
      wrapper = target.nextElementSibling;
    }
    
    return wrapper && wrapper.classList.contains(WidgetManager.WIDGET_WRAPPER_CLASS) ? wrapper.firstElementChild : null;
  }

  /**
   * @param {HTMLElement} widget
   */
  #addDefaultListeners (widget = null) {
    this.addListeners(this.#defaultListeners, widget);
  }

  /**
   * @param {HTMLElement} target
   * @param {HTMLElement} atcButton
   * @param {HTMLElement} buyNow
   * @param {string} position
   */
  #init (target, atcButton, buyNow, position) {
    this.container = AppLibrary.findGeneralParent([target, atcButton, buyNow]);
    this.position = position || POSITION_BEFORE;
    this.#id = this.#storage.generateWidgetId();
  }

  /**
   * @param {Rule} rule
   */
  #initWidget (rule) {
    const widget = this.#widget;
    if (!widget) {
      console.error('Invalid widget');
      return;
    }

    let subscriptionSelected = this.#config.settings.general.is_enabled_subscribe || rule.purchase_only_as_subscription;

    const state = this.#storage.getWidgetState(rule.id);
    subscriptionSelected = state?.subscriptionSelected || subscriptionSelected;

    this.#toggleSellingPlans(subscriptionSelected, widget);
    this.#loadCustomFrequency(widget, rule.id);

    const {selectedSellingPlanId, atc} = this;
    this.#prices.setRule(rule);
    this.#prices.updateWidget(selectedSellingPlanId, widget, atc);
    this.#updateLinkedElements(subscriptionSelected);
    this.#addDefaultListeners(widget);

    this.#fastCheckout.init(subscriptionSelected, rule);
    this.#atc.setElements(this.atc, widget).setWidgetListeners();
  }

  /**
   * @param {Rule} rule
   * @param {Object} product
   */
  #insertWidget(rule, product) {
    const wrapper = this.#makeWidget(rule, product);

    const {target} = this;
    if (target.matches(THEME_APP_BLOCK_SELECTOR)) {
      target.append(wrapper);
      return;
    }
    
    if (this.position === POSITION_BEFORE) {
      target.before(wrapper);
    } else {
      target.after(wrapper);
    }
  }

  /**
   * @returns {boolean}
   */
  #isSubscriptionOn = () => WidgetManager.isSubscriptionOn(this.#widget);

  /**
   * @param {HTMLElement} widget
   * @param {number} ruleId
   */
  #loadCustomFrequency (widget, ruleId) {
    const state = this.#storage.getWidgetState(ruleId);
    if (state && state.customFrequency) {
      const numberField = widget.querySelector(CUSTOM_FREQUENCY_NUMBER_SELECTOR);
      if (numberField) {
        numberField.value = state.customFrequency.number;
      }

      const unitField = widget.querySelector(CUSTOM_FREQUENCY_UNIT_SELECTOR);
      if (unitField) {
        unitField.value = state.customFrequency.unit;
      }
    }
  }

  /**
   * @param {Rule} rule
   * @param {Object} product
   * @returns {HTMLDivElement}
   */
  #makeWidget (rule, product) {
    const wrapper = document.createElement('div');
    wrapper.className = WidgetManager.WIDGET_WRAPPER_CLASS;
    wrapper.setAttribute(WidgetManager.DATA_RULE_ID, rule.id.toString());

    const price = this.#prices.getProductPrice(product);
    const format = this.#prices.getMoneyFormat();
    const pricePerDeliveryLabel = this.#config.settings.text_labels.price_per_delivery;
    this.#prices.addPricesToFrequencies(rule, price, pricePerDeliveryLabel);

    const widget = new Widget(rule, this.#config.settings, format, this.#prices.format(price));
    wrapper.innerHTML = widget.render(this.#id, this.#storage.getWidgetState(rule.id));

    return wrapper;
  }

  /**
   * @param {Object} e
   */
  #onPurchaseTypeChange = e => {
    if (this.#isPurchaseTypeChanged(e.target.name)) {
      const subscriptionSelected = !!(+e.target.value);
      this.#toggleSellingPlans(subscriptionSelected);
      this.#updateLinkedElements(subscriptionSelected);
      this.#fastCheckout.init(subscriptionSelected, null, true);
      this.#storage.rememberWidgetState(this.#widget);
      return;
    }

    if (e.target.name === `spurit-ros-${this.#id}-selling-plan`) {
      this.#toggleFrequency(true);
      this.#storage.rememberWidgetState(this.#widget);
      return;
    }

    if (e.target.id === `spurit-ros-${this.#id}-frequency-unit`) {
      this.#storage.rememberWidgetState(this.#widget);
    }
  };

  /**
   * @param {string} targetName 
   * @returns {boolean}
   */
  #isPurchaseTypeChanged (targetName) {
    if (this.#widget.dataset.type === WIDGET_TYPES.TILES) {
      return targetName === `spurit-ros-${this.#id}-selling-plan`;
    }

    return targetName === `spurit-ros-${this.#id}-option`;
  }

  /**
   * @param {Object} e
   */
  #onFrequencyNumberInput = e => {
    if (e.target.classList.contains('spurit-ros__custom-frequency-number')) {
      WidgetManager.#fixInputValue(e.target);
      this.#storage.rememberWidgetState(this.#widget);
    }
  };

  /**
   * @param {boolean} subscriptionSelected
   * @param {HTMLElement} widget
   */
  #toggleFrequency (subscriptionSelected, widget = null) {
    widget = widget || this.#widget;
    if (!widget) {
      return;
    }

    const {selectedSellingPlanId, atc, prices} = this;

    this.#prices.updateOnPage(selectedSellingPlanId, prices, atc, subscriptionSelected);
    this.#prices.updateWidget(selectedSellingPlanId, widget, atc);

    widget.querySelectorAll(NOTES_FOR_CUSTOMER_SELECTOR).forEach(notes => {
      if (notes.id === `spurit-ros-${this.#id}-notes-for-customer-${selectedSellingPlanId}`) {
        notes.classList.add(WidgetManager.NOTES_FOR_CUSTOMER_VISIBLE_CLASS);
      } else {
        notes.classList.remove(WidgetManager.NOTES_FOR_CUSTOMER_VISIBLE_CLASS);
      }
    });

    const customRadio = widget.querySelector(CUSTOM_SELLING_PLAN_SELECTOR);
    if (!customRadio) {
      return;
    }

    const numberInput = widget.querySelector(CUSTOM_FREQUENCY_NUMBER_SELECTOR);
    const unitSelect = widget.querySelector(CUSTOM_FREQUENCY_UNIT_SELECTOR);
    if (subscriptionSelected && customRadio.checked) {
      numberInput.removeAttribute('disabled');
      unitSelect.removeAttribute('disabled');
    } else {
      numberInput.setAttribute('disabled', 'disabled');
      unitSelect.setAttribute('disabled', 'disabled');
    }
  }

  /**
   * @param {boolean} subscriptionSelected
   * @param {HTMLElement} widget
   */
  #toggleSellingPlans (subscriptionSelected, widget = null) {
    widget = widget || this.#widget;
    if (!widget) {
      return;
    }

    const plans = widget.querySelectorAll(`${SELLING_PLAN_CHECKBOX_SELECTOR}, ${SELLING_PLAN_SELECT_SELECTOR}`);
    if (subscriptionSelected || widget.dataset.type === WIDGET_TYPES.TILES) {
      [].forEach.call(plans, radio => radio.removeAttribute('disabled'));
    } else {
      [].forEach.call(plans, radio => radio.setAttribute('disabled', 'disabled'));
    }

    this.#toggleFrequency(subscriptionSelected, widget);
  }

  /**
   * @param {boolean} subscriptionSelected
   */
  #updateLinkedElements (subscriptionSelected) {
    const {selectedSellingPlanId, atc, buyNow, prices} = this;
    this.#buttons.update(atc, buyNow, subscriptionSelected);
    this.#prices.updateOnPage(selectedSellingPlanId, prices, atc, subscriptionSelected);
  }

  /**
   * @param {HTMLElement} input
   */
  static #fixInputValue (input) {
    let min;
    let max;

    if (input.hasAttribute('min') && (min = +input.getAttribute('min')) > input.value) {
      input.value = min;
    }

    if (input.hasAttribute('max') && (max = +input.getAttribute('max')) < input.value) {
      input.value = max;
    }
  }
}