import resolve from "../../resolve";
import Product from "./Product";
import AppConfig from "../../config/config";
import { WIDGET_TYPES } from "../../classes/SettingsGeneral";
import Badge from "./Badge";
import { SUBSCRIBE_OPTION_SELECTOR } from "../Selectors";

const WIDGET_PRICES_SELECTOR = '.spurit-ros__price';
const WIDGET_FREQUENCY_LABEL_SELECTOR = '.spurit-ros__label-text';
const WIDGET_PRICE_PER_DELIVERY_SELECTOR = '.spurit-ros__price_per_delivery';
const DISCOUNT_BADGE_SELECTOR = '.spurit-ros__badge';
const WIDGET_OPTION_SELECTOR = '.spurit-ros__option';
const WIDGET_OPTION_SELLING_PLAN = '.spurit-ros__frequency-option';

export const CENTS_IN_DOLLAR = 100;

export default class Prices {

  /**
   * @type {Object}
   */
  #global;

  /**
   * @type {Product}
   */
  #product;

  /**
   * @type {Rule}
   */
  #rule;

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   *
   */
  constructor () {
    this.#global = resolve('global');
    this.#product = resolve(Product);
    this.#config = resolve(AppConfig);
  }

  /**
   *
   * @param {Rule|null} rule
   * @return {this}
   */
  setRule (rule) {
    this.#rule = rule;
    return this;
  }

  /**
   *
   * @param {Number} sellingPlanId
   * @param {HTMLElement[]} priceElems
   * @param {HTMLElement} atc
   * @param {boolean} isSubscriptionOn
   */
  updateOnPage (sellingPlanId, priceElems, atc, isSubscriptionOn) {
    if (!priceElems || !priceElems.length) {
      return;
    }

    this.#resolveProduct(atc, product => {
      const compareAtPrice = product.selected_variant.compare_at_price / CENTS_IN_DOLLAR;
      const standardPrice = product.selected_variant.price / CENTS_IN_DOLLAR;

      const isRuleActive = isSubscriptionOn && this.#rule;
      const newPrice = isRuleActive ? this.#rule.getPricePerDelivery(standardPrice, sellingPlanId) : standardPrice;

      for (const priceElem of priceElems) {
        const discount = this.#rule ? this.#rule.getActualDiscount(sellingPlanId) : null;
        this.#updatePriceBadge(priceElem, discount, isSubscriptionOn);
        this.#global.prices.displayNewPrice(priceElem, newPrice, compareAtPrice || standardPrice);
      }
    });
  }

  /**
   * @param {HTMLElement} priceElem
   * @param {Object} discount
   * @param {boolean} isSubscriptionOn
   */
  #updatePriceBadge (priceElem, discount, isSubscriptionOn) {
    if (!priceElem.parentElement) {
      return;
    }

    const existingBadge = priceElem.parentElement.querySelector(DISCOUNT_BADGE_SELECTOR);
    if (!isSubscriptionOn || !discount?.value) {
      if (existingBadge) {
        existingBadge.style.display = 'none';
      }

      return;
    }

    const actualBadge = resolve(Badge).render(this.#config.settings.text_labels.sale_badge, discount);
    if (!existingBadge) {
      priceElem.parentElement.insertAdjacentHTML('beforeend', actualBadge);
      priceElem.parentElement.style.alignItems = 'center';
      priceElem.parentElement.style.display = 'flex';
      return;
    }

    existingBadge.style.display = '';
    existingBadge.outerHTML = actualBadge;
  }

  /**
   *
   * @param {Number} sellingPlanId
   * @param {HTMLElement} widget
   * @param {HTMLElement} atc
   */
  updateWidget (sellingPlanId, widget, atc) {
    if (!this.#rule) {
      return;
    }

    if (widget.dataset.type === WIDGET_TYPES.TILES) {
      this.#resolveProduct(atc, product => this.#updateTilesWidgetPrices(widget, product));
      return;
    }

    this.#updateFrequencyLabel(widget, sellingPlanId);
    this.#togglePricePerDeliveryLabel(widget, sellingPlanId);
    this.#resolveProduct(atc, product => this.#updateWidgetPrices(widget, product, sellingPlanId));
  }

  /**
   *
   * @param price
   * @returns {string}
   */
  format = price => this.#global.prices.money.format(price);

  /**
   *
   * @param {Rule} rule
   * @param {number} price
   * @param {string} pricePerDeliveryLabel
   */
  addPricesToFrequencies(rule, price, pricePerDeliveryLabel) {
    rule.subscription_frequencies?.forEach(frequency => {
      let pricePerDelivery = null;
      if (frequency.deliveries_per_charge > 1) {
        pricePerDelivery = this.format(rule.getPricePerDelivery(price, frequency.selling_plan_id));
      }

      frequency.subscription_price = this.format(rule.getSubscriptionPrice(price, frequency.selling_plan_id));
      frequency.price_per_delivery = this.constructor.getPricePerDeliveryLabel(pricePerDeliveryLabel, pricePerDelivery);
    });
  }

  /**
   * 
   * @param {Object} product 
   * @returns {number}
   */
  getProductPrice(product) {
    return product.selected_variant.price / CENTS_IN_DOLLAR;
  }

  /**
   * 
   * @returns {string}
   */
  getMoneyFormat() {
    return this.#global.prices.money.getFormatDefault();
  }

  /**
   *
   * @param {HTMLElement} atc
   * @param {function} handler
   */
  #resolveProduct (atc, handler) {
    this.#product.resolve(atc).then(product => {
      if (!product) {
        console.error('Unable to find current product');
        return;
      }

      handler(product);
    });
  }

  /**
   * @param {HTMLElement} widget
   * @param {number} sellingPlanId
   */
  #updateFrequencyLabel (widget, sellingPlanId) {
    const frequencyLabel = this.#getWidgetFrequencyLabel(widget);
    const textLabels = this.#config.settings.text_labels;
    const moneyFormat = this.getMoneyFormat();

    frequencyLabel.innerHTML = this.#rule.makeLabelWithFormattedDiscount(sellingPlanId, textLabels, moneyFormat);
  }

  /**
   * @param {HTMLElement} widget
   * @param {number} sellingPlanId
   */
  #togglePricePerDeliveryLabel (widget, sellingPlanId) {
    const pricePerDeliveryElement = this.#getPricePerDeliveryElement(widget);
    if (!pricePerDeliveryElement) {
      return;
    }

    const deliveriesPerCharge = this.#rule.getDeliveriesPerCharge(sellingPlanId);
    pricePerDeliveryElement.style.display = deliveriesPerCharge === 1 ? 'none' : '';
  }

  /**
   * @param {HTMLElement} widget
   * @param {Object} product
   * @param {number} sellingPlanId
   */
  #updateWidgetPrices (widget, product, sellingPlanId) {
    const price = product.selected_variant.price / CENTS_IN_DOLLAR;
    this.#updateWidgetOptionPrices(widget, price, sellingPlanId);
    this.#updatePricePerDeliveryLabel(widget, price, sellingPlanId);
    this.#updateWidgetBadge(widget, sellingPlanId);
  }

  /**
   * @param {HTMLElement} widget
   * @param {number} price
   * @param {number} sellingPlanId
   */
  #updateWidgetOptionPrices (widget, price, sellingPlanId) {
    const widgetPrices = widget.querySelectorAll(WIDGET_PRICES_SELECTOR);

    if (this.#rule.purchase_only_as_subscription) {
      widgetPrices[0].innerHTML = this.format(this.#rule.getSubscriptionPrice(price, sellingPlanId));
    } else {
      widgetPrices[0].innerHTML = this.format(price);
      widgetPrices[1].innerHTML = this.format(this.#rule.getSubscriptionPrice(price, sellingPlanId));
    }
  }

  /**
   * @param {HTMLElement} widget
   * @param {number} price
   * @param {number} sellingPlanId
   */
  #updatePricePerDeliveryLabel (widget, price, sellingPlanId) {
    const pricePerDeliveryElement = this.#getPricePerDeliveryElement(widget);
    if (!pricePerDeliveryElement) {
      return;
    }

    pricePerDeliveryElement.innerHTML = this.constructor.getPricePerDeliveryLabel(
      this.#config.settings.text_labels.price_per_delivery,
      this.format(this.#rule.getPricePerDelivery(price, sellingPlanId)),
    );
  }

  /**
   * @param {HTMLElement} widget
   * @param {Object} product
   */
  #updateTilesWidgetPrices (widget, product) {
    const price = product.selected_variant.price / CENTS_IN_DOLLAR;

    const widgetOptions = widget.querySelectorAll(WIDGET_OPTION_SELECTOR);

    widgetOptions.forEach((option, key) => {
      const optionSellingPlanId = option.querySelector(WIDGET_OPTION_SELLING_PLAN).value;

      this.#updateTilesWidgetOptionPrice(option, key, price, optionSellingPlanId);
      this.#updateTilesWidgetPricePerDeliveryLabel(option, price, optionSellingPlanId);
    });
  }

  /**
   * @param {HTMLElement} widgetOption
   * @param {number} key
   * @param {number} price
   * @param {number} sellingPlanId
   */
  #updateTilesWidgetOptionPrice (widgetOption, key, price, sellingPlanId) {
    const optionPrice = widgetOption.querySelector(WIDGET_PRICES_SELECTOR);

    if (!this.#rule.purchase_only_as_subscription && key === 0) {
      optionPrice.innerHTML = this.format(price);
      return;
    }

    optionPrice.innerHTML = this.format(this.#rule.getSubscriptionPrice(price, sellingPlanId));
  }

  /**
   * @param {HTMLElement} option
   * @param {number} price
   * @param {number} sellingPlanId
   */
  #updateTilesWidgetPricePerDeliveryLabel (option, price, sellingPlanId) {
    const pricePerDeliveryElement = option.querySelector('[data-editable="additional-text"]');

    if (!pricePerDeliveryElement) {
      return;
    }

    pricePerDeliveryElement.innerHTML = this.constructor.getPricePerDeliveryLabel(
      this.#config.settings.text_labels.price_per_delivery,
      this.format(this.#rule.getPricePerDelivery(price, sellingPlanId)),
    );
  }

  /**
   * @param {HTMLElement} widget
   * @param {number} sellingPlanId
   */
  #updateWidgetBadge (widget, sellingPlanId) {
    const existingBadge = this.#getDiscountBadge(widget);
    const discount = this.#rule.getActualDiscount(sellingPlanId);
    if (!discount?.value) {
      if (existingBadge) {
        existingBadge.style.display = 'none';
      }

      return;
    }

    const actualBadge = resolve(Badge).render(this.#config.settings.text_labels.save_badge, discount);
    if (!existingBadge) {
      const labelContent = widget.querySelector(
        `${SUBSCRIBE_OPTION_SELECTOR} + .spurit-ros__option-inner .spurit-ros__label-content`,
      );
      labelContent.insertAdjacentHTML('beforeend', actualBadge);
      return;
    }

    existingBadge.style.display = '';
    existingBadge.outerHTML = actualBadge;
  }

  /**
   *
   * @param {HTMLElement} widget
   * @returns {HTMLElement}
   */
  #getWidgetFrequencyLabel (widget) {
    const widgetFrequencyLabels = widget.querySelectorAll(WIDGET_FREQUENCY_LABEL_SELECTOR);
    return this.#rule.purchase_only_as_subscription ? widgetFrequencyLabels[0] : widgetFrequencyLabels[1];
  }

  /**
   *
   * @param {HTMLElement} widget
   * @returns {HTMLElement}
   */
  #getPricePerDeliveryElement (widget) {
    return widget.querySelector(WIDGET_PRICE_PER_DELIVERY_SELECTOR);
  }

  /**
   * @param {HTMLElement} widget
   * @return {HTMLElement}
   */
  #getDiscountBadge (widget) {
    return widget.querySelector(DISCOUNT_BADGE_SELECTOR);
  }

  /**
   *
   * @returns {string|null}
   */
  static getPricePerDeliveryLabel (pricePerDeliveryLabel, price) {
    if (!pricePerDeliveryLabel || !price) {
      return null;
    }

    return pricePerDeliveryLabel.replace('{{price.per.delivery}}', price);
  }
}