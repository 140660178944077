import AccountPage from "./AccountPage";
import Actions from "./Actions";
import SubscriptionContract from "../../classes/SubscriptionContract";
import resolve from "../../resolve";
import SubscriptionContractProduct from '../../classes/SubscriptionContractProduct';
import {Permissions} from './Permissions';

const SHIPPING_ERROR_PATTERN = 'require shipping';

export default class View extends AccountPage {

  /**
   * @type {number} id
   */
  #id;

  /**
   * @type {Actions}
   */
  #actions;

  /**
   * @type {Element}
   */
  #container;

  /**
   * @type {SubscriptionContract}
   */
  #subscription;

  /**
   * @param {number} id
   */
  constructor (id) {
    super();
    this.#actions = resolve(Actions);
    this.#id = id;
  }

  /**
   * @param {Element} container
   */
  run = container => {
    this.#container = container;

    if (!this.#id) {
      this.showError('Invalid subscription id');
      return;
    }

    this.api.contract.getSubscription(this.#id)
      .then(subscription => {
        this.#subscription = new SubscriptionContract(subscription);
        this.addResizeListener();
        this.#initActions();
        this.#drawPage();
      })
      .catch(e => {
        this.showError('Unable to load subscription with that id');
        console.warn(e);
      });
  }

  /**
   *
   */
  hideFrequencyPicker = () => {
    this.constructor.#frequencyPicker.style.display = 'none';
  }

  /**
   *
   */
  hideNextOrderDatePicker = () => {
    this.constructor.#nextOrderDatePicker.style.display = 'none';
  }

  /**
   *
   */
  onResize = () => this.#drawPage();

  /**
   * @param {string} content
   * @param {boolean} success
   */
  showBanner = (content, success = true) => {
    const container = document.querySelector('.spurit-ros__banners-container');
    container.insertAdjacentHTML('beforeend', this.templates.renderBanner(content, success));
    container.scrollIntoView({block: "center", behavior: 'smooth'});
  }

  /**
   *
   */
  hideBanner = () => {
    const bannerElement = document.querySelector('.spurit-ros__banner');
    if (bannerElement) {
      bannerElement.remove();
    }
  }

  /**
   * @param {HTMLElement} fulfillmentRow
   */
  showFulfillmentDatePicker = fulfillmentRow => this.toggleFulfillmentDatePicker(fulfillmentRow, true)

  /**
   *
   * @param {HTMLElement} fulfillmentRow
   */
  hideFulfillmentDatePicker = fulfillmentRow => this.toggleFulfillmentDatePicker(fulfillmentRow, false);

  /**
   * @param {HTMLElement} fulfillmentRow
   * @param {boolean} show
   */
  toggleFulfillmentDatePicker = (fulfillmentRow, show = true) => {
    const editor = this.constructor.#getFulfillmentEditor(fulfillmentRow);
    if (editor) {
      editor.style.display = show ? '' : 'none';
    }
  }

  /**
   * @param {string} message
   */
  showError = message => {
    this.toggleLoader(false);
    this.showBanner(message, false);
  }

  /**
   * @param {HTMLElement} productQtySection
   */
  showProductQuantityPicker = productQtySection => this.toggleProductQuantityPicker(productQtySection, true);

  /**
   * @param {HTMLElement} productQtySection
   */
  hideProductQuantityPicker = productQtySection => this.toggleProductQuantityPicker(productQtySection, false);

  /**
   * @param {HTMLElement} productQtySection
   * @param {boolean} showPicker
   */
  toggleProductQuantityPicker = (productQtySection, showPicker) => {
    productQtySection.querySelector('.spurit-ros__product-qty').style.display = showPicker ? 'none' : 'inline';
    productQtySection.querySelector('.spurit-ros__edit-product-qty__btn').style.display = showPicker ? 'none' : 'inline-block';
    productQtySection.querySelector('.spurit-ros__edit-product-qty__section').style.display = showPicker ? 'block' : 'none';
  }  

  /**
   *
   */
  showFrequencyPicker = () => {
    this.constructor.#frequencyPicker.style.display = 'flex';
  }

  /**
   * @param {boolean|null} on
   */
  toggleLoader = (on = null) => {
    const loader = document.querySelector(`${AccountPage.CONTENT_WRAPPER_SELECTOR} > .spurit-ros__loader`);
    if (loader) {
      if (on === null) {
        loader.style.display = loader.style.display === 'none' ? 'inline-block' : 'none';
      } else {
        loader.style.display = on ? 'inline-block' : 'none';
      }
    }
  }

  /**
   *
   */
  showNextOrderDatePicker = () => {
    this.constructor.#nextOrderDatePicker.style.display = 'flex';
  }

  /**
   *
   */
  #drawPage = () => {
    this.#container.innerHTML = this.templates.renderSubscriptionView(
      this.variables.getViewVariables(this.#subscription),
    );

    if (resolve(Permissions).canChangeFrequency) {
      this.#initFrequencyUnitSelect();
    }
  }

  /**
   *
   */
  #initActions = () => {
    this.#actions.init(this.#subscription);

    this.#actions.addListener(Actions.EVENTS.ERROR, message => this.showBanner(message, false));
    this.#actions.addListener(Actions.EVENTS.CLOSE_BANNER_CLICK, this.hideBanner);

    this.#actions.addListener(Actions.EVENTS.SHOW_FREQUENCY_PICKER_CLICK, this.showFrequencyPicker);
    this.#actions.addListener(Actions.EVENTS.HIDE_FREQUENCY_PICKER_CLICK, this.hideFrequencyPicker);

    this.#actions.addListener(Actions.EVENTS.SHOW_NEXT_ORDER_DATE_PICKER_CLICK, this.showNextOrderDatePicker);
    this.#actions.addListener(Actions.EVENTS.HIDE_NEXT_ORDER_DATE_PICKER_CLICK, this.hideNextOrderDatePicker);

    this.#actions.addListener(Actions.EVENTS.SHOW_FULFILLMENT_DATE_PICKER_CLICK, this.showFulfillmentDatePicker);
    this.#actions.addListener(Actions.EVENTS.HIDE_FULFILLMENT_DATE_PICKER_CLICK, this.hideFulfillmentDatePicker);

    this.#actions.addListener(Actions.EVENTS.SHOW_PRODUCT_QUANTITY_PICKER_CLICK, this.showProductQuantityPicker);
    this.#actions.addListener(Actions.EVENTS.HIDE_PRODUCT_QUANTITY_PICKER_CLICK, this.hideProductQuantityPicker);

    this.#actions.addListener(Actions.EVENTS.FULFILLMENT_DATE_UPDATED, this.#onFulfillmentDateUpdate);

    this.#actions.addListener(Actions.EVENTS.FREQUENCY_UPDATED, updates => {
      this.#onSubscriptionUpdated(updates, 'frequency');
    });

    this.#actions.addListener(Actions.EVENTS.NEXT_ORDER_DATE_UPDATED, updates => {
      this.#onSubscriptionUpdated(updates, 'next_payment');
    });

    this.#actions.addListener(Actions.EVENTS.SUBSCRIPTION_PAUSED, updates => {
      this.#onSubscriptionUpdated(updates, 'pause');
    });

    this.#actions.addListener(Actions.EVENTS.SUBSCRIPTION_RESUMED, updates => {
      this.#onSubscriptionUpdated(updates, 'resume');
    });

    this.#actions.addListener(Actions.EVENTS.SUBSCRIPTION_CANCELLED, updates => {
      this.#onSubscriptionUpdated(updates, 'cancel');
    });

    this.#actions.addListener(Actions.EVENTS.ORDER_SKIPPED, updates => {
      this.#onSubscriptionUpdated(updates, 'skip');
    });

    this.#actions.addListener(Actions.EVENTS.ORDER_NOW, this.#onNewOrderPlaced);

    this.#actions.addListener(Actions.EVENTS.PAYMENT_METHOD_REQUESTED, updates => {
      this.#onSubscriptionUpdated(updates, 'payment_method');
    });

    this.#actions.addListener(Actions.EVENTS.SHIPPING_ADDRESS_UPDATED, updates => {
      this.#onSubscriptionUpdated(updates, 'shipping_address');
    });

    this.#actions.addListener(Actions.EVENTS.PRODUCT_QUANTITY_UPDATED, updates => {
      this.#onSubscriptionUpdated(updates, 'subscription');
    });

    this.#actions.addListener(Actions.EVENTS.BOX_QUANTITY_UPDATED, updates => {
      this.#onSubscriptionUpdated(updates, 'subscription');
    });

    this.#actions.addListener(Actions.EVENTS.SUBSCRIPTION_ITEMS_ADDED, data => {
      this.#onSubscriptionItemsAdded(data);
    });

    this.#actions.addListener(Actions.EVENTS.SUBSCRIPTION_ITEMS_REPLACED, ({deleted, added, error}) => {
      this.#afterSwap(deleted, added, error);
    });

    this.#actions.addListener(Actions.EVENTS.SUBSCRIPTION_ITEM_REMOVED, ({lineId}) => {
      this.#onSubscriptionItemRemoved(lineId);
    });

    this.#actions.addListener(Actions.EVENTS.SUBSCRIPTION_BOX_REMOVED, ({id}) => {
      this.#onSubscriptionBoxRemoved(id);
    });
  }

  /**
   *
   */
  #initFrequencyUnitSelect = () => {
    const frequencyUnitSelect = this.#container.querySelector('[name="spurit-ros-frequency-unit"]');
    if (frequencyUnitSelect) {
      frequencyUnitSelect.value = this.#subscription.frequency.frequency_unit;
    }
  };

  /**
   * @param {Object} updates
   */
  #onNewOrderPlaced = updates => {
    updates.subscription_contract_products = this.#subscription.subscriptionBasisItems;
    this.#onSubscriptionUpdated(updates, 'order_now');
  }

  /**
   * @param {Object} updates
   * @param {string} messageGroup
   */
  #onSubscriptionUpdated = (updates, messageGroup = '') => {
    if (Object.keys(updates).length) {
      this.#subscription = new SubscriptionContract({...this.#subscription, ...updates});
      this.#actions.setSubscription(this.#subscription);
      this.#drawPage();
    }

    if (messageGroup) {
      const labels = this.config.settings.customer_portal_labels[messageGroup];
      const message = labels.success || labels[this.#subscription.invoice.is_enabled ? 'invoice' : 'auto'].success;

      this.showBanner(message);
    }
  }

  /**
   * @param {Array} orders
   */
  #onFulfillmentDateUpdate = orders => {
    this.#onSubscriptionUpdated({subscription_contract_orders: orders}, 'fulfillment');
  }

  /**
   * @param {Object[]} selectedItems
   * @param {Object} result
   */
  #onSubscriptionItemsAdded = ({selectedItems, result}) => {
    const updatedItems = [...this.#subscription.subscription_contract_products, ...result.items];
    this.#onSubscriptionUpdated({subscription_contract_products: updatedItems});

    const labels = this.config.settings.customer_portal_labels.add_remove_product;
    if (result.items.length) {
      this.showBanner(this.templates.addedItemsMessage(labels.added, result.items));
    }

    if (selectedItems.length > result.items.length) {
      const skippedItems = selectedItems.filter(selected => !result.items.some(item => +item.variant_id === selected.id));
      const message = result.error && result.error.includes(SHIPPING_ERROR_PATTERN) ? labels.failed_shipping : labels.failed;
      this.showBanner(this.templates.addedItemsMessage(message, skippedItems), false);
    }
  }

  /**
   * @param {number} deletedItemId
   * @param {Object} addedItem
   * @param {string|null} error
   */
  #afterSwap = (deletedItemId, addedItem = null, error = null) => {
    addedItem ? this.#onSwapSuccess(deletedItemId, addedItem) : this.#onSwapFailed(error);
  }

  /**
   * @param {number} deletedItemId
   * @param {Object} addedItem
   */
  #onSwapSuccess = (deletedItemId, addedItem) => {
    const labels = this.config.settings.customer_portal_labels.add_remove_product;
    const index = this.#subscription.subscription_contract_products.findIndex(item => item.line_id === deletedItemId);
    this.#subscription.subscription_contract_products[index] = new SubscriptionContractProduct(addedItem);
    this.#onSubscriptionUpdated({
      subscription_contract_products: this.#subscription.subscription_contract_products,
    });

    this.showBanner(labels.swap_successful);
  }

  /**
   * @param {string} error
   */
  #onSwapFailed = error => {
    const labels = this.config.settings.customer_portal_labels.add_remove_product;
    const message = error && error.includes(SHIPPING_ERROR_PATTERN) ? labels.failed_shipping : labels.swap_failed;
    this.showBanner(message, false);
  }

  /**
   * @param {number} lineId
   */
  #onSubscriptionItemRemoved = lineId => {
    const updatedItems = this.#subscription.subscription_contract_products.filter(item => item.line_id !== lineId);
    this.#onSubscriptionUpdated({subscription_contract_products: updatedItems});
    this.showBanner(this.config.settings.customer_portal_labels.add_remove_product.removed);
  }

  /**
   * @param {number} id
   */
  #onSubscriptionBoxRemoved = id => {
    this.#onSubscriptionUpdated({boxes: this.#subscription.boxes.filter(box => box.id !== id)});
    this.showBanner(this.config.settings.customer_portal_labels.add_remove_product.removed);
  }

  /**
   * @returns {Element}
   */
  static get #frequencyPicker () {
    return document.querySelector('.spurit-ros__frequency-picker');
  }

  /**
   * @returns {Element}
   */
  static get #nextOrderDatePicker () {
    return document.querySelector('.spurit-ros__next-order-date-picker');
  }

  /**
   * @param {HTMLElement} fulfillmentRow
   * @returns {HTMLElement|null}
   */
  static #getFulfillmentEditor = fulfillmentRow => {
    return fulfillmentRow.querySelector('.spurit-ros__fulfillment-date-editor');
  }
}