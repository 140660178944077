//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SettingsCustomerPortalLabelsFrequencyAuto from './SettingsCustomerPortalLabelsFrequencyAuto';
import SettingsCustomerPortalLabelsFrequencyInvoice from './SettingsCustomerPortalLabelsFrequencyInvoice';


export default class SettingsCustomerPortalLabelsFrequency extends AbstractParent {


  /**
   * @type  {string}
   */
  button = ___('Change frequency');

  /**
   * @type  {string}
   */
  dismiss = ___('Cancel');

  /**
   * @type  {string}
   */
  save = ___('Save');

  /**
   * @type  {string}
   */
  cancel = ___('Cancel').toUpperCase();

  /**
   * @type  {string}
   */
  ok = ___('OK');

  /**
   * @type  {string}
   */
  days = ___('Day(s)');

  /**
   * @type  {string}
   */
  weeks = ___('Week(s)');

  /**
   * @type  {string}
   */
  months = ___('Month(s)');

  /**
   * @type  {string}
   */
  years = ___('Year(s)');

  /**
   * @type {string}
   */
  deliveries = ___('Deliveries');

  /**
   * @type {string}
   */
  charge = `${___('Charge every')}:`;

  /**
   * @type {string}
   */
  deliver = `${___('Deliver every')}:`;

  /**
   * @type {string}
   */
  deliver_and_charge = `${___('Deliver and charge every')}:`;

  /**
   * @type  {SettingsCustomerPortalLabelsFrequencyAuto}
   */
  auto;

  /**
   * @type  {SettingsCustomerPortalLabelsFrequencyInvoice}
   */
  invoice;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      auto: SettingsCustomerPortalLabelsFrequencyAuto,
      invoice: SettingsCustomerPortalLabelsFrequencyInvoice,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}