//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SettingsGeneralSelectors from './SettingsGeneralSelectors';

export const STORE = 'STORE';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const RADIO = 'RADIO';
export const SELECT = 'SELECT';
export const WIDGET_TYPES = {
  SOLID_BLOCK: 'solid-block',
  SEPARATED_BLOCKS: 'separated-blocks',
  TILES: 'tiles',
};

export default class SettingsGeneral extends AbstractParent {


  /**
   * @type  {string}
   */
  tag = ___('Subscription').toLowerCase();

  /**
   * @type {string}
   */
  locale = 'en';

  /**
   * @type  {boolean}
   */
  is_enabled_subscribe = false;

  /**
   * @type  {boolean}
   */
  should_replace_text = false;

  /**
   * @type  {string}
   */
  atc_replacement_text = ___('Add subscription to cart');

  /**
   * @type  {STORE|SUBSCRIPTION|'STORE'|'SUBSCRIPTION'}
   */
  product_prices_in_invoices = SUBSCRIPTION;

  /**
   * @type  {RADIO|SELECT|'RADIO'|'SELECT'}
   */
  frequency_style = SELECT;

  /**
   * @type  {'SOLID_BLOCK'|'SEPARATED_BLOCKS'|'TILES'}
   */
  widget_type = WIDGET_TYPES.SEPARATED_BLOCKS;

  /**
   * @type  {SettingsGeneralSelectors}
   */
  selectors;

  /**
   * @type {string}
   */
  cart_label = ___('Delivery frequency');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      selectors: SettingsGeneralSelectors,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}