/**
 * @param {AppLibrary} library
 * @param {string} appName
 * @constructor
 */
export const Loader = function (library, appName) {

  /**
   * @type string
   */
  const currentPage = library.getCurrentPage();

  /**
   * @type {{selectorPicker: function|bool|null, appResources: [{url: string, pages: [string]}]}}
   */
  const params = {
    selectorPicker: null,
    appResources: [],
  };

  /**
   * @param {function(string)|bool} callback
   *        Function to check if SP should start in the current page (will be passed to that func.) or true to start SP unconditionally
   */
  this.enableSelectorPicker = (callback) => {
    params.selectorPicker = callback;
  };

  /**
   * @param {string} url
   * @param {string[]|string} pages
   * @param {function} isLoadingAllowed
   */
  this.addResources = (url, pages = [], isLoadingAllowed = null) => {
    if (!Array.isArray(pages)) {
      pages = [pages];
    }
    params.appResources.push({url, pages, isLoadingAllowed});
  };


  /**
   * @param {string} page
   * @return {[string]}
   */
  const getResources = (page) => {
    const resources = [];
    for (let i = 0; i < params.appResources.length; i++) {
      const resource = params.appResources[i];
      if (!resource.pages.length || resource.pages.includes(page) || resource.pages.includes('all')) {
        resources.push(resource);
      }
    }
    return resources;
  };

  /**
   * @param {jQuery|null} $
   * @param {function(jQuery, string)} appEntryPoint
   */
  const runApp = ($, appEntryPoint) => {
    const _GET = library.requestGetVars();
    if (params.selectorPicker && typeof (_GET.sign) !== 'undefined' && _GET.sign === appName) {
      if (typeof (params.selectorPicker) !== 'function' || params.selectorPicker(currentPage)) {
        library.loadStatic(_GET.script);
      }
      return;
    }

    library.loadStatic(
        getResources(currentPage),
        () => {
          library.onLoad(() => {
            // Run App
            appEntryPoint($, currentPage);
          });
        },
    );
  };

  /**
   * @param {function(jQuery, string)} appEntryPoint
   * @param {string|null} loadJQuery
   */
  this.run = (appEntryPoint, loadJQuery = null) => {
    try {
      if (loadJQuery) {
        library.loadStatic(
          '//ajax.googleapis.com/ajax/libs/jquery/' + loadJQuery + '/jquery.min.js',
          () => {
            const $ = jQuery.noConflict(true);
            runApp($, appEntryPoint);
          },
        );
      } else {
        runApp(typeof (jQuery) === 'undefined' ? null : jQuery, appEntryPoint);
      }
    } catch (e) {
      throw new Error('Exception while loading jQuery: ' + e.message);
    }
  };
};
