/* eslint-disable max-lines */
import {getNewOrderDate} from '../../../../assets/js/helpers/dates';
import {ACTIVE, CANCELLED, PAUSED} from '../../classes/SubscriptionContract';
import resolve from '../../resolve';
import PopupManager from './PopupManager';
import Validator from './Validator';
import ProductSelector from '../ProductSelector';
import Rules from '../Rules';
import {Permissions} from './Permissions';
import {STATUS_SUCCESS} from '../api/ApiBase';
import Api from '../api/Api';
import AppConfig from '../../config/config';
import moment from 'moment-timezone';

const DEFAULT_ERROR = 'Some error occurred. Try again later or contact the store owner.';
const ACTION_ATTRIBUTE = 'data-action';

export default class Actions {

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Permissions}
   */
  #permissions;

  /**
   * @type {Api}
   */
  #api;

  /**
   * @type {Validator}
   */
  #validator;

  /**
   * @type {PopupManager}
   */
  #popupManager;

  /**
   * @type {SubscriptionContract}
   */
  #subscription;

  /**
   * @type {Object}
   */
  #listeners = {};

  /**
   * @type {ProductSelector}
   */
  #productSelector;

  /**
   * @type {Rules}
   */
  #rules;

  /**
   * @type {number[]|null}
   */
  #productSelectorVisibleItems = null;

  /**
   * @type {Object}
   */
  static LIST = {
    CLOSE_BANNER: 'close-banner',
    SHOW_FREQUENCY_PICKER: 'show-fp',
    HIDE_FREQUENCY_PICKER: 'hide-fp',
    UPDATE_FREQUENCY: 'update-frequency',
    SHOW_NEXT_ORDER_DATE_PICKER: 'show-next-order-dp',
    HIDE_NEXT_ORDER_DATE_PICKER: 'hide-next-order-dp',
    UPDATE_NEXT_ORDER_DATE: 'update-next-order-date',
    PAUSE_SUBSCRIPTION: 'pause',
    RESUME_SUBSCRIPTION: 'resume',
    CANCEL_SUBSCRIPTION: 'cancel',
    SKIP_ORDER: 'skip',
    ORDER_NOW: 'order-now',
    ORDER_NOW_FAILED: 'order-now-failed',
    ORDER_NOW_BOTH: 'order-now-both',
    UPDATE_PAYMENT_METHOD: 'update-payment-method',
    SAVE_ADDRESS: 'save-address',
    SHOW_FULFILLMENT_DATE_PICKER: 'show-fdp',
    HIDE_FULFILLMENT_DATE_PICKER: 'hide-fdp',
    SAVE_FULFILLMENT_DATE: 'save-fulfillment-date',
    SHOW_PRODUCT_QUANTITY_PICKER: 'show-pqty',
    HIDE_PRODUCT_QUANTITY_PICKER: 'hide-pqty',
    UPDATE_PRODUCT_QUANTITY: 'update-product-qty',
    UPDATE_BOX_QUANTITY: 'update-box-qty',
    SHOW_ADD_ITEM_MODAL: 'show-add-modal',
    SHOW_SWAP_ITEM_MODAL: 'show-swap-modal',
    REMOVE_ITEM: 'remove-product',
    ADD_ITEM_AS_SUBSCRIPTION: 'add_subscription_item',
    ADD_ITEM_AS_ONE_TIME_PURCHASE: 'add_one_time_purchase',
    SWAP_ITEMS: 'swap-items',
  };

  /**
   * @type {Object}
   */
  static EVENTS = {
    CLOSE_BANNER_CLICK: 'close-banner',

    SHOW_FREQUENCY_PICKER_CLICK: 'show-fp',
    HIDE_FREQUENCY_PICKER_CLICK: 'hide-fp',
    FREQUENCY_UPDATED: 'frequency_updated',

    SHOW_NEXT_ORDER_DATE_PICKER_CLICK: 'show-nod',
    HIDE_NEXT_ORDER_DATE_PICKER_CLICK: 'hide-nod',
    NEXT_ORDER_DATE_UPDATED: 'next-order-date-updated',

    SHOW_FULFILLMENT_DATE_PICKER_CLICK: 'show-fdp',
    HIDE_FULFILLMENT_DATE_PICKER_CLICK: 'hide-fdp',

    SHOW_PRODUCT_QUANTITY_PICKER_CLICK: 'show-pqty',
    HIDE_PRODUCT_QUANTITY_PICKER_CLICK: 'hide-pqty',

    SUBSCRIPTION_PAUSED: 'paused',
    SUBSCRIPTION_RESUMED: 'resumed',
    SUBSCRIPTION_CANCELLED: 'cancelled',

    ORDER_SKIPPED: 'skipped',
    ORDER_NOW: 'order-now',

    PAYMENT_METHOD_REQUESTED: 'pm-requested',
    SHIPPING_ADDRESS_UPDATED: 'shipping_address_updated',
    FULFILLMENT_DATE_UPDATED: 'fulfillment_date_updated',

    PRODUCT_QUANTITY_UPDATED: 'product_quantity_updated',
    BOX_QUANTITY_UPDATED: 'box_quantity_updated',
    SUBSCRIPTION_ITEMS_ADDED: 'subscription_items_added',
    SUBSCRIPTION_ITEMS_REPLACED: 'subscription_items_replaced',
    SUBSCRIPTION_ITEM_REMOVED: 'subscription_item_removed',
    SUBSCRIPTION_BOX_REMOVED: 'subscription_box_removed',

    ERROR: 'error',
  };

  /**
   *
   */
  constructor () {
    this.#config = resolve(AppConfig);
    this.#api = resolve(Api);
    this.#validator = resolve(Validator);
    this.#popupManager = resolve(PopupManager);
    this.#rules = resolve(Rules);
    this.#permissions = resolve(Permissions);

    this.#initProductSelector();
  }

  /**
   * @param {SubscriptionContract} subscription
   */
  init = subscription => {
    this.#subscription = subscription;
    this.#validator.init();
    this.#popupManager.init(subscription);
    this.#productSelector.addDisabledItems(this.#subscriptionItemIds);
    this.#rules.getProductVariantIdsForSubscription(this.#subscription).then(this.#onProductSelectorVisibleItemsLoaded);

    this.initListeners();
  }

  /**
   *
   */
  initListeners = () => {
    document.body.addEventListener('click', e => {
      const action = this.#getEventAction(e);
      if (!action) {
        return;
      }

      if (action === Actions.LIST.CLOSE_BANNER) {
        this.#triggerEvent(Actions.EVENTS.CLOSE_BANNER_CLICK);
      }

      if (this.#permissions.canChangeFrequency && this.#handleFrequencyActions(e)) {
        return;
      }

      if (this.#permissions.canChangeNextOrderDate && this.#handleOrderActions(e)) {
        return;
      }

      if (this.#permissions.canChangeFulfillmentDate && this.#handleFulfillmentActions(e)) {
        return;
      }

      if (this.#permissions.canEditProductQuantity && this.#handleQuantityActions(e)) {
        return;
      }

      if (this.#handleAddRemoveItem(e)) {
        return;
      }

      this.#handleSubscriptionActions(e);
    });

    document.body.addEventListener('keydown', e => {
      if (e.target.name === 'spurit-ros-next-order-date') {
        e.preventDefault();
      }
    });

    document.body.addEventListener('change', e => {
      if (e.target.getAttribute('name') === 'spurit-ros-fulfillment-date') {
        this.onFulfillmentDateChange(e.target);
      }
    });
  }

  /**
   * @param {HTMLElement} input
   */
  onFulfillmentDateChange = input => {
    if (input.value) {
      return;
    }

    const {fulfillAt} = this.#subscription.getFulfillment(+input.closest('tr').dataset.id);
    input.value = moment(fulfillAt)
      .utc(true)
      .tz(this.#config.timezone)
      .format('YYYY-MM-DD');
  }

  /**
   * @param {string} name
   * @param {function} func
   */
  addListener (name, func) {
    if (!this.#listeners[name]) {
      this.#listeners[name] = [];
    }

    this.#listeners[name].push(func);
  }

  /**
   * @param {SubscriptionContract} subscription
   */
  setSubscription = subscription => {
    this.#subscription = subscription;
    this.#popupManager.setSubscription(subscription);
  }

  /**
   * @returns {number[]}
   */
  get #subscriptionItemIds () {
    return this.#subscription.subscription_contract_products.map(p => p.variant_id);
  }

  /**
   * @param {boolean} swap
   * @returns {{save: string, title: string}}
   */
  #getProductSelectorTexts = (swap = false) => {
    const labels = this.#config.settings.customer_portal_labels.add_remove_product;
    if (swap) {
      return {
        ...labels,
        title: labels.swap_modal,
        save: labels.next,
      };
    }

    return {
      ...labels,
      title: labels.add_modal,
      save: labels.save,
    };
  };

  /**
   * @param {number[]} items
   */
  #onProductSelectorVisibleItemsLoaded = items => {
    this.#productSelectorVisibleItems = items;
    this.#productSelector.setLoading(false);
  };

  /**
   * @param {Object} event
   * @returns {string|null}
   */
  #getEventAction = event => {
    const target = event.target.closest(`[${ACTION_ATTRIBUTE}]`);
    return target ? target.getAttribute(ACTION_ATTRIBUTE) : null;
  }

  /**
   * @param {Object} event
   * @returns {boolean}
   */
  #handleFrequencyActions = event => {
    const action = this.#getEventAction(event);

    // change frequency button handler
    if (action === Actions.LIST.SHOW_FREQUENCY_PICKER) {
      event.preventDefault();
      this.#triggerEvent(Actions.EVENTS.SHOW_FREQUENCY_PICKER_CLICK);
      return true;
    }

    // cancel change frequency button handler
    if (action === Actions.LIST.HIDE_FREQUENCY_PICKER) {
      event.preventDefault();
      this.#triggerEvent(Actions.EVENTS.HIDE_FREQUENCY_PICKER_CLICK);
      return true;
    }

    // confirm frequency update handler
    if (action === Actions.LIST.UPDATE_FREQUENCY) {
      this.#updateFrequency();
      return true;
    }

    return false;
  }

  /**
   * @param {Object} event
   * @returns {boolean}
   */
  #handleOrderActions = event => {
    const action = this.#getEventAction(event);

    // change next order date button handler
    if (action === Actions.LIST.SHOW_NEXT_ORDER_DATE_PICKER) {
      event.preventDefault();
      this.#triggerEvent(Actions.EVENTS.SHOW_NEXT_ORDER_DATE_PICKER_CLICK);
      return true;
    }

    // cancel change next order date button handler
    if (action === Actions.LIST.HIDE_NEXT_ORDER_DATE_PICKER) {
      event.preventDefault();
      this.#triggerEvent(Actions.EVENTS.HIDE_NEXT_ORDER_DATE_PICKER_CLICK);
      return true;
    }

    // confirm frequency update handler
    if (action === Actions.LIST.UPDATE_NEXT_ORDER_DATE) {
      this.#updateNextOrderDate();
      return true;
    }

    return false;
  }

  /**
   * @param {Object} event
   * @returns {boolean}
   */
  #handleFulfillmentActions = event => {
    const action = this.#getEventAction(event);
    const fulfillment = event.target.closest('.spurit-ros__fulfillment');

    // show fulfillment date picker
    if (action === Actions.LIST.SHOW_FULFILLMENT_DATE_PICKER) {
      event.preventDefault();
      this.#triggerEvent(Actions.EVENTS.SHOW_FULFILLMENT_DATE_PICKER_CLICK, fulfillment);
      return true;
    }

    // hide fulfillment date picker
    if (action === Actions.LIST.HIDE_FULFILLMENT_DATE_PICKER) {
      event.preventDefault();
      this.#triggerEvent(Actions.EVENTS.HIDE_FULFILLMENT_DATE_PICKER_CLICK, fulfillment);
      return true;
    }

    // save new fulfillment date
    if (action === Actions.LIST.SAVE_FULFILLMENT_DATE) {
      event.preventDefault();
      this.#updateFulfillmentDate(fulfillment);
      return true;
    }

    return false;
  }

  /**
   * @param {Object} event
   * @returns {boolean}
   */
  #handleSubscriptionActions = event => {
    const action = this.#getEventAction(event);

    // confirm subscription pause/resume handler
    if (
      (this.#permissions.canPause && action === Actions.LIST.PAUSE_SUBSCRIPTION)
      || action === Actions.LIST.RESUME_SUBSCRIPTION
    ) {
      this.#toggleSubscription(action === Actions.LIST.PAUSE_SUBSCRIPTION);
      return true;
    }

    // confirm subscription cancel handler
    if (this.#permissions.canCancel && action === Actions.LIST.CANCEL_SUBSCRIPTION) {
      this.#cancelSubscription();
      return true;
    }

    // confirm order skip handler
    if (this.#permissions.canSkipPayment && action === Actions.LIST.SKIP_ORDER) {
      this.#skipNextOrder();
      return true;
    }

    // confirm order now handler
    if (this.#permissions.canMakeAdditionalOrder) {
      if ([Actions.LIST.ORDER_NOW, Actions.LIST.ORDER_NOW_FAILED, Actions.LIST.ORDER_NOW_BOTH].includes(action)) {
        this.#orderNow(action);
        return true;
      }
    }

    // update payment method button handler
    if (action === Actions.LIST.UPDATE_PAYMENT_METHOD) {
      this.#requestNewPaymentMethod();
      return true;
    }

    // confirm shipping address changes
    if (action === Actions.LIST.SAVE_ADDRESS) {
      this.#updateShippingAddress();
      return true;
    }

    return false;
  }

  /**
   * @param {Object} event
   * @returns {boolean}
   */
  #handleQuantityActions = event => {
    const action = this.#getEventAction(event);

    // change product quantity button handler
    if (action === Actions.LIST.SHOW_PRODUCT_QUANTITY_PICKER) {
      this.#triggerEvent(Actions.EVENTS.SHOW_PRODUCT_QUANTITY_PICKER_CLICK, this.#getItemQtySection(event));
      return true;
    }

    // cancel change product quantity button handler
    if (action === Actions.LIST.HIDE_PRODUCT_QUANTITY_PICKER) {
      event.preventDefault();
      this.#triggerEvent(Actions.EVENTS.HIDE_PRODUCT_QUANTITY_PICKER_CLICK, this.#getItemQtySection(event));
      return true;
    }

    // confirm product quantity update handler
    if (action === Actions.LIST.UPDATE_PRODUCT_QUANTITY) {
      event.preventDefault();
      this.#updateProductQty(this.#getItemQtySection(event));
      return true;
    }

    // confirm box quantity update handler
    if (action === Actions.LIST.UPDATE_BOX_QUANTITY) {
      event.preventDefault();
      this.#updateBoxQty(this.#getItemQtySection(event));
      return true;
    }

    return false;
  }

  /**
   * @param {Object} event
   * @returns {boolean}
   */
  #handleAddRemoveItem = event => {
    const labels = this.#config.settings.customer_portal_labels.add_remove_product;
    const productAdditionAllowed = this.#permissions.canAddProduct(this.#subscription);
    const oneTimePurchaseAllowed = this.#permissions.canAddOneTimePurchase(this.#subscription);

    const action = this.#getEventAction(event);

    if (action === Actions.LIST.SHOW_ADD_ITEM_MODAL) {
      if (!productAdditionAllowed && !oneTimePurchaseAllowed) {
        return false;
      }

      if (productAdditionAllowed && oneTimePurchaseAllowed) {
        this.#popupManager.showNewProductTypePopup(labels);
        return true;
      }

      if (oneTimePurchaseAllowed) {
        this.#showProductSelectorForOneTimePurchase();
      } else {
        this.#showProductSelectorForSubscription();
      }

      return true;
    }

    if (action === Actions.LIST.ADD_ITEM_AS_ONE_TIME_PURCHASE && oneTimePurchaseAllowed) {
      this.#showProductSelectorForOneTimePurchase();
      return true;
    }

    if (action === Actions.LIST.ADD_ITEM_AS_SUBSCRIPTION && productAdditionAllowed) {
      this.#showProductSelectorForSubscription();
      return true;
    }

    if (action === Actions.LIST.REMOVE_ITEM) {
      const {itemToDelete} = this.#popupManager.storage;
      if (itemToDelete.box) {
        if (this.#permissions.canRemoveBox(this.#subscription)) {
          this.#removeBox(itemToDelete.id);
        }
      } else if (this.#permissions.canRemoveProduct(this.#subscription, itemToDelete.id)) {
        this.#removeProduct(itemToDelete.id);
      }

      return true;
    }

    if (action === Actions.LIST.SHOW_SWAP_ITEM_MODAL) {
      const lineId = event.target.closest('[data-line-id]')?.dataset.lineId;
      if (lineId) {
        this.#showProductSelectorForSwap(lineId);
      } else {
        const boxId = event.target.closest('[data-box-id]')?.dataset.boxId;
        this.#goToBoxEditor(+boxId);
      }

      return true;
    }

    if (action === Actions.LIST.SWAP_ITEMS) {
      this.#swapItems();
      return true;
    }

    return false;
  }

  /**
   *
   */
  #initProductSelector = () => {
    this.#productSelector = resolve(ProductSelector);
    this.#productSelector.setLoading(true);
  }

  /**
   *
   */
  #showProductSelectorForOneTimePurchase = () => {
    this.#showProductSelector(true, this.#addItemsAsOneTimePurchase);
  };

  /**
   *
   */
  #showProductSelectorForSubscription = () => {
    this.#showProductSelector(false, this.#addItemsAsSubscriptions);
  };

  /**
   * @param {string} lineId
   */
  #showProductSelectorForSwap = lineId => {
    const itemToRemove = this.#subscription.subscription_contract_products.find(p => p.line_id === lineId);
    this.#showProductSelector(false, this.#showSwapConfirmation.bind(this, itemToRemove), true);
  };

  /**
   * @param {number} boxId
   */
  #goToBoxEditor = boxId => {
    const box = this.#subscription.boxes.find(b => b.id === boxId);
    this.#api.shopify.getPages().then(pages => {
      const page = pages.find(p => p.id === box.box_page.page_id);
      window.location.href = `/pages/${page.handle}?box=${boxId}`;
    });
  }

  /**
   * @param {SubscriptionContractProduct} itemToRemove
   * @param {Object} itemToAdd
   */
  #showSwapConfirmation = (itemToRemove, [itemToAdd]) => {
    this.#productSelector.hide();
    this.#popupManager.showSwapConfirmation(itemToRemove, itemToAdd);
  };

  /**
   * @param {boolean} allProductsAllowed
   * @param {function} onItemsSelected
   * @param {boolean} swap
   */
  #showProductSelector = (allProductsAllowed, onItemsSelected, swap = false) => {
    this.#popupManager.closePopup();

    this.#productSelector.resetLoadedItems();
    this.#productSelector.onItemsSelected = onItemsSelected;
    this.#productSelector.visibleItems = allProductsAllowed ? null : this.#productSelectorVisibleItems;
    this.#productSelector.setTexts(this.#getProductSelectorTexts(swap));
    this.#productSelector.multiple = !swap;
    this.#productSelector.show();
  };

  /**
   * @param {Object[]} selectedItems
   */
  #addItemsAsSubscriptions = selectedItems => this.#addSubscriptionItems(selectedItems, false);

  /**
   * @param {Object[]} selectedItems
   */
  #addItemsAsOneTimePurchase = selectedItems => this.#addSubscriptionItems(selectedItems, true);

  /**
   * @param {Object[]} selectedItems
   * @param {boolean} oneTimePurchase
   */
  #addSubscriptionItems = (selectedItems, oneTimePurchase) => {
    this.#api.contract.addSubscriptionItems(this.#subscription.id, selectedItems.map(i => i.id), oneTimePurchase)
      .then(result => {
        this.#productSelector.addDisabledItems(result.items.map(i => +i.variant_id));
        this.#triggerEvent(Actions.EVENTS.SUBSCRIPTION_ITEMS_ADDED, {selectedItems, result});
      })
      .finally(() => this.#productSelector.hide());
  };

  /**
   *
   */
  #swapItems = () => {
    this.#popupManager.showLoading();

    const {swap} = this.#popupManager.storage;
    this.#api.contract.swapSubscriptionItems(this.#subscription.id, swap.delete.line_id, swap.add)
      .then(response => {
        this.#productSelector.addDisabledItems(response.items.map(i => +i.variant_id));
        this.#productSelector.removeDisabledItems([+swap.delete.variant_id]);

        this.#triggerEvent(Actions.EVENTS.SUBSCRIPTION_ITEMS_REPLACED, {
          deleted: swap.delete.line_id,
          added: response.items[0],
          error: response.error,
        });
      })
      .finally(() => this.#popupManager.closePopup());
  };

  /**
   * @param {Object} event
   * @returns {HTMLElement}
   */
  #getItemQtySection = event => event.target.closest('.spurit-ros__product-qty__section');

  /**
   * @param {HTMLElement} productQtySection
   */
  #updateProductQty = productQtySection => {
    const lineId = productQtySection.querySelector('input[name="spurit-ros-line-id"]').value;
    const quantity = +productQtySection.querySelector('input[name="spurit-ros-product-quantity"]').value;
    const product = this.#subscription.getProductByLineId(lineId);
    if (product.quantity === quantity) {
      this.#triggerEvent(Actions.EVENTS.HIDE_PRODUCT_QUANTITY_PICKER_CLICK, productQtySection);
      return;
    }
    product.setQuantity(quantity);
    const {subscription_contract_products} = this.#subscription;
    this.#api.contract.updateProductQuantity(this.#subscription.id, lineId, quantity).catch(e => console.warn(e));
    this.#triggerEvent(Actions.EVENTS.HIDE_PRODUCT_QUANTITY_PICKER_CLICK, productQtySection);
    this.#triggerEvent(Actions.EVENTS.PRODUCT_QUANTITY_UPDATED, {subscription_contract_products});
  }

  /**
   * @param {HTMLElement} qtySection
   */
  #updateBoxQty = qtySection => {
    const boxId = +qtySection.querySelector('input[name="spurit-ros-box-id"]').value;
    const quantity = +qtySection.querySelector('input[name="spurit-ros-box-quantity"]').value;
    const box = this.#subscription.boxes.find(b => b.id === boxId);
    if (box.quantity === quantity) {
      this.#triggerEvent(Actions.EVENTS.HIDE_PRODUCT_QUANTITY_PICKER_CLICK, qtySection);
      return;
    }

    box.setQuantity(quantity);

    this.#api.box.setQuantity(boxId, quantity).catch(e => console.warn(e));
    this.#triggerEvent(Actions.EVENTS.HIDE_PRODUCT_QUANTITY_PICKER_CLICK, qtySection);
    this.#triggerEvent(Actions.EVENTS.BOX_QUANTITY_UPDATED, {boxes: this.#subscription.boxes});
  }

  /**
   *
   */
  #cancelSubscription = () => {
    const reason = this.#popupManager.selectedCancellationReason;
    this.#popupManager.showLoading();
    this.#api.contract.cancelSubscription(this.#subscription.id, reason)
      .then(response => {
        if (response.status !== STATUS_SUCCESS) {
          throw new Error(DEFAULT_ERROR);
        }

        this.#triggerEvent(Actions.EVENTS.SUBSCRIPTION_CANCELLED, {
          paused: false,
          cancelled: true,
          status: CANCELLED,
        });
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  }

  /**
   * @param {string} mode
   */
  #orderNow = mode => {
    this.#popupManager.showLoading();
    this.#api.contract.orderNow(this.#subscription.id, mode)
      .then(response => {
        if (response.status !== STATUS_SUCCESS) {
          throw new Error(DEFAULT_ERROR);
        }

        const updates = {};
        if (mode !== Actions.LIST.ORDER_NOW_FAILED) {
          updates.next_order_date = this.#subscription.frequency.getNextOrderDateAfter(
            this.#subscription.next_order_date,
          );
        }

        this.#triggerEvent(Actions.EVENTS.ORDER_NOW, updates);
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  }

  /**
   * @param {HTMLElement} fulfillmentRow
   */
  #updateFulfillmentDate = fulfillmentRow => {
    const date = fulfillmentRow.querySelector('input[type="date"]').value;
    const fulfillment = this.#subscription.getFulfillment(+fulfillmentRow.dataset.id);
    if (fulfillment.fulfillAt === date) {
      this.#triggerEvent(Actions.EVENTS.HIDE_FULFILLMENT_DATE_PICKER_CLICK, fulfillmentRow);
      return;
    }

    fulfillment.setFulfillmentDate(date);

    this.#api.contract.updateFulfillmentDate(this.#subscription.id, fulfillment.id, date).catch(e => console.warn(e));
    this.#triggerEvent(Actions.EVENTS.HIDE_FULFILLMENT_DATE_PICKER_CLICK, fulfillmentRow);
    this.#triggerEvent(Actions.EVENTS.FULFILLMENT_DATE_UPDATED, this.#subscription.subscription_contract_orders);
  }

  /**
   *
   */
  #requestNewPaymentMethod = () => {
    this.#popupManager.showLoading();
    this.#api.contract.requestNewPaymentMethod(this.#subscription.id)
      .then(response => {
        if (response.status !== STATUS_SUCCESS) {
          throw new Error(DEFAULT_ERROR);
        }

        this.#triggerEvent(Actions.EVENTS.PAYMENT_METHOD_REQUESTED);
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  }

  /**
   *
   */
  #skipNextOrder = () => {
    this.#popupManager.showLoading();
    this.#api.contract.skipNextOrder(this.#subscription.id)
      .then(response => {
        if (response.status !== STATUS_SUCCESS) {
          throw new Error(DEFAULT_ERROR);
        }

        this.#triggerEvent(Actions.EVENTS.ORDER_SKIPPED, {
          next_order_date: this.#subscription.frequency.getNextOrderDateAfter(this.#subscription.next_order_date),
        });
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  }

  /**
   * @param {boolean} pause
   */
  #toggleSubscription = pause => {
    const action = pause ? 'pauseSubscription' : 'resumeSubscription';
    const event = pause ? Actions.EVENTS.SUBSCRIPTION_PAUSED : Actions.EVENTS.SUBSCRIPTION_RESUMED;
    const updates = pause ? {paused: true, status: PAUSED} : {paused: false, status: ACTIVE};

    this.#popupManager.showLoading();
    this.#api.contract[action](this.#subscription.id)
      .then(response => {
        if (response.status !== STATUS_SUCCESS) {
          throw new Error(DEFAULT_ERROR);
        }

        this.#triggerEvent(event, updates);
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  }

  /**
   * @param {string} name
   * @param {Object} params
   */
  #triggerEvent (name, params = {}) {
    if (this.#listeners[name]) {
      this.#listeners[name].forEach(listener => listener(params));
    }
  }

  /**
   *
   */
  #updateFrequency = () => {
    const unit = document.querySelector('#spurit-ros-frequency-unit').value;
    const unitsCount = document.querySelector('#spurit-ros-frequency-number').value;

    const frequency = {...this.#subscription.frequency, frequency_number: unitsCount, frequency_unit: unit};

    this.#updateSchedule(
      Actions.EVENTS.FREQUENCY_UPDATED,
      frequency,
      getNewOrderDate(frequency, this.#subscription.last_order_date),
    );
  }

  /**
   *
   */
  #updateNextOrderDate = () => {
    this.#updateSchedule(
      Actions.EVENTS.NEXT_ORDER_DATE_UPDATED,
      this.#subscription.frequency,
      new Date(document.querySelector('input[name="spurit-ros-next-order-date"]').value),
    );
  };

  /**
   * @param {string} successEventName
   * @param {Object} frequency
   * @param {Date} nextOrderDate
   */
  #updateSchedule (successEventName, frequency, nextOrderDate) {
    this.#popupManager.showLoading();
    this.#api.contract.updateSchedule(this.#subscription.id, nextOrderDate.toISOString(), frequency)
      .then(response => {
        if (response.status !== STATUS_SUCCESS) {
          throw new Error(DEFAULT_ERROR);
        }

        this.#triggerScheduleUpdatedEvent(successEventName, frequency, nextOrderDate);
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  }

  /**
   * @param {string} successEventName
   * @param {Object} frequency
   * @param {Date} nextOrderDate
   */
  #triggerScheduleUpdatedEvent (successEventName, frequency = null, nextOrderDate = null) {
    const eventParams = {};
    if (frequency) {
      eventParams.frequency = frequency;
    }
    if (nextOrderDate) {
      eventParams.next_order_date = nextOrderDate;
    }
    this.#triggerEvent(successEventName, eventParams);
  }

  /**
   *
   */
  #updateShippingAddress = () => {
    this.#popupManager.hideError();

    const data = {};
    const invalid = [];
    const form = document.querySelector('.spurit-ros__address-form');
    const inputs = form.querySelectorAll('input, select');

    for (let i = 0; i < inputs.length; i++) {
      data[inputs[i].name] = inputs[i].value;

      if (inputs[i].hasAttribute('required') && !inputs[i].value) {
        invalid.push(inputs[i]);
      }
    }

    if (invalid.length) {
      this.#popupManager.showError(this.#config.settings.customer_portal_labels.shipping_address.required, invalid);
      return;
    }

    this.#popupManager.showLoading();
    this.#api.contract.updateShippingAddress(this.#subscription.id, data)
      .then(response => response.text())
      .then(response => {
        if (response) {
          this.#popupManager.showAddressPopup(data);
          this.#popupManager.showError(response);
          return;
        }

        this.#triggerEvent(Actions.EVENTS.SHIPPING_ADDRESS_UPDATED, {shipping_address: data});
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  }

  /**
   * @param {string} lineId
   */
  #removeProduct = lineId => {
    this.#popupManager.showLoading();
    this.#api.contract.removeSubscriptionItem(this.#subscription.id, lineId)
      .then(() => {
        const removedItem = this.#subscription.subscription_contract_products.find(item => item.line_id === lineId);
        this.#productSelector.removeDisabledItems([+removedItem.variant_id]);
        this.#triggerEvent(Actions.EVENTS.SUBSCRIPTION_ITEM_REMOVED, {lineId});
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  };

  /**
   * @param {number} id
   */
  #removeBox = id => {
    this.#popupManager.showLoading();
    this.#api.box.delete(id)
      .then(() => {
        const removedBox = this.#subscription.boxes.find(box => box.id === +id);
        removedBox.items.forEach(item => this.#productSelector.removeDisabledItems([+item.variant_id]));
        this.#triggerEvent(Actions.EVENTS.SUBSCRIPTION_BOX_REMOVED, {id});
      })
      .catch(() => this.#triggerEvent(Actions.EVENTS.ERROR, DEFAULT_ERROR))
      .finally(() => this.#popupManager.closePopup());
  };
}
