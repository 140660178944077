import ApiBase from './ApiBase';

const ROUTES = {
  PAGES: '/pages.json',
  CART: {
    GET: '/cart.js',
    ADD: '/cart/add.js',
    CHANGE: '/cart/change.js',
    UPDATE: '/cart/update.js',
  },
};

export default class Shopify extends ApiBase {

  /**
   * @returns {Promise<any>}
   */
  getPages = () => {
    return fetch(ROUTES.PAGES)
      .then(response => response.json())
      .then(r => r.pages);
  };

  /**
   * @returns {Promise<any>}
   */
  getCart = () => fetch(ROUTES.CART.GET).then(response => response.json());

  /**
   * @param {Object[]} items
   * @returns {Promise}
   */
  addToCart = items => fetch(ROUTES.CART.ADD, this.makePostData({items})).then(r => r.json());

  /**
   * @param {number} line
   * @param {number} quantity
   * @returns {Promise<Response>}
   */
  setCartLineQuantity = (line, quantity) => {
    return fetch(ROUTES.CART.CHANGE, this.makePostData({line, quantity}));
  };

  /**
   * @param {Object[]} items
   * @returns {Promise<Response>}
   */
  removeCartItems = items => {
    const updates = items.reduce((result, item) => {
      result[item.key] = 0;
      return result;
    }, {});

    return this.updateCartItemQuantities(updates);
  };

  /**
   * @param {Object} updates
   * @returns {Promise<Response>}
   */
  updateCartItemQuantities = updates => fetch(ROUTES.CART.UPDATE, this.makePostData({updates}));
}