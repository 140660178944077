import resolve from '../resolve';
import Rules from '../components/Rules';
import {STORE} from '../classes/Rule';
import {AppLibrary} from '../helpers/AppLibrary';
import AppConfig from '../config/config';
import Widget from './Widget';
import Selectors from '../components/Selectors';
import UpsellAtc from '../components/atc/UpsellAtc';
import UpsellModal from './UpsellModal';
import {CartEvents} from './EventListener';
import Rule from '../containers/Rule';

const PRODUCT_LINK_SELECTOR = 'a[href^="/products/"]';
const WIDGET_SELECTOR = '.ros-cart-widget';
const SELLING_PLAN_SELECTOR = '.ros-cart-widget__details-item select';
const UPDATE_BUTTON_SELECTOR = '.ros-cart-widget__details-item button.button--primary';

const TIMEOUT_AFTER_CART_UPDATE = 250;

export default class Upsell {

  /**
   * @type {boolean}
   */
  addingToCart = false;

  /**
   * @property {AppLibrary}
   */
  #library;

  /**
   * @property {Object}
   */
  #global;

  /**
   * @property {AppConfig}
   */
  #config;

  /**
   * @property {Rule}
   */
  #rules;

  /**
   * @type {Selectors}
   */
  #selectors;

  /**
   * @type {UpsellAtc}
   */
  #atc;

  /**
   * @type {UpsellModal}
   */
  #modal;

  /**
   * @type {Object[]}
   */
  #cartItems;

  /**
   * @type {Map}
   */
  #widgets = new Map();

  /**
   * @type {boolean}
   */
  #isFirstRun = true;

  /**
   *
   */
  constructor () {
    this.#library = resolve(AppLibrary);
    this.#global = resolve('global');
    this.#rules = resolve(Rules);
    this.#config = resolve(AppConfig);
    this.#selectors = resolve(Selectors);
    this.#atc = resolve(UpsellAtc);
    this.#modal = resolve(UpsellModal, [this.#addAllSelectedSubscriptions]);
  }

  /**
   *
   */
  run () {
    const widgetPlaces = this.#widgetPlaces;
    if (!widgetPlaces.length) {
      return;
    }

    if (this.#isFirstRun) {
      this.#onFirstRun();
    }

    this.#cartItems = this.#cartAdapter.getItems();

    for (const place of widgetPlaces) {
      this.#processUpsellPosition(place);
    }
  }

  /**
   * @returns {boolean}
   */
  get isModalShown () {
    return this.#modal.shown;
  }

  /**
   * @returns {Object}
   */
  get #cartAdapter () {
    return this.#global.cartPool.getAdapter(this.#library.appName);
  }

  /**
   * @returns {HTMLElement[]}
   */
  get #activeWidgets () {
    const widgets = [...document.querySelectorAll(`${WIDGET_SELECTOR}`)];
    return widgets.filter(w => w.querySelector('input[type="checkbox"]:checked'));
  }

  /**
   * @returns {NodeListOf<Element>}
   */
  get #widgetPlaces () {
    const selector = this.#selectors.cartUpsellSelector;
    return selector?.selector ? document.querySelectorAll(selector.selector) : [];
  }

  /**
   * @returns {CartUpsellTextsWidget}
   */
  get #widgetPhrases () {
    const phrases = this.#config.upsells.cart.texts.widget;

    const labels = this.#config.settings.text_labels;
    phrases.frequency_label = labels.frequency;
    phrases.per_delivery_label = labels.price_per_delivery;

    return phrases;
  }

  /**
   *
   */
  #onFirstRun = () => {
    if (this.#config.isCartUpsellModalEnabled) {
      this.#initModal();
    }

    this.#addListeners();
    this.#isFirstRun = false;
  };

  /**
   * @param {HTMLElement} targetElement
   */
  #processUpsellPosition = targetElement => {
    const productLink = this.#global.utils.nearest(targetElement, PRODUCT_LINK_SELECTOR);
    const cartItem = this.#getCartItemByLink(productLink);
    if (!cartItem || cartItem.selling_plan_allocation) {
      return;
    }

    this.#getActualRuleForCartItem(cartItem).then(rule => {
      if (!rule) {
        return;
      }

      this.#widgets.set(targetElement, {rule: new Rule(rule), cartItem});
      this.#renderWidget(targetElement, rule, cartItem);
    });
  };

  /**
   * @param {HTMLElement} targetElement
   * @param {Rule} rule
   * @param {Object} item
   */
  #renderWidget = (targetElement, rule, item) => {
    const moneyFormat = this.#global.prices.money.getFormatDefault();
    const enabled = !!this.#widgets.get(targetElement).enabled;

    const html = Widget.render(this.#widgetPhrases, rule, item, moneyFormat, enabled);
    targetElement.insertAdjacentHTML(this.#selectors.cartUpsellPosition, html);
  };

  /**
   * @param {HTMLLinkElement} a
   * @returns {number}
   */
  #getVariantIdFromLink = a => +a.href.split('variant=')[1];

  /**
   * @param {Object} cartItem
   * @returns {Promise}
   */
  #getActualRuleForCartItem = cartItem => {
    let availableRules = this.#rules.activeRules;
    if (!this.#config.isEligible) {
      availableRules = availableRules.filter(r => r.invoice.is_enabled);
    }
    availableRules.sort((r1, r2) => new Date(r2.created_at) - new Date(r1.created_at));

    return this.#searchActualRuleRecursive(cartItem, availableRules);
  };

  /**
   * @param {Object} cartItem
   * @param {Rule[]} rules
   * @param {number} index
   * @returns {Promise}
   */
  #searchActualRuleRecursive = (cartItem, rules, index = 0) => {
    return new Promise(success => {
      if (!rules.length) {
        success(null);
        return;
      }

      const rule = rules[index];
      if (rule.apply_to === STORE) {
        success(rule);
        return;
      }

      this.#rules.convertRuleTargetsToIds(rule).then(targets => {
        const suitableTarget = targets.find(target => {
          return target.pid === cartItem.product_id && (!target.vid || target.vid === cartItem.vid);
        });

        if (suitableTarget) {
          success(rule);
          return;
        }

        index++;
        if (index < rules.length) {
          this.#searchActualRuleRecursive(cartItem, rules, index).then(success);
          return;
        }

        success(null);
      });
    });
  };

  /**
   * @param {HTMLLinkElement} link
   * @returns {Object}
   */
  #getCartItemByLink = link => {
    const vid = this.#getVariantIdFromLink(link);
    if (!vid) {
      return null;
    }

    const itemIndex = this.#cartItems.findIndex(item => item.variant_id === vid);
    return this.#cartItems.splice(itemIndex, 1)[0];
  };

  /**
   *
   */
  #addListeners = () => {
    document.body.addEventListener('change', e => {
      if (e.target.matches('.ros-cart-widget__label input[type="checkbox"]')) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.#toggleWidget(e.target.closest(WIDGET_SELECTOR));
        return;
      }

      if (e.target.matches(SELLING_PLAN_SELECTOR)) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.#selectWidgetFrequency(e.target.closest(WIDGET_SELECTOR));
      }
    });

    document.body.addEventListener('click', e => {
      if (!e.target.closest(UPDATE_BUTTON_SELECTOR)) {
        return true;
      }

      e.preventDefault();
      e.stopImmediatePropagation();
      this.#addSubscriptionToCart(e.target.closest(WIDGET_SELECTOR)).then();

      return false;
    });

    window.addEventListener(CartEvents.change, this.#onCartUpdated);
    window.addEventListener(CartEvents.update, this.#onCartUpdated);
  };

  /**
   *
   */
  #initModal = () => {
    this.#global.checkout.onCheckout(next => {
      const activeWidgets = this.#activeWidgets;
      if (activeWidgets.length) {
        this.#modal.show();
      }

      next(!activeWidgets.length);
    });

    this.#modal.init();
  };

  /**
   *
   */
  #addAllSelectedSubscriptions = () => {
    this.addingToCart = true;
    this.#atc.handle(this.#activeWidgets.map(this.#getWidgetState)).then(() => {
      this.addingToCart = false;
      window.location.href = '/checkout';
    });
  };

  /**
   * @param {HTMLElement} widget
   */
  #toggleWidget = widget => {
    const state = this.#getWidgetState(widget);
    state.enabled = !state.enabled;
    this.#resetWidget(widget);
  };

  /**
   * @param {HTMLElement} widget
   */
  #selectWidgetFrequency = widget => {
    const sellingPlanId = +widget.querySelector('select').value;
    const state = this.#getWidgetState(widget);
    const old = state.rule.subscription_frequencies.find(f => f.selected);
    if (old) {
      old.selected = false;
    }

    const current = state.rule.subscription_frequencies.find(f => f.selling_plan_id === sellingPlanId);
    current.selected = true;

    this.#resetWidget(widget);
  };

  /**
   * @param {HTMLElement} widget
   */
  #addSubscriptionToCart = widget => {
    this.addingToCart = true;
    widget.querySelector(UPDATE_BUTTON_SELECTOR).classList.add('ros-btn_loading');
    return this.#atc.handle([this.#getWidgetState(widget)]).then(() => {
      this.addingToCart = false;
      window.location.reload();
    });
  };

  /**
   * @param {Object} e
   */
  #onCartUpdated = e => {
    setTimeout(() => {
      if (!e || e.detail.errors) {
        return;
      }

      this.#removeWidgets();
      this.run();
    }, TIMEOUT_AFTER_CART_UPDATE);
  };

  /**
   * @param {HTMLElement} widget
   */
  #resetWidget = widget => {
    const targetElement = this.#getWidgetTarget(widget);
    widget.remove();

    const widgetData = this.#widgets.get(targetElement);
    this.#renderWidget(targetElement, widgetData.rule, widgetData.cartItem);
  };

  /**
   *
   */
  #removeWidgets = () => {
    this.#widgets = new Map();
    for (const widget of document.body.querySelectorAll(WIDGET_SELECTOR)) {
      widget.remove();
    }
  };

  /**
   * @param {HTMLElement} widget
   * @returns {Object}
   */
  #getWidgetState = widget => this.#widgets.get(this.#getWidgetTarget(widget));

  /**
   * @param {HTMLElement} widget
   * @returns {HTMLElement}
   */
  #getWidgetTarget = widget => {
    return (
      this.#selectors.cartUpsellPosition === 'afterend'
        ? widget.previousElementSibling
        : widget.nextElementSibling
    );
  };
}