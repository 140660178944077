export default class CountriesManager {

  /**
   * @type {Object}
   */
  #countries;

  /**
   *
   */
  constructor () {
    this.#countries = JSON.parse(JSON.stringify(this.constructor.#getFormattedCountries(window.Countries)));
  }

  /**
   *
   */
  init () {
    this.#countries = JSON.parse(JSON.stringify(this.constructor.#getFormattedCountries(window.Countries)));
  }

  /**
   *
   * @param {string|null} countryCode
   * @param {string|null} provinceCode
   * @returns {Object}
   */
  get (countryCode = null, provinceCode = null) {
    const countries = this.#countries;

    if (countryCode && countries[countryCode]) {
      countries[countryCode].selected = true;

      if (provinceCode && countries[countryCode].provinces[provinceCode]) {
        countries[countryCode].provinces[provinceCode].selected = true;
      }
    }

    return countries;
  }

  /**
   *
   * @param {string|null} countryCode
   * @param {string|null} selectedProvince
   * @returns {Object}
   */
  getProvinces (countryCode = null, selectedProvince = null) {
    const {provinces} = this.#countries[countryCode];

    if (selectedProvince && provinces[selectedProvince]) {
      provinces[selectedProvince].selected = true;
    }

    return provinces;
  }

  /**
   *
   * @param {Object} countries
   * @returns {Object}
   */
  static #getFormattedCountries (countries) {
    const formatted = {};

    for (const countryName in countries) {
      if (Object.hasOwnProperty.call(countries, countryName)) {
        const countryCode = countries[countryName].code;
        formatted[countryCode] = {name: countryName, code: countryCode, provinces: {}};

        if (countries[countryName].provinces) {
          formatted[countryCode].provinces = CountriesManager.#getFormattedProvinces(countries[countryName].provinces);
        }
      }
    }

    return formatted;
  }

  /**
   *
   * @param {Object} provinces
   * @returns {Object}
   */
  static #getFormattedProvinces (provinces) {
    const formatted = {};
    for (const provinceName in provinces) {
      if (Object.hasOwnProperty.call(provinces, provinceName)) {
        const provinceCode = provinces[provinceName].code;
        formatted[provinceCode] = {name: provinceName, code: provinceCode};
      }
    }

    return formatted;
  }
}