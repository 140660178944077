//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SettingsCustomerPortalLabelsOrderNowAuto from './SettingsCustomerPortalLabelsOrderNowAuto';
import SettingsCustomerPortalLabelsOrderNowInvoice from './SettingsCustomerPortalLabelsOrderNowInvoice';


export default class SettingsCustomerPortalLabelsOrderNow extends AbstractParent {


  /**
   * @type  {string}
   */
  cancel = ___('Cancel').toUpperCase();

  /**
   * @type  {string}
   */
  ok = ___('OK');

  /**
   * @type  {string}
   */
  proceeding_with_failed = ___('You are proceeding with failed order. Proceed with failed order only or both failed and new one? If you proceed with both your next order date will be moved.');

  /**
   * @type  {string}
   */
  proceed_with_both = ___('No, proceed with both');

  /**
   * @type  {string}
   */
  proceed_with_failed = ___('Yes, proceed with failed order');

  /**
   * @type  {SettingsCustomerPortalLabelsOrderNowAuto}
   */
  auto;

  /**
   * @type  {SettingsCustomerPortalLabelsOrderNowInvoice}
   */
  invoice;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      auto: SettingsCustomerPortalLabelsOrderNowAuto,
      invoice: SettingsCustomerPortalLabelsOrderNowInvoice,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}