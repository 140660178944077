import { COLLECTIONS, STORE } from "../../classes/Rule";
import resolve from "../../resolve";
import AppConfig from "../../config/config";
import Product from "../widget/Product";
import Rules from "../Rules";
import Storage from "../../config/Storage";
import Api from '../api/Api';

export const CUSTOM_FREQUENCY = 'custom';

export default class AtcAbstract {

  /**
   * @type {AppConfig}
   */
  config;

  /**
   * @type {Object}
   */
  global;

  /**
   * @type {Api}
   */
  api;

  /**
   * @type {Product}
   */
  product;

  /**
   * @type {Storage}
   */
  storage;

  /**
   * @type {Rules}
   */
  rules;

  /**
   * @type {HTMLElement}
   */
  button;

  /**
   *
   */
  constructor () {
    this.config = resolve(AppConfig);
    this.global = resolve('global');
    this.api = resolve(Api);
    this.product = resolve(Product);
    this.storage = resolve(Storage);
    this.rules = resolve(Rules);
  }

  /**
   * abstract method
   * @returns {{frequency_unit, frequency_number}}
   */
  get customFrequency () {
    return {};
  }

  /**
   * @returns {Promise}
   */
  async getAddedVariantId () {
    const product = await this.product.resolve(this.button);
    return product.selected_variant.id;
  }

  /**
   *
   * @param {string|number} planId
   * @param {Rule} rule
   * @param {Object[]} lineItems
   * @returns {Promise}
   */
  async handleFixedFrequency (planId, rule, lineItems) {
    await this.prepareSellingPlan(rule);
    const name = this.getFixedFrequencyName(rule, planId.toString());
    this.handleLineItems(lineItems, planId, rule, name);
    await this.onAtcProcessed(rule);
  }

  /**
   *
   * @param {Rule} rule
   * @param {string} planId
   * @returns {string}
   */
  getFixedFrequencyName (rule, planId) {
    const plan = rule.subscription_frequencies.find(f => f.selling_plan_id.toString() === planId);
    let {name} = plan;
    if (!name) {
      const frequencyNumber = plan.frequency.frequency_number.toString();
      const frequencyUnit = this.config.getPeriodLabel(plan.frequency.frequency_unit);
      name = `${frequencyNumber} ${frequencyUnit}`;
    }

    return name;
  }

  /**
   *
   * @param {{frequency_unit, frequency_number}} frequency
   * @param {Rule} rule
   * @param {Object[]} lineItems
   * @returns {Promise}
   */
  async handleCustomFrequency (frequency, rule, lineItems) {
    const planName = `${frequency.frequency_number} ${this.config.getPeriodLabel(frequency.frequency_unit)}`;
    const newPlanId = await this.prepareNewSellingPlan(rule, frequency);
    this.handleLineItems(lineItems, newPlanId, rule, planName);

    await this.onAtcProcessed(rule);
  }

  /**
   *
   *
   * @param {Rule} rule
   * @param {{frequency_number: number, frequency_unit: string}} frequency
   * @returns {Promise}
   */
  prepareNewSellingPlan (rule, frequency) {
    const unitLabel = this.config.getPeriodLabel(frequency.frequency_unit);
    const name = `${frequency.frequency_number} ${unitLabel}`;

    return this.api.widget.createSellingPlan(rule, {name, ...frequency})
      .then(response => this.prepareSellingPlan(rule).then(() => response));
  }

  /**
   *
   * @param {Rule} rule
   * @returns {Promise<unknown>|void}
   */
  async prepareSellingPlan (rule) {
    if (rule.apply_to === STORE || rule.apply_to === COLLECTIONS) {
      await this.#applyRuleToCurrentProduct(rule);
    }
  }

  /**
   *
   * @param {HTMLElement} button
   * @returns {AtcAbstract}
   */
  setButton (button) {
    this.button = button;
    return this;
  }

  /**
   *
   * @param {Rule} rule
   * @returns {Promise<unknown>}
   */
  async onAtcProcessed (rule) {
    const product = await this.product.resolve(this.button);
    await this.api.widget.trackAtc(rule.id, product.id);
  }

  /**
   *
   * @param {Object[]} lineItems
   * @param {string} name
   * @param {string|number} planId
   * @param {Rule} rule
   * @returns {Array}
   */
  handleLineItems (lineItems, planId, rule, name = 'Subscription') {
    if (this.config.isEligible) {
      lineItems = this.setLineItemsSellingPlan(lineItems, name, planId);
    }
    lineItems = this.setLineItemsProperties(lineItems, name);

    this.saveContractDataToStorage(lineItems, name, planId, rule);

    return lineItems;
  }

  /**
   * @param {Array} lineItems
   * @param {string} frequency
   * @returns {Array}
   */
  setLineItemsProperties (lineItems, frequency) {
    return lineItems.map(item => {
      if (!this.config.isEligible || (this.config.isEligible && !this.config.snippet.hasCartLabel)) {
        item.properties = {...item.properties, [this.config.settings.general.cart_label || ' ']: frequency};
      }

      return item;
    });
  }

  /**
   *
   * @param {Array} lineItems
   * @param {string} frequency
   * @param {string|number} planId
   * @param {Rule} rule
   * @returns {Array}
   */
  saveContractDataToStorage (lineItems, frequency, planId, rule) {
    lineItems.forEach(item => {
      this.storage.addArrayItem(
        'contracts',
        {
          ruleId: rule.id,
          variantId: item.id,
          sellingPlanId: planId,
          frequency: frequency,
        },
        (i, newItem) => i.variantId === newItem.variantId && i.frequency === newItem.frequency,
      );
    });
  }

  /**
   *
   * @param {Array} lineItems
   * @param {string} frequency
   * @param {string|number} planId
   * @returns {Object[]}
   */
  setLineItemsSellingPlan (lineItems, frequency, planId) {
    return lineItems.map(item => {
      if (!item.selling_plan_allocation) {
        item.selling_plan = planId;
      }

      return item;
    });
  }

  /**
   * @param {Rule} rule
   * @returns {Promise<Response>}
   */
  #applyRuleToCurrentProduct = rule => {
    return this.getAddedVariantId().then(vid => this.api.widget.addVariantToRule(rule, vid));
  }
}