/* eslint func-names: 0 */
import { defaultsDeep } from "../helpers/utils";

/**
 * @param {string} appName
 * @constructor
 */
export const Customization = function (appName) {
  const defaultCustomization = {
    onConfigReady: (config) => null,
    onListenerReady: (listener) => null,
    hideBuyNowSection: false,
    buyNow: {
      hide: null,
    },
    selectedLineItem: {
      optionsSelector: '',
      getOptions: (atc) => [],
    },
    isCheckoutEnabled: true,
  };

  const isCustomizationSet = (
      typeof (Spurit) !== 'undefined'
      && typeof (Spurit[appName]) !== 'undefined'
      && typeof (Spurit[appName].customization) !== 'undefined'
  );
  let customization = defaultCustomization;
  if (isCustomizationSet) {
    customization = defaultsDeep(Spurit[appName].customization, customization);
  }
  for (const key in customization) {
    if ({}.hasOwnProperty.call(customization, key)) {
      this[key] = customization[key];
    }
  }
};