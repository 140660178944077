import resolve from "../resolve";
import { AppLibrary } from "../helpers/AppLibrary";

const CART_LIFE_TIME = 1209600; // two weeks in seconds
const LOGIN_TOKEN_KEY = 'loginToken';

export default class Storage {

  /**
   * @type {AppLibrary}
   */
  #library;

  /**
   *
   */
  constructor () {
    this.#library = resolve(AppLibrary);
  }

  /**
   *
   * @param {string} name
   * @param {any} newItem
   * @param {function} uniqueCallback
   */
  addArrayItem(name, newItem, uniqueCallback = null) {
    let items = this.getItem(name);
    items = Array.isArray(items) ? items : [];

    if (uniqueCallback) {
      const targetItem = items.find(i => uniqueCallback(i, newItem));
      if (targetItem) {
        return;
      }
    }

    items.push(newItem);

    this.setItem(name, items);
  }

  /**
   *
   * @param {string} name
   * @param {function} filter
   * @return {array}
   */
  filterArray(name, filter) {
    let items = this.getItem(name);
    items = Array.isArray(items) ? items.filter(filter) : [];
    this.setItem(name, items);

    return items;
  }

  /**
   *
   * @param {string} name
   * @param {any} item
   */
  setItemIfDoesntExist(name, item) {
    const value = this.getItem(name);
    if (!value) {
      this.setItem(name, item);
    }
  }

  /**
   *
   * @param {string} name
   * @param {any} item
   */
  setItem(name, item) {
    if (typeof item === 'object' || Array.isArray(item)) {
      item = JSON.stringify(item);
    }
    this.#library.setCookie(name, item, {path: '/', expires: CART_LIFE_TIME});
  }

  /**
   * @param {string} name
   */
  removeItem = name => this.#library.deleteCookie(name);

  /**
   * @param {string} name
   * @return {any|null}
   */
  getItem(name) {
    let item = this.#library.getCookie(name);
    if (typeof item === 'string') {
      try {
        item = JSON.parse(item);
      } catch (e) {
        // ignore
      }
    }

    return item;
  }

  /**
   * @returns {boolean}
   */
  redirectIfNotLoggedIn () {
    if (window.Spurit.globalSnippet.customer_id || this.getItem(LOGIN_TOKEN_KEY)) {
      return false;
    }

    window.location.href = '/account/login';
    return true;
  }

  /**
   *
   */
  setLoginToken = () => {
    const variables = this.#library.requestGetVars();
    if (variables[LOGIN_TOKEN_KEY]) {
      this.setItem(LOGIN_TOKEN_KEY, variables[LOGIN_TOKEN_KEY]);
    }
  }

  /**
   * @return {string|null}
   */
  getLoginToken = () => this.getItem(LOGIN_TOKEN_KEY);
}


