/* eslint-disable max-lines */
import {FAILED as ORDER_FAILED, PAID, PENDING} from "../../classes/SubscriptionContractOrder";
import {ACTIVE, CANCELLED, EXPIRED, FAILED, PAUSED} from "../../classes/SubscriptionContract";
import {fromUtcToTimezone, getPhpDate} from '../../../../assets/js/helpers/dates';
import {getFormattedProductTitle, isEmpty} from '../../../../assets/js/helpers/utils';
import {DAY, MONTH, WEEK, YEAR} from "../../classes/TimeInterval";
import AccountPage from "./AccountPage";
import {FULFILLED, SCHEDULED} from "../../classes/FulfillmentOrder";
import {AppLibrary} from "../../helpers/AppLibrary";
import Mustache from 'mustache';
import Actions from "./Actions";
import {ACTIONS} from "./PopupManager";
import resolve from "../../resolve";
import AppConfig from "../../config/config";
import CountriesManager from "./CountriesManager";
import TemplatesManager from "./TemplatesManeger";
import {Permissions} from './Permissions';
import moment from 'moment-timezone';

export default class VariablesManager {

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Object}
   */
  #global;

  /**
   * @var {CountriesManager}
   */
  #countries;

  /**
   * @var {TemplatesManager}
   */
  #templatesManager;

  /**
   * @type {Permissions}
   */
  #permissions;

  /**
   *
   */
  constructor () {
    this.#config = resolve(AppConfig);
    this.#global = resolve('global');
    this.#countries = resolve(CountriesManager);
    this.#templatesManager = resolve(TemplatesManager);
    this.#permissions = resolve(Permissions);
  }

  /**
   *
   * @param {Object} address
   * @returns {null|Object}
   */
  getFormattedAddress (address) {
    if (isEmpty(address)) {
      return null;
    }

    const formattedAddress = {...address};

    const countries = this.#countries.get();

    const shippingCountryCode = address.country_code;
    if (shippingCountryCode) {
      const shippingCountry = countries[shippingCountryCode];
      formattedAddress.country = shippingCountry?.name;

      const shippingProvinceCode = address.province_code;
      if (shippingProvinceCode) {
        const shippingProvince = shippingCountry ? shippingCountry.provinces[shippingProvinceCode] : null;
        formattedAddress.province = shippingProvince?.name;
      }
    }

    return formattedAddress;
  }

  /**
   * @param {Object} subscription
   * @returns {string}
   */
  getChargeFrequency (subscription) {
    const unitName = this.#config.settings.customer_portal_labels.frequency.deliveries;
    return `${subscription.frequency.deliveries_per_charge} ${unitName}`;
  }

  /**
   *
   * @param {SubscriptionContract[]} subscriptions
   * @returns {Object}
   */
  getDashboardVariables (subscriptions) {
    const labels = this.#config.settings.customer_portal_labels.subscriptions;

    const variables = {
      id_label: labels.subscription,
      products_label: labels.products,
      next_label: labels.next,
      status_label: labels.status,
      lang: AppLibrary.getUrlLang(),
    };

    variables.subscriptions = this.getFormattedSubscriptions(subscriptions);

    return variables;
  }

  /**
   * @param {Object} subscription
   * @returns {string}
   */
  getDeliveryFrequency (subscription) {
    const frequencyLabels = this.#config.settings.customer_portal_labels.frequency;
    if (subscription.invoice.is_enabled) {
      return frequencyLabels.invoice.label;
    }

    return subscription.isPrepaid ? frequencyLabels.auto.label_prepaid : frequencyLabels.auto.label;
  }

  /**
   *
   * @param {SubscriptionContract} contract
   * @returns {Object}
   */
  getActionLabels (contract) {
    const cpLabels = this.#config.settings.customer_portal_labels;

    return {
      status_label: cpLabels.subscriptions.status,
      started_label: cpLabels.subscription.started,
      frequency_label: this.getDeliveryFrequency(contract),
      change_frequency: cpLabels.frequency.button,
      next_order_label: VariablesManager.#getNestedSubscriptionLabel('label', cpLabels.next_payment, contract),
      pause_label: cpLabels.pause[this.#permissions.canPause ? 'button' : 'contact'],
      resume_label: cpLabels.resume[this.#permissions.canResume ? 'button' : 'contact'],
      cancel_label: cpLabels.cancel[this.#permissions.canCancel ? 'button' : 'contact'],
      skip_label: VariablesManager.#getNestedSubscriptionLabel('button', cpLabels.skip, contract),
      order_now_label: VariablesManager.#getNestedSubscriptionLabel('button', cpLabels.order_now, contract),
      payment_method_label: cpLabels.payment_method.label,
      invoice_payment_method_note: cpLabels.payment_method.invoice_note,
      update_payment_method_label: cpLabels.payment_method.update,
      save_frequency_label: cpLabels.frequency.save,
      cancel_frequency_label: cpLabels.frequency.dismiss,
      next_payment_button_text: cpLabels.next_payment.button,
      next_order_date_cancel: cpLabels.next_payment.dismiss,
      next_order_date_save: cpLabels.next_payment.save,
      shipping_address_title: cpLabels.shipping_address.label,
      shipping_address_empty: cpLabels.shipping_address.empty,
      shipping_address_button_text: cpLabels.shipping_address.edit,
      billing_address_title: cpLabels.payment_method.address,
      subscription_type_label: cpLabels.subscription.type,
      charge_label: cpLabels.frequency.charge,
    };
  }

  /**
   *
   * @returns {Object}
   */
  getIntervalLabels () {
    const labels = this.#config.settings.customer_portal_labels.frequency;

    return {
      years: labels.years,
      months: labels.months,
      weeks: labels.weeks,
      days: labels.days,
    };
  }

  /**
   *
   * @param {Object[]} orders
   * @returns {Object[]}
   */
  getFormattedOrders (orders) {
    return orders.map(o => {
      let {status_url} = o;
      if (status_url && status_url.includes('/orders/')) {
        const [token] = status_url.split('/orders/')[1].split('/');
        status_url = `${window.location.origin}${AppLibrary.getUrlLang()}/account/orders/${token}`;
      }

      return {
        created_at: o.created_at ? fromUtcToTimezone(o.created_at, this.#config.timezone, 'YYYY-MM-DD') : '',
        status: this.#orderStatuses[o.status],
        name: this.getOrderDetails(o),
        status_url,
      };
    });
  }

  /**
   * @todo: (discount => discount.after_cycle > 0)?.after_cycle also in resources/assets/js/pages/Subscriptions/components/tables/Items.js
   * @param {Object[]} products
   * @param {string} currency
   * @param {SubscriptionContract} subscription
   * @returns {Object[]}
   */
  getFormattedProducts (products, currency, subscription) {
    const {money} = this.#global.prices;
    const subscriptionLabels = this.#config.settings.customer_portal_labels.subscription;
    const afterCycle = subscription.discounts?.find(discount => discount.after_cycle > 0)?.after_cycle;

    const nextPriceLabel = Mustache.render(subscriptionLabels.price_after_payments, {
      number_of_payments: afterCycle,
    });

    return products.map(p => {
      return {
        title: getFormattedProductTitle(p),
        handle: p.product.handle,
        quantity: p.quantity,
        price: money.formatWithSpecificCurrency(p.price, currency),
        total: money.formatWithSpecificCurrency(p.price * p.quantity, currency),
        exists_in_shopify: !p.product.is_deleted && !p.variant?.is_deleted,
        variant_id: p.variant?.variant_id,
        line_id: p.line_id,
        price_initial: money.formatWithSpecificCurrency(p.initial_price, currency),
        price_next: money.formatWithSpecificCurrency(p.next_price, currency),
        is_after_cycle_discount: afterCycle && p.next_price,
        next_price_label: nextPriceLabel,
        one_time_purchase: p.one_time_purchase,
        actions: this.getProductActions(p, subscription),
      };
    });
  }

  /**
   * @param {SubscriptionContract} contract
   * @returns {Object[]}
   */
  getFormattedBoxes = (contract) => {
    const {money} = this.#global.prices;

    return contract.boxes.map(box => ({
      id: box.id,
      name: box.name,
      items: box.items.map(item => getFormattedProductTitle(item)),
      price: money.formatWithSpecificCurrency(box.price, contract.currency),
      total: money.formatWithSpecificCurrency(box.totalPrice, contract.currency),
      quantity: box.quantity,
      actions: this.getBoxActions(contract),
    }));
  }

  /**
   * @param {SubscriptionContractProduct} product
   * @param {SubscriptionContract} contract
   * @returns {Object[]}
   */
  getProductActions = (product, contract) => {
    const allowToRemove = this.#permissions.canRemoveProduct(contract, product.line_id);
    const allowToSwap = this.#permissions.canSwapProduct(contract, product.line_id);
    return this.getActions(contract, allowToSwap, allowToRemove);
  }

  /**
   * @param {SubscriptionContract} contract
   * @returns {Object[]}
   */
  getBoxActions = contract => {
    const allowToRemove = this.#permissions.canRemoveBox(contract);
    const allowToSwap = this.#permissions.isSwapAllowedForContract(contract);
    return this.getActions(contract, allowToSwap, allowToRemove);
  }

  /**
   * @param {SubscriptionContract} contract
   * @param {boolean} allowToSwap
   * @param {boolean} allowToRemove
   * @returns {Object[]}
   */
  getActions = (contract, allowToSwap, allowToRemove) => {
    const actions = [];

    if (allowToSwap || allowToRemove) {
      const swap = {};
      if (allowToSwap) {
        swap.swap = true;
      }

      if (this.#permissions.canSwapSomeItems(contract) || swap.swap) {
        actions.push(swap);
      }
    }

    if (allowToRemove) {
      actions.push({remove: true});
    }

    return actions;
  }

  /**
   *
   * @param subscription
   * @returns {number}
   */
  getShippingPerDelivery (subscription) {
    return subscription.delivery_price / subscription.frequency.deliveries_per_charge;
  }

  /**
   *
   * @param {SubscriptionContract} subscription
   * @param {boolean} full
   * @returns {Object}
   */
  getFormattedSubscription (subscription, full = true) {
    const {prices} = this.#global;
    const cpLabels = this.#config.settings.customer_portal_labels;
    const shipping = prices.money.formatWithSpecificCurrency(
      this.getShippingPerDelivery(subscription), subscription.currency,
    );
    const totalForNextOrder = prices.money.formatWithSpecificCurrency(
      this.getTotalSubscriptionPrice(subscription),
      subscription.currency,
    );

    const products = this.getFormattedProducts(
      subscription.subscription_contract_products,
      subscription.currency,
      subscription,
    );

    const boxes = this.getFormattedBoxes(subscription);

    const formattedSubscription = {
      id: subscription.id,
      beautiful_id: subscription.beautiful_id || '',
      is_prepaid: subscription.isPrepaid,
      is_invoice: subscription.invoice.is_enabled,
      type: subscription.isPrepaid ? cpLabels.subscription.prepaid : cpLabels.subscription.pay_per_delivery,
      paused: subscription.status === PAUSED,
      payment_card: subscription.payment_card.replace(/[^0-9 *•]/gu, '').trim(),
      card_expires: subscription.card_expires,
      cancelled: subscription.status === CANCELLED,
      created_at: fromUtcToTimezone(subscription.created_at, this.#config.timezone, 'YYYY-MM-DD'),
      status: this.#subscriptionStatuses[subscription.status],
      next_order_date: getPhpDate(subscription.next_order_date, true),
      nice_next_order_date: fromUtcToTimezone(subscription.next_order_date, this.#config.timezone, 'YYYY-MM-DD'),
      shipping: shipping,
      frequency: this.getFormattedFrequency(subscription.frequency),
      charge_frequency: this.getChargeFrequency(subscription),
      requires_shipping: this.isShippingRequired(subscription),
      total_for_next_charge: totalForNextOrder,
      status_details: this.#getStatusDetails(subscription),
      has_actions: products.some(p => p.actions.length),
      actions_colspan: Math.max.apply(this, products.map(p => p.actions.length)),
      products,
      boxes,
    };

    if (full) {
      if (!isEmpty(subscription.subscription_contract_orders)) {
        formattedSubscription.orders = this.getFormattedOrders(subscription.subscription_contract_orders);
        formattedSubscription.fulfillments = this.getFormattedFulfillments(subscription);
      }

      formattedSubscription.shipping_address = this.getFormattedAddress(subscription.shipping_address);
      formattedSubscription.billing_address = this.getFormattedAddress(subscription.billing_address);
    }

    return formattedSubscription;
  }

  /**
   * @param {SubscriptionContract} subscription
   * @returns {Object[]}
   */
  getFormattedFulfillments = subscription => subscription.fulfillments.map(this.getFormattedFulfillment);

  /**
   * @param {Object} fulfillment
   * @returns {Object}
   */
  getFormattedFulfillment = fulfillment => {
    return {
      ...fulfillment,
      fulfill_at: moment(fulfillment.fulfill_at).format('YYYY-MM-DD'),
      editable: this.#isFulfillmentEditable(fulfillment),
      status: this.#getFulfillmentStatus(fulfillment.status),
    };
  }

  /**
   *
   * @param {TimeInterval} frequency
   * @returns {string}
   */
  getFormattedFrequency (frequency) {
    const labels = this.#config.settings.customer_portal_labels.frequency;

    let name;
    switch (frequency.frequency_unit.toUpperCase()) {
      case DAY:
        name = labels.days;
        break;
      case WEEK:
        name = labels.weeks;
        break;
      case MONTH:
        name = labels.months;
        break;
      case YEAR:
        name = labels.years;
        break;
      default:
        name = '';
    }

    return `${frequency.frequency_number} ${name}`;
  }

  /**
   *
   * @param {Object} subscription
   * @returns {Object}
   */
  getSubscriptionLabels (subscription) {
    const {settings} = this.#config;
    const cpLabels = settings.customer_portal_labels;
    const subscriptionLabels = settings.customer_portal_labels.subscription;

    return {
      products_label: settings.customer_portal_labels.subscription.products,
      price_label: cpLabels.subscription.price,
      quantity_label: cpLabels.subscription.quantity,
      cancel_qty_label: cpLabels.subscription.cancel,
      save_qty_label: cpLabels.subscription.save,
      total_label: cpLabels.subscription.total,
      shipping_label: cpLabels.subscription.shipping,
      total_for_next_charge_label: subscription.isPrepaid ? cpLabels.subscription.total_to_prepay : cpLabels.subscription.total_for_next_order,
      history_label: VariablesManager.#getNestedSubscriptionLabel('label', cpLabels.history, subscription),
      date_label: cpLabels.history.date,
      status_label: cpLabels.history.status,
      details_label: cpLabels.history.details,
      initial_price_label: subscriptionLabels.initial_price,
      add_product_label: cpLabels.add_remove_product.add,
      one_time_purchase_label: cpLabels.subscription.one_time_purchase,
    };
  }

  /**
   * @param {SubscriptionContract[]} subscriptions
   * @returns {Object[]}
   */
  getFormattedSubscriptions (subscriptions) {
    return subscriptions.map(s => this.getFormattedSubscription(s, false));
  }

  /**
   * @param {Object} order
   * @returns {string}
   */
  getOrderDetails (order) {
    const attempts = order.subscription_billing_attempts;
    const lastAttempt = attempts[attempts.length - 1] ?? {};

    if (this.#orderStatuses[order.status] === 'Failed') {
      return order.current_error;
    }
    if (lastAttempt.next_action_url) {
      return this.#get3dSecureErrorMessge(lastAttempt.next_action_url);
    }

    return order.name;
  }

  /**
   * @param {Object} subscription
   * @returns {number}
   */
  getTotalSubscriptionPrice = subscription => {
    const boxesPrice = subscription.boxes.reduce((result, box) => {
      return result + box.items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    }, 0) * subscription.frequency.deliveries_per_charge;

    const itemsPrice = subscription.subscription_contract_products.reduce((sum, product) => {
      return sum + (product.price * product.quantity);
    }, 0) * subscription.frequency.deliveries_per_charge;

    const deliveryPrice = subscription.invoice.is_enabled ? 0 : +subscription.delivery_price;

    return (boxesPrice + itemsPrice + deliveryPrice);
  }

  /**
   *
   * @param {SubscriptionContract} subscription
   * @returns {Object}
   */
  getViewVariables (subscription) {
    const settings = this.#config.settings.subscription;

    const today = moment.tz(this.#config.timezone);
    const tomorrow = moment.tz(this.#config.timezone).add(1, 'day');
    const formattedSubscription = this.getFormattedSubscription(subscription);

    const variables = {
      ...formattedSubscription,
      ...settings,
      ...this.getIntervalLabels(),
      today: today.format('YYYY-MM-DD'),
      tomorrow: tomorrow.format('YYYY-MM-DD'),
      vertical: AccountPage.useVerticalLayout,
      subscription_labels: this.getSubscriptionLabels(subscription),
      action_labels: this.getActionLabels(subscription),
      fulfillment_labels: this.getFulfillmentLabels(),
      show_fulfillments: !!formattedSubscription.fulfillments.length,
      next_order_date_btn_action: subscription.isChargeDaySet ? Actions.LIST.UPDATE_NEXT_ORDER_DATE : ACTIONS.UPDATE_NEXT_ORDER_DATE,
      show_add_button: this.#permissions.canAddProduct(subscription) || this.#permissions.canAddOneTimePurchase(subscription),
      is_product_qty_editable: this.#permissions.canEditProductQuantity(subscription),
    };

    return {...variables, ...this.#templatesManager.renderSubscriptionViewSections(variables, AccountPage.isMobile)};
  }

  /**
   * @returns {Object}
   */
  getFulfillmentLabels () {
    const labels = this.#config.settings.customer_portal_labels.fulfillment;

    return {
      title: labels.schedule,
      date_label: labels.date,
      status_label: labels.status,
      details_label: labels.details,
      change_label: labels.change_date,
      cancel_label: labels.cancel,
      save_label: labels.save,
    };
  }

  /**
   *
   * @returns {Object}
   */
  get #orderStatuses () {
    const labels = this.#config.settings.customer_portal_labels.history;

    return {
      [PENDING]: labels.pending,
      [PAID]: labels.paid,
      [ORDER_FAILED]: labels.failed,
    };
  }

  /**
   *
   * @returns {Object}
   */
  get #subscriptionStatuses () {
    const labels = this.#config.settings.customer_portal_labels.subscriptions;

    return {
      [ACTIVE]: labels.active,
      [PAUSED]: labels.paused,
      [CANCELLED]: labels.cancelled,
      [FAILED]: labels.failed,
      [EXPIRED]: 'Expired',
    };
  }

  /**
   * @param {string} status
   * @returns {string}
   */
  #getFulfillmentStatus (status) {
    const labels = this.#config.settings.customer_portal_labels.fulfillment;

    if (status === SCHEDULED) {
      return labels.scheduled;
    }

    return status === FULFILLED ? labels.fulfilled : labels.unfulfilled;
  }

  /**
   * @param {Object} fulfillment
   * @returns {boolean}
   */
  #isFulfillmentEditable = fulfillment => {
    return this.#config.settings.subscription.is_delivery_date_changeable && fulfillment.editable;
  }

  /**
   *
   * @param {string} name
   * @param {Object} labels
   * @param {Object} subscription
   * @returns {string}
   */
  static #getNestedSubscriptionLabel (name, labels, subscription) {
    return labels[subscription.invoice.is_enabled ? 'invoice' : 'auto'][name];
  }

  /**
   * @param {Object} subscription
   * @returns {string|null}
   */
  #getStatusDetails(subscription) {
    const attempts = subscription.subscription_billing_attempts;
    const nextActionUrl = attempts[attempts.length - 1]?.next_action_url;

    return nextActionUrl ? this.#get3dSecureErrorMessge(nextActionUrl) : null;
  }

  /**
   *
   * @param {string} nextActionUrl
   * @returns {string}
   */
  #get3dSecureErrorMessge(nextActionUrl) {
    const labels = this.#config.settings.customer_portal_labels.history;

    return this.#templatesManager.renderStatus3dSecure(
      labels.secure3d_text,
      nextActionUrl,
      labels.secure3d_button,
    );
  }

  /**
   * @param {Object} subscription
   * @returns {boolean}
   */
  isShippingRequired(subscription) {
    if (subscription.invoice.is_enabled) {
      return false;
    }

    return subscription.subscription_contract_products.some(p => p.requires_shipping);
  }
}
