//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import CartUpsellTextsWidget from './CartUpsellTextsWidget';
import CartUpsellTextsModal from './CartUpsellTextsModal';

export default class CartUpsellTexts extends AbstractParent {

  /**
   * @type  {CartUpsellTextsWidget}
   */
  widget;

  /**
   * @type  {CartUpsellTextsModal}
   */
  modal;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      widget: CartUpsellTextsWidget,
      modal: CartUpsellTextsModal,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}