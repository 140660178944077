import AbstractParent from './AbstractParent';

export default class CartUpsellTextsModal extends AbstractParent {

  /**
   * @type {string}
   */
  title = 'Cart changes';

  /**
   * @type {string}
   */
  content = 'Some products in the cart have not been updated to subscriptions.';

  /**
   * @type {string}
   */
  update = 'Update and proceed to checkout';

  /**
   * @type {string}
   */
  proceed = 'Proceed without updating';

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}