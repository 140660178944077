//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';

export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';

export default class TimeInterval extends AbstractParent {


  /**
   * @type  {number}
   */
  frequency_number = 1;

  /**
   * @type  {DAY|WEEK|MONTH|YEAR|'DAY'|'WEEK'|'MONTH'|'YEAR'}
   */
  frequency_unit = MONTH;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}