import { AppLibrary } from "../../helpers/AppLibrary";
import resolve from "../../resolve";
import AppConfig from "../../config/config";
import VariablesManager from "./VariablesManager";
import TemplatesManager from "./TemplatesManeger";
import Storage from "../../config/Storage";
import Api from '../api/Api';

const BREAKPOINT_DESKTOP = 750;
const BREAKPOINT_VERTICAL = 1014;

const RESIZE_INTERVAL = 250;

export default class AccountPage {

  static PAGE_WRAPPER_SELECTOR = '.spurit-ros__subscriptions';

  static CONTENT_WRAPPER_SELECTOR = '.spurit-ros__wrapper';

  /**
   * @type {AppConfig}
   */
  config;

  /**
   * @type {Api}
   */
  api;

  /**
   * @type {VariablesManager}
   */
  variables;

  /**
   * @type {TemplatesManager}
   */
  templates;

  /**
   * @type {Storage}
   */
  storage;

  /**
   *
   */
  #resizeTimeout;

  /**
   *
   */
  constructor () {
    this.config = resolve(AppConfig);
    this.api = resolve(Api);
    this.variables = resolve(VariablesManager);
    this.templates = resolve(TemplatesManager);
    this.storage = resolve(Storage);
  }

  /**
   *
   */
  init () {
    if (this.storage.redirectIfNotLoggedIn()) {
      return;
    }

    this.initPage();

    const wrapper = document.querySelector(AccountPage.CONTENT_WRAPPER_SELECTOR);
    if (wrapper) {
      this.run(wrapper);
    }
  }

  /**
   *
   */
  initPage () {
    const page = document.querySelector(AccountPage.PAGE_WRAPPER_SELECTOR);
    if (page) {
      const settings = this.config.settings.customer_portal_labels.general;
      page.innerHTML = this.templates.renderPageWrapper({
        title: settings.my_subscriptions,
        back: settings.return_to_account,
        lang: AppLibrary.getUrlLang(),
      });
    }
  }

  /**
   *
   */
  addResizeListener () {
    window.addEventListener('resize', () => {
      clearTimeout(this.#resizeTimeout);
      setTimeout(e => this.onResize(e), RESIZE_INTERVAL);
    });
  }

  /**
   *
   */
  onResize () {
    //
  }

  /**
   *
   * @param {Element} container
   */
  run (container) {
    //
  }

  /**
   * @returns {boolean}
   */
  static get isMobile () {
    return document.documentElement.clientWidth < BREAKPOINT_DESKTOP;
  }

  static get useVerticalLayout () {
    return document.documentElement.clientWidth < BREAKPOINT_VERTICAL;
  }
}