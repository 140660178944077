import Mustache from 'mustache';
import resolve from '../../resolve';
import AppConfig from '../../config/config';
import Storage from '../../config/Storage';

export const STATUS_SUCCESS = 200;

export default class ApiBase {

  /**
   * @type {AppConfig}
   */
  config;

  /**
   * @type {Storage}
   */
  storage;

  /**
   *
   */
  constructor () {
    this.config = resolve(AppConfig);
    this.storage = resolve(Storage);
  }

  /**
   *
   * @returns {string}
   */
  get apiRoot () {
    return `${this.config.env.appUrl}/api`;
  }

  /**
   * @returns {{shop_id: number, customer_email: string, customer_id: number}}
   */
  get authParams () {
    const params = {
      shop_id: +this.config.snippet.shopId,
      customer_id: Spurit.globalSnippet.customer_id,
      customer_email: this.config.snippet.customerEmail,
    };
    if ((!params.customer_email || !params.customer_id) && !this.storage.getLoginToken()) {
      throw new Error('Essential params are not set');
    }

    return params;
  }

  /**
   * @param {string} path
   * @param {Object} params
   * @param {boolean} json
   * @returns {Promise<Response>}
   */
  makeCommonGetRequest (path, params = {}, json = true) {
    return fetch(
      this.makeUrl(path, {id: params.id}, {...this.authParams, ...params}),
      {headers: this.requestHeaders(json)},
    ).then(response => {
      if (response.ok) {
        return json ? response.json() : response.text();
      }

      return Promise.reject(response);
    });
  }

  /**
   *
   * @param {string} path
   * @param {number} id
   * @param {Object} additionalParams
   * @returns {Promise<Response>}
   */
  makeCommonPostRequest (path, id, additionalParams = {}) {
    return fetch(
      this.makeUrl(path, {id}),
      this.makePostData({...this.authParams, id, ...additionalParams}),
    );
  }

  /**
   *
   * @param {string} route
   * @param {Object} data
   * @param {Object} query
   * @returns {string}
   */
  makeUrl (route, data = {}, query = {}) {
    let url = Mustache.render(`${this.apiRoot}${route}`, data);

    if (Object.keys(query).length) {
      url = `${url}?${new URLSearchParams(query)}`;
    }

    return url;
  }

  /**
   * @param {Object} data
   * @returns {Object}
   */
  makePostData = data => this.makeRequestData(data, 'POST');

  /**
   * @param {Object} data
   * @returns {Object}
   */
  makePutData = data => this.makeRequestData(data, 'PUT');

  /**
   * @param {Object} data
   * @returns {Object}
   */
  makeDeleteData = data => this.makeRequestData(data, 'DELETE');

  /**
   * @param {Object} data
   * @param {string} type
   * @returns {Object}
   */
  makeRequestData = (data, type) => {
    return {
      body: JSON.stringify(data),
      headers: this.requestHeaders(),
      method: type,
    };
  }

  /**
   * @param {boolean} json
   * @returns {{"ROS-Token": *, "Content-Type": string}}
   */
  requestHeaders(json = true) {
    const headers = {
      'Content-Type': 'application/json',
      'ROS-Token': this.config.snippet.shopHash,
    };

    if (json) {
      headers.Accept = 'application/json';
    }

    const token = this.storage.getLoginToken();
    if (token) {
      headers['Login-Token'] = token;
    }

    return headers;
  }
}