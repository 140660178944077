//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsBuildABox extends AbstractParent {

  /**
   * @type  {string}
   */
  size = ___('Box size');

  /**
   * @type  {string}
   */
  frequency = ___('Delivery frequency');

  /**
   * @type  {string}
   */
  one_time_purchase = ___('One-time purchase');

  /**
   * @type  {string}
   */
  add_to_box = ___('Add to box');

  /**
   * @type  {string}
   */
  added = ___('Added');

  /**
   * @type  {string}
   */
  summary = ___('Box Summary');

  /**
   * @type  {string}
   */
  total = ___('Box total');

  /**
   * @type  {string}
   */
  atc = ___('Add box to cart');

  /**
   * @type  {string}
   */
  update = ___('Update box');

  /**
   * @type  {string}
   */
  no_added = ___('No products added');

  /**
   * @type  {string}
   */
  no_available = ___('No products available');

  /**
   * @type  {string}
   */
  atc_tip = ___('The box should contain {{number_of_items}} items.');

  /**
   * @type  {string}
   */
  discount = ___('You will receive a {{discount}} discount on the total box price when you add {{number_of_items}} items.');

  /**
   * @type  {string}
   */
  discounted = ___('You have received a {{discount}} discount on the total box price.');

  /**
   * @type  {string}
   */
  atc_tip_more = ___('The box should contain {{number_of_items}} or more items.');

  /**
   * @type  {string}
   */
  atc_max = ___('You’ve added the maximum allowed number of items.');

  /**
   * @type  {string}
   */
  get_discount_items = ___('Get a {{discount}} discount when the box contains {{number_of_items}} or more items.');

  /**
   * @type  {string}
   */
  get_discount_values = ___('Get a {{discount}} discount when the total value of the box is {{amount}} or higher.');

  /**
   * @type  {string}
   */
  updated = ___('Subscription box has been successfully updated.');

  /**
   * @type  {string}
   */
  na = ___('Not available');

  /**
   * @type  {string}
   */
  part = ___('This product is part of the subscription box');

  /**
   * @type  {string}
   */
  remove = ___('If you delete this product, other products in the subscription box will also be removed.');

  /**
   * @type  {string}
   */
  change = ___('If you want to change the box content, delete the current box products and create a new subscription box.');

  /**
   * @type  {string}
   */
  delete = ___('Delete box');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}