//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import TimeInterval from './TimeInterval';
import Discount from './Discount';
import {compareDiscountValues} from '../helpers/utils';

export const PAY_PER_DELIVERY = 'PAY_PER_DELIVERY';
export const PREPAID = 'PREPAID';

export default class RuleSubscriptionFrequency extends AbstractParent {

  /**
   * @type  {number}
   */
  position;

  /**
   * @type  {string}
   */
  name = '';

  /**
   * @type  {TimeInterval}
   */
  frequency;

  /**
   * @type {number}
   */
  deliveries_per_charge = 1;

  /**
   * @type  {number}
   */
  selling_plan_id;

  /**
   * @type  {boolean}
   */
  inner = false;

  /**
   * @type  {boolean}
   */
  visible = true;

  /**
   * @type  {boolean}
   */
  should_apply_discount = false;

  /**
   * @type  {Discount[]}
   */
  discounts;

  /**
   * @type  {string|null}
   */
  notes_for_customer = null;

  /**
   * @type  {string}
   */
  type = PAY_PER_DELIVERY;

  /**
   * @type {number|null}
   */
  charge_day = null;

  /**
   * @type {number}
   */
  cutoff_days = 0;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      frequency: TimeInterval,
      discounts: Discount,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @returns {Discount|null}
   */
  get initialDiscount () {
    return this.should_apply_discount ? this.discounts.find(d => !(+d.after_cycle)) : null;
  }

  /**
   * @returns {Discount}
   */
  get afterCycleDiscount () {
    if (!this.should_apply_discount) {
      return null;
    }

    const afterCycleDiscount = this.discounts.find(d => !!(+d.after_cycle));
    return compareDiscountValues(this.initialDiscount, afterCycleDiscount) ? null : afterCycleDiscount;
  }

  /**
   * @param {Discount} discount
   * @returns {Discount|null}
   */
  getDiscountAfter = discount => !!(+discount?.after_cycle) ? null : this.afterCycleDiscount;

  /**
   * @returns {null|Discount}
   */
  getDiscountAfterOrders = ordersCount => {
    const afterCycleDiscount = this.afterCycleDiscount;
    if (afterCycleDiscount && ordersCount >= afterCycleDiscount.after_cycle) {
      return afterCycleDiscount;
    }

    return this.initialDiscount;
  }
}