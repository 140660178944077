//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SubscriptionContractBillingAddress extends AbstractParent {


  /**
   * @type  {string}
   */
  address1 = null;

  /**
   * @type  {string}
   */
  address2 = null;

  /**
   * @type  {string}
   */
  city = null;

  /**
   * @type  {string}
   */
  company = null;

  /**
   * @type  {string}
   */
  country = null;

  /**
   * @type  {string}
   */
  first_name = null;

  /**
   * @type  {string}
   */
  last_name = null;

  /**
   * @type  {string}
   */
  phone = null;

  /**
   * @type  {string}
   */
  province = null;

  /**
   * @type  {string}
   */
  zip = null;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}