import ApiBase from './ApiBase';

const ROUTES = {
  SETTINGS: {
    GET: '/settings',
  },
};

export default class ShopApi extends ApiBase {

  /**
   * @returns {Promise<Response>}
   */
  getFrontSettings () {
    return fetch(
      this.makeUrl(ROUTES.SETTINGS.GET),
      this.makePostData({shop_id: +this.config.snippet.shopId}),
    ).then(response => response.json());
  }
}