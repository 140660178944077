import "core-js/stable";
import "regenerator-runtime/runtime";
import 'element-matches-polyfill';
import 'element-closest-polyfill';
import './lang/stub';

import {Settings} from './config/settings';
import AppConfig from './config/config';

import {AppLibrary} from './helpers/AppLibrary';
import {Loader} from './components/Loader';
import runApp from "./components/launcher";
import resolve from "./resolve";
import CustomerPortal, { PAGE_NAME_SUBSCRIPTIONS } from "./components/CustomerPortal";

const config = resolve(AppConfig);
const APP_NAME = config.env.appName;

const library = resolve(AppLibrary);
const settings = resolve(Settings);

const theGreatLoader = resolve(Loader);

theGreatLoader.enableSelectorPicker(
    (pageType) => {

      if (pageType === 'cart') {
        const _GET = library.requestGetVars();
        if (config.snippet.globalSnippet.cart.items.length !== 1 && typeof _GET.vid !== 'undefined') {
          // Add exactly 1 line-item to the cart
          library.ajax('POST', '/cart/clear.js', null, () => {
            // eslint-disable-next-line max-nested-callbacks
            library.ajax('POST', '/cart/add.js', 'quantity=1&id=' + _GET.vid, () => {
              document.location.reload();
            });
          });
          return false;
        }
      }

      return true;
    },
);

// theGreatLoader.addResources(config.env.cdnStorePath + config.snippet.shopHash + '.css');
theGreatLoader.addResources(config.env.cdnJsLibPath + 'spurit.global-2.x.min.js', [], () => {
  return !document.querySelector('script[src*="spurit.global"]');
});
// theGreatLoader.addResources(config.env.cdnStorePath + config.snippet.shopHash + '.js?' + Date.now());

if (library.getCurrentPageName() === PAGE_NAME_SUBSCRIPTIONS && CustomerPortal.subscriptionId) {
  theGreatLoader.addResources(config.env.cdnCommonPath + 'countries.js');
}

theGreatLoader.run(() => {
  // Run App
  const run = () => {
    if (!Settings.isSettingsSet(APP_NAME)) {
      return;
    }

    settings.init();
    config.init(settings);

    Spurit.global.onReady(
      ['atc', 'selectors', 'prices', 'checkout'],
      () => runApp(),
      {
        checkout: {
          hash: config.snippet.shopHash,
          app_id: config.env.appId,
          app_name: APP_NAME,
        },
      },
    );
  };

  if (typeof Spurit.global !== 'undefined' && typeof Spurit.global.onReady !== 'undefined') {
    run();
  } else {
    Spurit.globalOnLoad = () => run();
  }
});