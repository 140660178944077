//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsResume extends AbstractParent {


  /**
   * @type  {string}
   */
  button = ___('RESUME');

  /**
   * @type  {string}
   */
  contact = ___('CONTACT US TO RESUME YOUR SUBSCRIPTION');

  /**
   * @type  {string}
   */
  modal = ___('Are you sure you want to resume your subscription?');

  /**
   * @type  {string}
   */
  cancel = ___('Cancel').toUpperCase();

  /**
   * @type  {string}
   */
  ok = ___('OK');

  /**
   * @type  {string}
   */
  success = ___('Your subscription has been resumed');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}