import Actions from "../subscriptions/Actions";
import ApiBase from './ApiBase';

const ROUTES = {
  SUBSCRIPTIONS: {
    LIST: '/customer-portal/subscriptions',
    SEARCH: '/customer-portal/subscriptions/{{id}}',
    PAUSE: '/customer-portal/subscriptions/{{id}}/pause',
    RESUME: '/customer-portal/subscriptions/{{id}}/resume',
    CANCEL: '/customer-portal/subscriptions/{{id}}/cancel',
    SKIP: '/customer-portal/subscriptions/{{id}}/skip-next-order',
    NEW_PAYMENT_METHOD: '/customer-portal/subscriptions/{{id}}/payment-method',
    UPDATE_SCHEDULE: '/customer-portal/subscriptions/{{id}}/payment-schedule',
    UPDATE_SHIPPING_ADDRESS: '/customer-portal/subscriptions/{{id}}/shipping-address',
    UPDATE_FULFILLMENT_DATE: '/customer-portal/subscriptions/{{id}}/fulfillment',
    UPDATE_PRODUCT_QUANTITY: '/customer-portal/subscriptions/{{id}}/product-quantity',
    ADD_ITEMS: '/customer-portal/subscriptions/{{id}}/items',
    REMOVE_ITEM: '/customer-portal/subscriptions/{{sid}}/items/{{id}}',
    SWAP_ITEM: '/customer-portal/subscriptions/{{id}}/items/swap',
  },

  ORDERS: {
    HAS_FAILED_ORDER: '/customer-portal/{{id}}/has-failed-order',
    ORDER_NOW: '/customer-portal/{{id}}/order-now',
    ORDER_NOW_FAILED: '/customer-portal/{{id}}/order-now-failed',
    ORDER_NOW_BOTH: '/customer-portal/{{id}}/order-now-both',
  },
};

export default class ContractApi extends ApiBase {

  /**
   * @param {number} id
   * @param {string|null} reason
   * @returns {Promise<Response>}
   */
  cancelSubscription (id, reason = null) {
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.CANCEL, id, {cancellation_reason: reason});
  }

  /**
   *
   * @returns {Promise<Response>}
   */
  getSubscriptions () {
    return this.makeCommonGetRequest(ROUTES.SUBSCRIPTIONS.LIST);
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<Response>}
   */
  getSubscription (id) {
    return this.makeCommonGetRequest(ROUTES.SUBSCRIPTIONS.SEARCH, {id});
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<Response>}
   */
  hasFailedOrder (id) {
    return this.makeCommonGetRequest(ROUTES.ORDERS.HAS_FAILED_ORDER, {id}, false);
  }

  /**
   *
   * @param {number} id
   * @param {string} mode
   * @returns {Promise<Response>}
   */
  orderNow (id, mode) {
    let path;
    switch (mode) {
      case Actions.LIST.ORDER_NOW:
        path = ROUTES.ORDERS.ORDER_NOW;
        break;
      case Actions.LIST.ORDER_NOW_FAILED:
        path = ROUTES.ORDERS.ORDER_NOW_FAILED;
        break;
      default:
        path = ROUTES.ORDERS.ORDER_NOW_BOTH;
        break;
    }

    return this.makeCommonPostRequest(path, id);
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<Response>}
   */
  pauseSubscription (id) {
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.PAUSE, id);
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<Response>}
   */
  requestNewPaymentMethod (id) {
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.NEW_PAYMENT_METHOD, id);
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<Response>}
   */
  resumeSubscription (id) {
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.RESUME, id);
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<Response>}
   */
  skipNextOrder (id) {
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.SKIP, id);
  }

  /**
   *
   * @param {number} id
   * @param {string} nextOrderDate
   * @param {Object} frequency
   * @returns {Promise<Response>}
   */
  updateSchedule (id, nextOrderDate, frequency) {
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.UPDATE_SCHEDULE, id, {
      next_order: nextOrderDate,
      frequency: frequency,
    });
  }

  /**
   *
   * @param {number} id
   * @param {Object} data
   * @returns {Promise<Response>}
   */
  updateShippingAddress (id, data) {
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.UPDATE_SHIPPING_ADDRESS, id, data);
  }

  /**
   * @param {number} subscriptionId
   * @param {number} fulfillmentId
   * @param {string} date
   * @returns {Promise<Response>}
   */
  updateFulfillmentDate (subscriptionId, fulfillmentId, date) {
    return fetch(
      this.makeUrl(ROUTES.SUBSCRIPTIONS.UPDATE_FULFILLMENT_DATE, {id: subscriptionId}),
      this.makePostData({...this.authParams, id: subscriptionId, fulfillment_id: fulfillmentId, date}),
    );
  }

  /**
   * @param {number} subscriptionId
   * @param {number} lineId
   * @param {number} quantity
   * @returns {Promise<Response>}
   */
  updateProductQuantity (subscriptionId, lineId, quantity) {
    return fetch(
      this.makeUrl(ROUTES.SUBSCRIPTIONS.UPDATE_PRODUCT_QUANTITY, {id: subscriptionId}),
      this.makePostData({...this.authParams, id: subscriptionId, line_id: lineId, quantity}),
    );
  }

  /**
   * @param {number} subscriptionId
   * @param {number[]} vids
   * @param {boolean} oneTimePurchase
   * @returns {Promise<Response>}
   */
  addSubscriptionItems (subscriptionId, vids, oneTimePurchase) {
    const data = {vids, one_time_purchase: oneTimePurchase};
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.ADD_ITEMS, subscriptionId, data)
      .then(response => response.json());
  }

  /**
   * @param {number} subscriptionId
   * @param {number} idToDelete
   * @param {number} idToAdd
   * @returns {Promise<any>}
   */
  swapSubscriptionItems (subscriptionId, idToDelete, idToAdd) {
    const data = {delete: idToDelete, add: idToAdd};
    return this.makeCommonPostRequest(ROUTES.SUBSCRIPTIONS.SWAP_ITEM, subscriptionId, data)
      .then(response => response.json());
  }

  /**
   * @param {number} subscriptionId
   * @param {string} lineId
   * @returns {Promise<Response>}
   */
  removeSubscriptionItem (subscriptionId, lineId) {
    return fetch(
      this.makeUrl(ROUTES.SUBSCRIPTIONS.REMOVE_ITEM, {sid: subscriptionId, id: lineId}),
      this.makeDeleteData(this.authParams),
    );
  }
}
