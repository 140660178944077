//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsOrderStatusWidget extends AbstractParent {


  /**
   * @type  {string}
   */
  subscriptions = ___('Subscriptions');

  /**
   * @type  {string}
   */
  continue_and_manage = ___('Continue to your account to view and manage your subscriptions.');

  /**
   * @type  {string}
   */
  manage_subscriptions = ___('Manage your subscriptions');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}