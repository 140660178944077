import resolve from '../../resolve';
import BoxApi from './BoxApi';
import ContractApi from './ContractApi';
import WidgetApi from './WidgetApi';
import ShopApi from './ShopApi';
import Shopify from './Shopify';

export default class Api {

  /**
   * @type {BoxApi}
   */
  box;

  /**
   * @type {ContractApi}
   */
  contract;

  /**
   * @type {WidgetApi}
   */
  widget;

  /**
   * @type {ShopApi}
   */
  shop;

  /**
   * @type {Shopify}
   */
  shopify;

  /**
   *
   */
  constructor () {
    this.box = resolve(BoxApi);
    this.contract = resolve(ContractApi);
    this.widget = resolve(WidgetApi);
    this.shop = resolve(ShopApi);
    this.shopify = resolve(Shopify);
  }
}