//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsNotificationsPaymentFailed extends AbstractParent {


  /**
   * @type  {boolean}
   */
  is_enabled = false;

  /**
   * @type  {boolean}
   */
  from_name = '{{shop.name}}';

  /**
   * @type  {string}
   */
  email_subject = ___('[{{shop.name}}] Your payment failed and will be retried');

  /**
   * @type  {string}
   */
  email_body = ___('<h2>Your payment couldn\'t be processed</h2><p>{{payment.error}}</p><p>It will be automatically retried. Please make sure that your payment card and subscription details are up to date.</p>');

  /**
   * @type  {string}
   */
  portal_link_text = ___('Manage your subscription');

  /**
   * @type  {string}
   */
  store_link_text = ___('or Visit our store');

  /**
   * @type  {string}
   */
  additional_instruction = ___('If you have any questions, reply to this email or contact us at {{shop.email}}');

  /**
   * @type  {string}
   */
  phrase_to_unsubscribe = ___('Unsubscribe from these notifications');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}