import {
  CUSTOM_FREQUENCY_NUMBER_SELECTOR,
  CUSTOM_FREQUENCY_UNIT_SELECTOR,
  SELECTED_SELLING_PLAN_SELECTOR, SUBSCRIBE_OPTION_SELECTOR,
} from "../Selectors";

const WIDGETS_KEY = 'widgets';
const SHOWN_RULES_KEY = 'shownRules';

const WIDGET_IDS_CAPACITY = 100;
const WIDGET_IDS_RANGE_MULTIPLIER = 100;

export default class Storage {

  /**
   *
   * @type {Set<number>}
   */
  #ids = new Set();

  /**
   *
   * @type {Object}
   */
  #data = {};

  /**
   *
   * @returns {number}
   */
  generateWidgetId () {
    if (this.#ids.size >= WIDGET_IDS_CAPACITY) {
      throw new Error('Too many widgets on this page');
    }

    const id = Math.round(Math.random() * WIDGET_IDS_CAPACITY * WIDGET_IDS_RANGE_MULTIPLIER);
    if (this.#ids.has(id)) {
      return this.getId();
    }

    this.#ids.add(id);

    return id;
  }

  /**
   *
   * @param {string} key
   * @return {*}
   */
  get (key) {
    return this.#data[key] || null;
  }

  /**
   *
   * @param {number} ruleId
   * @return {Object|null}
   */
  getWidgetState (ruleId) {
    const states = this.get(WIDGETS_KEY);
    return states ? states.find(s => s.ruleId === ruleId) : null;
  }

  /**
   *
   * @param {number} id
   * @return {boolean}
   */
  isRuleShown (id) {
    const rules = this.get(SHOWN_RULES_KEY);
    return rules && rules.has(id);
  }

  /**
   *
   * @param {number} id
   */
  rememberRule (id) {
    let rules = this.get(SHOWN_RULES_KEY);
    if (!rules) {
      rules = new Set();
    }

    rules.add(id);

    this.set(SHOWN_RULES_KEY, rules);
  }

  /**
   *
   * @param {HTMLElement} widget
   */
  rememberWidgetState (widget) {
    const state = Storage.#makeStateFromWidget(widget);

    let states = this.get(WIDGETS_KEY);
    if (states) {
      const index = states.findIndex(s => s.ruleId === state.ruleId);
      // eslint-disable-next-line no-magic-numbers
      if (index >= 0) {
        states[index] = state;
      } else {
        states.push(state);
      }
    } else {
      states = [state];
    }

    this.set(WIDGETS_KEY, states);
  }

  /**
   *
   * @param {string} key
   * @param {*} value
   */
  set (key, value) {
    this.#data[key] = value;
  }

  /**
   *
   * @param {HTMLElement} widget
   * @return {Object}
   */
  static #makeStateFromWidget (widget) {
    const state = {
      ruleId: +widget.dataset.ruleId,
      subscriptionSelected: +widget.querySelector(SUBSCRIBE_OPTION_SELECTOR)?.checked,
      planId: widget.querySelector(SELECTED_SELLING_PLAN_SELECTOR)?.value,
    };

    if (state.planId === 'custom') {
      state.customFrequency = {
        number: widget.querySelector(CUSTOM_FREQUENCY_NUMBER_SELECTOR).value,
        unit: widget.querySelector(CUSTOM_FREQUENCY_UNIT_SELECTOR).value,
      };
    }

    return state;
  }
}