import AbstractParent from './AbstractParent';
import Fulfillment, { CANCELLED } from "./Fulfillment";

export const SHOPIFY_CANCELLED = 'CANCELLED';
export const SHOPIFY_CLOSED = 'CLOSED';
export const SHOPIFY_SCHEDULED = 'SCHEDULED';

export const FULFILLED = 'Fulfilled';
export const UNFULFILLED = 'Unfulfilled';
export const SCHEDULED = 'Scheduled';

export const STATUSES_PRIORITY = {
  [FULFILLED]: 3,
  [UNFULFILLED]: 2,
  [SCHEDULED]: 1,
};

export default class FulfillmentOrder extends AbstractParent {
  /**
   * @type  {number}
   */
  id;

  /**
   * @type {string}
   */
  status;

  /**
   * @type {string}
   */
  fulfillAt;

  /**
   * @type {Fulfillment[]}
   */
  fulfillments = [];

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      fulfillments: Fulfillment,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @returns {boolean}
   */
  get isClosed () {
    return this.status === SHOPIFY_CLOSED;
  }

  /**
   * @returns {boolean}
   */
  get isRefunded () {
    return this.isClosed && !this.fulfillments?.length;
  }

  /**
   * @returns {boolean}
   */
  get isFulfilled () {
    return this.isClosed && this.fulfillments.length && this.fulfillments[0].isFulfilled();
  }

  /**
   * @returns {boolean}
   */
  get isScheduled () {
    return this.status === SHOPIFY_SCHEDULED;
  };

  /**
   * @returns {Object}
   */
  get formatted () {
    return {
      id: this.id,
      fulfill_at: this.getFulfillAt(),
      status: this.getStatusForUser(),
      editable: this.isScheduled,
    };
  }

  /**
   * @returns {boolean}
   */
  getFulfillAt () {
    return this.isFulfilled ? this.fulfillments[0].createdAt : this.fulfillAt;
  };

  /**
   * @returns {string}
   */
  getStatusForUser () {
    if (this.status === SHOPIFY_SCHEDULED) {
      return SCHEDULED;
    }

    return this.status === SHOPIFY_CLOSED ? FULFILLED : UNFULFILLED;
  }

  /**
   * @param {string} date
   */
  setFulfillmentDate (date) {
    const time = this.fulfillAt.split('T')[1];
    this.fulfillAt = `${date}T${time}`;
  }
}