//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import Invoice from './Invoice';
import Discount from './Discount';
import SubscriptionContractBillingAddress from './SubscriptionContractBillingAddress';
import SubscriptionContractShippingAddress from './SubscriptionContractShippingAddress';
import SubscriptionContractOrder from "./SubscriptionContractOrder";
import { STATUSES_PRIORITY } from "./FulfillmentOrder";
import SubscriptionContractFrequency from "./SubscriptionContractFrequency";
import SubscriptionContractProduct from "./SubscriptionContractProduct";
import {compareDiscountValues} from '../helpers/utils';
import SubscriptionContractBox from './SubscriptionContractBox';

export const ACTIVE = 'ACTIVE';
export const CANCELLED = 'CANCELLED';
export const EXPIRED = 'EXPIRED';
export const FAILED = 'FAILED';
export const PAUSED = 'PAUSED';

export default class SubscriptionContract extends AbstractParent {

  /**
   * @type  {number}
   */
  id;

  /**
   * @type  {number}
   */
  api_contract_id;

  /**
   * @type  {number}
   */
  beautiful_id;

  /**
   * @type  {number}
   */
  shop_id;

  /**
   * @type  {Date}
   */
  created_at;

  /**
   * @type  {Date}
   */
  created_in_shop_tz;

  /**
   * @type  {Date}
   */
  cancelled_in_shop_tz = null;

  /**
   * @type  {number}
   */
  customer_id;

  /**
   * @type  {Invoice}
   */
  invoice = null;

  /**
   * @type  {Date}
   */
  last_order_date = null;

  /**
   * @type  {Date}
   */
  next_order_date = null;

  /**
   * @type {Date|null}
   */
  postnext_order_date = null;

  /**
   * @type  {ACTIVE|CANCELLED|EXPIRED|FAILED|PAUSED|'ACTIVE'|'CANCELLED'|'EXPIRED'|'FAILED'|'PAUSED'}
   */
  status;

  /**
   * @type  {ACTIVE|CANCELLED|EXPIRED|FAILED|PAUSED|'ACTIVE'|'CANCELLED'|'EXPIRED'|'FAILED'|'PAUSED'}
   */
  status_before_cancellation = null;

  /**
   * @type  {number}
   */
  total_processed = 0;

  /**
   * @type  {number}
   */
  total_orders;

  /**
   * @type  {number}
   */
  delivery_price;

  /**
   * @type  {number}
   */
  deleted_delivery_price;

  /**
   * @type  {SubscriptionContractFrequency}
   */
  frequency;

  /**
   * @type  {string}
   */
  payment_card;

  /**
   * @type  {string}
   */
  card_expires;

  /**
   * @type  {Discount[]}
   */
  discounts = [];

  /**
   * @type  {number}
   */
  tries = 1;

  /**
   * @type  {Date}
   */
  paused_at = null;

  /**
   * @type  {string|null}
   */
  cancellation_reason = null;

  /**
   * @type  {boolean}
   */
  is_processing = false;

  /**
   * @type  {string}
   */
  currency;

  /**
   * @type  {SubscriptionContractBillingAddress}
   */
  billing_address = null;

  /**
   * @type  {SubscriptionContractShippingAddress}
   */
  shipping_address = null;

  /**
   * @type {SubscriptionContractOrder[]}
   */
  subscription_contract_orders;

  /**
   * @type {SubscriptionContractProduct[]}
   */
  subscription_contract_products;

  /**
   * @type {SubscriptionContractBox[]}
   */
  boxes = [];

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping () {
    return {
      invoice: Invoice,
      frequency: SubscriptionContractFrequency,
      discount: Discount,
      billing_address: SubscriptionContractBillingAddress,
      shipping_address: SubscriptionContractShippingAddress,
      subscription_contract_orders: SubscriptionContractOrder,
      subscription_contract_products: SubscriptionContractProduct,
      boxes: SubscriptionContractBox,
    };
  };

  /**
   * @param  {Object} properties
   */
  constructor (properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @returns {Object[]}
   */
  get fulfillments () {
    return this.subscription_contract_orders
      .reduce((result, order) => ([...result, ...order.formattedFulfillments]), [])
      .sort(this.compareFulfillments);
  }

  /**
   * @returns {boolean}
   */
  get isChargeDaySet () {
    return this.frequency.isChargeDaySet;
  }

  /**
   * @returns {boolean}
   */
  get isPrepaid () {
    return this.frequency.isPrepaid;
  }

  /**
   * @returns {Date}
   */
  get actualNextOrderDate () {
    return this.frequency.getNextOrderDateAfter(this.last_order_date);
  }

  /**
   * @returns {Discount|null}
   */
  get pendingDiscount () {
    return !!(+this.actualDiscount?.after_cycle) ? null : this.afterCycleDiscount;
  }

  /**
   * @returns {null|Discount}
   */
  get actualDiscount () {
    const afterCycleDiscount = this.afterCycleDiscount;
    if (afterCycleDiscount && this.total_orders >= afterCycleDiscount.after_cycle) {
      return afterCycleDiscount;
    }

    return this.initialDiscount;
  }

  /**
   * @returns {Discount|null}
   */
  get initialDiscount () {
    return this.discounts.find(d => !(+d.after_cycle));
  }

  /**
   * @returns {Discount|null}
   */
  get afterCycleDiscount () {
    const afterCycleDiscount = this.discounts.find(d => !!(+d.after_cycle));
    return compareDiscountValues(this.initialDiscount, afterCycleDiscount) ? null : afterCycleDiscount;
  }

  /**
   * @returns {SubscriptionContractProduct[]}
   */
  get subscriptionBasisItems () {
    return this.subscription_contract_products.filter(p => !p.one_time_purchase);
  }

  /**
   * @param {Object} f1
   * @param {Object} f2
   * @return {number}
   */
  compareFulfillments = (f1, f2) => {
    const date1 = f1.fulfill_at.split('T')[0];
    const date2 = f2.fulfill_at.split('T')[0];

    if (date1 === date2) {
      return STATUSES_PRIORITY[f2.status] - STATUSES_PRIORITY[f1.status];
    }

    return date1 < date2 ? -1 : 1;
  };

  /**
   * @param {number} id
   * @returns {null|FulfillmentOrder}
   */
  getFulfillment (id) {
    const orders = this.subscription_contract_orders;
    for (let i = 0; i < orders.length; i++) {
      const fulfillment = orders[i].fulfillment_orders.find(f => +f.id === +id);
      if (fulfillment) {
        return fulfillment;
      }
    }

    return null;
  }

  /**
   * @param {number} id
   * @returns {SubscriptionContractProduct}
   */
  getProductByLineId(id) {
    return this.subscription_contract_products.find(product => product.line_id === id);
  }
}