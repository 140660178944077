//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';

export const BEFORE = 'before';
export const AFTER = 'after';

export default class Selector extends AbstractParent {


  /**
   * @type  {BEFORE|AFTER|'before'|'after'}
   */
  position = BEFORE;

  /**
   * @type  {string}
   */
  selector = '';

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}