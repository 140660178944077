import AtcAbstract from './AtcAbstract';
import Api from '../api/Api';
import resolve from '../../resolve';
import {PRODUCTS} from '../../classes/Rule';

export default class UpsellAtc extends AtcAbstract {

  /**
   * @type {Api}
   */
  #api;

  /**
   *
   */
  constructor () {
    super();
    this.#api = resolve(Api);
  }

  /**
   * @param {Object[]} items
   * @returns {Promise}
   */
  handle = items => this.#removeFromCart(items.map(item => item.cartItem)).then(() => this.#addToCart(items));

  /**
   * @param {Object[]} items
   * @returns {Promise<Promise>}
   */
  #addToCart = items => {
    return this.#prepareSellingPlans(items).then(() => this.#api.shopify.addToCart(this.#prepareItems(items)));
  };

  /**
   * @param {Object[]} items
   * @returns {Promise<Response>}
   */
  #removeFromCart = items => this.#api.shopify.removeCartItems(items);

  /**
   * @param {Object} items
   * @returns {Promise|void}
   */
  #prepareSellingPlans = items => {
    return Promise.all(
      items
        .filter(item => item.rule.apply_to !== PRODUCTS)
        .map(item => this.api.widget.addVariantToRule(item.rule, item.cartItem.variant_id)),
    );
  }

  /**
   * @param {Object[]} items
   * @returns {Object[]}
   */
  #prepareItems = items => {
    return items.map(item => {
      const lineItem = {id: item.cartItem.id, quantity: item.cartItem.quantity, properties: item.cartItem.properties};

      const planId = item.rule.subscription_frequencies.find(f => f.selected).selling_plan_id;
      const name = this.getFixedFrequencyName(item.rule, planId.toString());
      this.handleLineItems([lineItem], planId, item.rule, name);

      return lineItem;
    });
  }
}