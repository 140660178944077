//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import FulfillmentOrder from "./FulfillmentOrder";

export const PENDING = 'PENDING';
export const PAID = 'PAID';
export const FAILED = 'FAILED';

export default class SubscriptionContractOrder extends AbstractParent {

  /**
   * @type  {number}
   */
  id;

  /**
   * @type  {number}
   */
  order_id;

  /**
   * @type  {string|null}
   */
  draft_order_id = null;

  /**
   * @type  {Date}
   */
  reminder_sent_at = null;

  /**
   * @type  {number}
   */
  subscription_contract_id;

  /**
   * @type  {Date}
   */
  created_at;

  /**
   * @type  {Date}
   */
  created_in_shop_tz;

  /**
   * @type  {Date}
   */
  updated_at;

  /**
   * @type  {PENDING|PAID|FAILED|'PENDING'|'PAID'|'FAILED'}
   */
  status;

  /**
   * @type  {string|null}
   */
  name = null;

  /**
   * @type  {string|null}
   */
  status_url = null;

  /**
   * @type  {number}
   */
  total_price;

  /**
   * @type  {boolean}
   */
  is_processed_by_app = false;

  /**
   * @type  {number}
   */
  old_app_invoice_id;

  /**
   * @type {FulfillmentOrder[]}
   */
  fulfillment_orders = [];

  /**
   * @type {string|null}
   */
  current_error = null;

  /**
   * It helps to make object structure
   * @type {{string: *}}
   */
  get classMapping () {
    return {
      fulfillment_orders: FulfillmentOrder
    };
  };

  /**
   * @param {object} properties
   */
  constructor (properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @returns {boolean}
   */
  get isScheduled () {
    return this.fulfillment_orders ? this.fulfillment_orders.some(f => !f.isClosed) : false;
  }

  /**
   * @returns {FulfillmentOrder[]}
   */
  get formattedFulfillments () {
    return this.fulfillment_orders.filter(f => !f.isRefunded).map(f => this.getFulfillmentWithOrderParams(f));
  }

  /**
   * @param {FulfillmentOrder} fulfillment
   * @returns {Object}
   */
  getFulfillmentWithOrderParams (fulfillment) {
    return {
      ...fulfillment.formatted,
      status_url: this.status_url,
      order_name: this.name,
      order_id: this.order_id,
    }
  }
}