//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import BoxSize from "./BoxSize";
import BoxDiscount from "./BoxDiscount";
import BoxSellingPlan from './BoxSellingPlan';

export const CART = 'CART';
export const CHECKOUT = 'CHECKOUT';

export default class Box extends AbstractParent {

  /**
   * @type {number}
   */
  id;

  /**
   * @type {number}
   */
  shop_id;

  /**
   * @type {number}
   */
  selling_plan_group_id;

  /**
   * @type {number}
   */
  rule_id;

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  name_internal;

  /**
   * @type {string}
   */
  description;

  /**
   * @type {CART|CHECKOUT|'CART'|'CHECKOUT'}
   */
  atc_action = CART;

  /**
   * @type {string}
   */
  image;

  /**
   * @type {boolean}
   */
  is_enabled = true;

  /**
   * @type {Date}
   */
  created_at;

  /**
   * @type {Date}
   */
  updated_at;

  /**
   * @type {Date}
   */
  deleted_at = null;

  /**
   * @type  {BoxSize[]}
   */
  sizes;

  /**
   * @type  {BoxDiscount[]}
   */
  discounts;

  /**
   * @type {number}
   */
  page_id;

  /**
   * @type {boolean}
   */
  hide_widget = true;

  /**
   * @type {BoxSellingPlan[]}
   */
  sellingPlans;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      sizes: BoxSize,
      discounts: BoxDiscount,
      sellingPlans: BoxSellingPlan,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}