//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';

export default class BoxSellingPlan extends AbstractParent {

  /**
   * @type {number}
   */
  id;

  /**
   * @type {number}
   */
  box_discount_id;

  /**
   * @type {number}
   */
  shopify_id;

  /**
   * @type {number}
   */
  rule_selling_plan_id;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}