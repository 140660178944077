//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SettingsCustomerPortalLabelsOrderStatusWidget from './SettingsCustomerPortalLabelsOrderStatusWidget';
import SettingsCustomerPortalLabelsGeneral from './SettingsCustomerPortalLabelsGeneral';
import SettingsCustomerPortalLabelsSubscriptions from './SettingsCustomerPortalLabelsSubscriptions';
import SettingsCustomerPortalLabelsSubscription from './SettingsCustomerPortalLabelsSubscription';
import SettingsCustomerPortalLabelsHistory from './SettingsCustomerPortalLabelsHistory';
import SettingsCustomerPortalLabelsFrequency from './SettingsCustomerPortalLabelsFrequency';
import SettingsCustomerPortalLabelsNextPayment from './SettingsCustomerPortalLabelsNextPayment';
import SettingsCustomerPortalLabelsPause from './SettingsCustomerPortalLabelsPause';
import SettingsCustomerPortalLabelsResume from './SettingsCustomerPortalLabelsResume';
import SettingsCustomerPortalLabelsCancel from './SettingsCustomerPortalLabelsCancel';
import SettingsCustomerPortalLabelsSkip from './SettingsCustomerPortalLabelsSkip';
import SettingsCustomerPortalLabelsOrderNow from './SettingsCustomerPortalLabelsOrderNow';
import SettingsCustomerPortalLabelsShippingAddress from './SettingsCustomerPortalLabelsShippingAddress';
import SettingsCustomerPortalLabelsPaymentMethod from './SettingsCustomerPortalLabelsPaymentMethod';
import SettingsCustomerPortalLabelsFulfillment from "./SettingsCustomerPortalLabelsFulfillment";
import SettingsCustomerPortalLabelsAddRemoveProduct from './SettingsCustomerPortalLabelsAddRemoveProduct';
import SettingsCustomerPortalLabelsBuildABox from "./SettingsCustomerPortalLabelsBuildABox";


export default class SettingsCustomerPortalLabels extends AbstractParent {


  /**
   * @type  {SettingsCustomerPortalLabelsOrderStatusWidget}
   */
  order_status_widget;

  /**
   * @type  {SettingsCustomerPortalLabelsGeneral}
   */
  general;

  /**
   * @type  {SettingsCustomerPortalLabelsSubscriptions}
   */
  subscriptions;

  /**
   * @type  {SettingsCustomerPortalLabelsSubscription}
   */
  subscription;

  /**
   * @type  {SettingsCustomerPortalLabelsHistory}
   */
  history;

  /**
   * @type  {SettingsCustomerPortalLabelsFrequency}
   */
  frequency;

  /**
   * @type  {SettingsCustomerPortalLabelsNextPayment}
   */
  next_payment;

  /**
   * @type  {SettingsCustomerPortalLabelsPause}
   */
  pause;

  /**
   * @type  {SettingsCustomerPortalLabelsResume}
   */
  resume;

  /**
   * @type  {SettingsCustomerPortalLabelsCancel}
   */
  cancel;

  /**
   * @type  {SettingsCustomerPortalLabelsSkip}
   */
  skip;

  /**
   * @type  {SettingsCustomerPortalLabelsOrderNow}
   */
  order_now;

  /**
   * @type  {SettingsCustomerPortalLabelsShippingAddress}
   */
  shipping_address;

  /**
   * @type  {SettingsCustomerPortalLabelsPaymentMethod}
   */
  payment_method;

  /**
   * @type {SettingsCustomerPortalLabelsFulfillment}
   */
  fulfillment;

  /**
   * @type {SettingsCustomerPortalLabelsAddRemoveProduct}
   */
  add_remove_product;

  /**
   * @type {SettingsCustomerPortalLabelsBuildABox}
   */
  box;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      order_status_widget: SettingsCustomerPortalLabelsOrderStatusWidget,
      general: SettingsCustomerPortalLabelsGeneral,
      subscriptions: SettingsCustomerPortalLabelsSubscriptions,
      subscription: SettingsCustomerPortalLabelsSubscription,
      history: SettingsCustomerPortalLabelsHistory,
      frequency: SettingsCustomerPortalLabelsFrequency,
      next_payment: SettingsCustomerPortalLabelsNextPayment,
      pause: SettingsCustomerPortalLabelsPause,
      resume: SettingsCustomerPortalLabelsResume,
      cancel: SettingsCustomerPortalLabelsCancel,
      skip: SettingsCustomerPortalLabelsSkip,
      order_now: SettingsCustomerPortalLabelsOrderNow,
      shipping_address: SettingsCustomerPortalLabelsShippingAddress,
      payment_method: SettingsCustomerPortalLabelsPaymentMethod,
      fulfillment: SettingsCustomerPortalLabelsFulfillment,
      add_remove_product: SettingsCustomerPortalLabelsAddRemoveProduct,
      box: SettingsCustomerPortalLabelsBuildABox,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}