import { FIXED, PERCENTAGE } from "../classes/Discount";
import { isEqual } from 'lodash';
import PromiseWrapper from "./PromiseWrapper";
import resolve from "../resolve";
import AppConfig from "../config/config";
import { AppLibrary } from "../helpers/AppLibrary";
import Storage from "../config/Storage";
import Selectors from '../components/Selectors';
import Api from '../components/api/Api';
import {CENTS_IN_DOLLAR} from '../components/widget/Prices';
import Actions from './Actions';
import Upsell from './Upsell';
import {initCartEventListener} from './EventListener';

/**
 *
 */
export default class Cart {

  /**
   * @type {Object}
   */
  #global;

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Object}
   */
  #cartAdapter;

  /**
   * @type {Storage}
   */
  #storage;

  /**
   * @type {Selectors}
   */
  #selectors;

  /**
   * @type {Api}
   */
  #api;

  /**
   * @type {Actions}
   */
  #actions;

  /**
   * @type {Upsell}
   */
  #upsell;

  /**
   *
   */
  constructor () {
    this.#global = resolve('global');
    this.#config = resolve(AppConfig);
    this.#cartAdapter = this.#global.cartPool.getAdapter(resolve(AppLibrary).appName);
    this.#storage = resolve(Storage);
    this.#selectors = resolve(Selectors);
    this.#api = resolve(Api);
    this.#actions = resolve(Actions);
    this.#upsell = resolve(Upsell);
  }

  /**
   *
   */
  init = async () => {
    if (resolve(AppLibrary).getCurrentPage() === 'cart') {
      this.#actions.init();
      initCartEventListener();

      if (this.#config.upsells.cart?.enabled) {
        this.#upsell.run();
        this.#setUpsellModalListener();
      }
    }

    this.#global.checkout.onBeforeCheckout(this.#onBeforeCheckout);
    this.#cartAdapter.onChange(this.#adjustContracts);

    if (await this.#removeInvalidProducts()) {
      location.reload();
    }
    this.#adjustContracts();
    if (this.#config.isEligible) {
      this.#startPricesCrossing();
    } else {
      this.#applyDiscounts();
    }
  }

  /**
   *
   */
  #setUpsellModalListener = () => {
    document.body.addEventListener('click', e => {
      if (e.target.matches('[data-action="checkout"]')) {
        e.preventDefault();
        this.#onCheckout(null, true).then();
      }
    });
  }

  /**
   *
   */
  #onBeforeCheckout = () => {
    if (this.#onlyInvoiceContractsInCart()) {
      this.#global.checkout.onCheckout(this.#onCheckout);
    }
  }

  /**
   *
   * @param {function} next
   * @param {boolean} ignoreUpsell
   * @returns {Promise<void>}
   */
  #onCheckout = async (next = null, ignoreUpsell = false) => {
    if (!this.#config.customization.isCheckoutEnabled) {
      return;
    }

    if (this.#upsell.isModalShown && !ignoreUpsell) {
      return;
    }

    await this.#updateConfig();

    if (await this.#removeInvalidProducts()) {
      location.reload();
      return;
    }

    if (!this.#onlyInvoiceContractsInCart()) {
      return;
    }

    if (typeof next === 'function') {
      next(false);
    }

    const noteAttributes = this.#createAttributesFromContracts();
    const cartData = this.#getCartData();
    const options = {
      note_attributes: noteAttributes,
      tags: this.#config.settings.general.tag,
    };

    const result = await this.#global.checkout.submitCustomDraftOrder(cartData, options);
    location.href = result.draft_order.invoice_url;
  }

  /**
   * @returns {Promise<boolean>}
   */
  async #removeInvalidProducts() {
    const contracts = this.#storage.getItem('contracts');
    if (!contracts || !contracts.length) {
      return false;
    }
    const actualRuleIds = this.#config.rules.map(r => r.id);
    const invalidContracts = contracts.filter(c => !actualRuleIds.includes(c.ruleId));
    const result = [];
    invalidContracts.forEach(c => {
      const item = this.#findLineItemByContract(c);
      if (item) {
        result.push(item.remove());
      }
    });

    await PromiseWrapper.allSettled(result);
    return !!result.length;
  }

  /**
   *
   */
  async #updateConfig() {
    try {
      const settings = await this.#api.shop.getFrontSettings();
      this.#config.init(settings);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   *
   * @returns {*}
   */
  #getCartData () {
    const cartData = this.#cartAdapter.getForDraftOrder();

    const contracts = this.#storage.getItem('contracts');
    contracts.forEach(c => {
      const item = this.#findLineItemByContract(c);
      if (item) {
        const foundIndex = cartData.line_items.findIndex(i => (
          i.variant_id === item.variant_id
          && i.quantity === item.quantity
          && isEqual(i.properties, item.properties)
        ));

        if (foundIndex >= 0) {
          cartData.line_items[foundIndex].properties = {
            ...cartData.line_items[foundIndex].properties,
            [this.#config.settings.general.cart_label]: c.frequency,
          };
          const rule = this.#config.rules.find(r => r.id === c.ruleId);
          const draftOrderDiscount = rule.getDraftOrderDiscount(c.sellingPlanId);
          if (draftOrderDiscount) {
            cartData.line_items[foundIndex].applied_discount = draftOrderDiscount;
          }
        } else {
          console.warn('Index is not found');
        }
      } else {
        console.warn('Line item is not found');
      }
    });

    return cartData;
  }

  /**
   *
   */
  #crossPrices = () => {
    const lineItems = this.#cartAdapter.getItems();
    const {SELECTOR} = this.#global.constants;
    const priceElements = this.#selectors.getElements(SELECTOR.CART_SUBTOTAL);
    if (!priceElements.length) {
      return;
    }

    const reducer = (sum, lineItem) => {
      let {price} = lineItem;
      if (lineItem.selling_plan_allocation && lineItem.selling_plan_allocation.compare_at_price > 0) {
        price = lineItem.selling_plan_allocation.compare_at_price;
      }

      return sum + (price / CENTS_IN_DOLLAR) * lineItem.quantity;
    };

    const oldPrice = lineItems.reduce(reducer, 0);
    const newPrice = this.#cartAdapter.getDiscountedTotal() - Spurit.globalSnippet.cart.total_discount / CENTS_IN_DOLLAR;
    priceElements.forEach(e => this.#global.prices.displayNewPrice(e, newPrice, oldPrice));
  }

  /**
   *
   */
  #startPricesCrossing () {
    setInterval(() => this.#crossPrices(), 1000);
  }

  /**
   *
   * @returns {boolean}
   */
  #onlyInvoiceContractsInCart () {
    const contracts = this.#storage.getItem('contracts');
    if (!contracts || !contracts.length) {
      return false;
    }

    return contracts.every(c => {
      const rule = this.#config.rules.find(r => r.id === c.ruleId);
      return rule.invoice.is_enabled;
    });
  }

  /**
   *
   */
  #adjustContracts = () => {
    if (this.#upsell.addingToCart) {
      return;
    }

    let contracts = this.#storage.getItem('contracts');
    if (!contracts || !contracts.length) {
      return;
    }
    contracts = contracts.filter(c => !!this.#findLineItemByContract(c));
    this.#storage.setItem('contracts', contracts);
  }

  /**
   * @param {object} lineItem
   * @param {Discount|null} discount
   * @return {boolean}
   */
  #applyDiscount (lineItem, discount) {
    if (!discount || !lineItem) {
      return false;
    }

    if (discount.type === FIXED) {
      lineItem.addFixedDiscount(discount.value);
    } else if (discount.type === PERCENTAGE) {
      lineItem.addPercentDiscount(discount.value);
    }

    return true;
  }

  /**
   * @param contract
   * @returns {*}
   */
  #findLineItemByContract (contract) {
    const lineItems = this.#cartAdapter.getItems();
    let result = lineItems.find(i => (
      i.variant_id === contract.variantId
      && !!i.properties
      && Object.values(i.properties).includes(contract.frequency)
    ));

    if (!result) {
      result = lineItems.find(i => (
        i.variant_id === contract.variantId && i.selling_plan_allocation
        && i.selling_plan_allocation.selling_plan.id === +contract.sellingPlanId
      ));
    }

    return result;
  }

  /**
   *
   * @returns {boolean}
   */
  #applyDiscounts () {
    const contracts = this.#storage.getItem('contracts');
    if (!contracts || !contracts.length) {
      return false;
    }

    contracts.forEach(c => {
      const rule = this.#config.rules.find(r => r.id === c.ruleId);
      const discount = rule.getActualDiscount(c.sellingPlanId);
      if (discount) {
        this.#applyDiscount(this.#findLineItemByContract(c), discount);
      }
    });

    return true;
  }

  /**
   *
   * @returns {{name: string, value: string}[]|*[]}
   */
  #createAttributesFromContracts () {
    const contracts = this.#storage.getItem('contracts');
    if (!contracts || !contracts.length) {
      return [];
    }

    return contracts.map((c, key) => {
      return {
        name: `${this.#config.env.initialOrderAtributePrefix}-${key}`,
        value: `${c.ruleId}-${c.variantId}-${c.sellingPlanId}-${c.frequency}`,
      };
    });
  }
}