import template from '../templates/upsells/cart/modal.mustache';
import closeIcon from '../templates/icons/close.html';
import resolve from '../resolve';
import AppConfig from '../config/config';
import Mustache from 'mustache';

const UPDATE_BUTTON_SELECTOR = '.spurit-ros__popup-actions .btn--primary';

export default class UpsellModal {

  /**
   * @type {boolean}
   */
  shown = false;

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {function}
   */
  #onUpdateClick;

  /**
   * @param {function} onUpdateClick
   */
  constructor (onUpdateClick = () => null) {
    this.#config = resolve(AppConfig);
    this.#onUpdateClick = onUpdateClick;
  }

  /**
   *
   */
  init () {
    this.#insert();
    this.#setListeners();
  }

  /**
   *
   */
  show () {
    this.#overlay.style.display = '';
    this.shown = true;
  }

  /**
   *
   */
  hide () {
    this.#overlay.style.display = 'none';
    this.shown = false;
  }

  /**
   * @returns {HTMLElement}
   */
  get #overlay () {
    return document.body.querySelector('.spurit-ros__overlay_cart-upsell');
  }

  /**
   *
   */
  #setListeners = () => {
    this.#overlay.addEventListener('click', e => {
      if (e.target.matches('.spurit-ros__overlay')) {
        this.hide();
        return;
      }

      if (e.target.closest('[data-action="close-popup"]') || e.target.closest('[data-action="checkout"]')) {
        this.hide();
        return;
      }

      const button = e.target.closest(UPDATE_BUTTON_SELECTOR);
      if (button) {
        button.classList.add('ros-btn_loading');
        this.#onUpdateClick();
      }
    });
  }

  /**
   *
   */
  #insert = () => {
    const upsellPhrases = this.#config.upsells.cart.texts.modal;

    const modalHTML = Mustache.render(template, {
      proceed_label: upsellPhrases.proceed,
      update_label: upsellPhrases.update,
      content: upsellPhrases.content,
      title: upsellPhrases.title,
      close: closeIcon,
      header: true,
    });

    document.body.insertAdjacentHTML('beforeend', modalHTML);
  }
}