//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsSubscription extends AbstractParent {


  /**
   * @type  {boolean}
   */
  is_cancellable = true;

  /**
   * @type  {boolean}
   */
  is_pausable = true;

  /**
   * @type  {boolean}
   */
  is_next_order_date_changeable = false;

  /**
   * @type  {boolean}
   */
  is_frequency_changeable = false;

  /**
   * @type  {boolean}
   */
  is_additional_order_allowed = false;

  /**
   * @type  {boolean}
   */
  is_payment_skipable = false;

  /**
   * @type {boolean}
   */
  is_delivery_date_changeable = false;

  /**
   * @type {boolean}
   */
  is_product_qty_editable = false;

  /**
   * @type {boolean}
   */
  is_product_addition_allowed = false;

  /**
   * @type {boolean}
   */
  is_one_purchase_products_allowed = false;

  /**
   * @type {boolean}
   */
  is_product_swap_allowed = false;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}