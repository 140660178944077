//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SettingsCustomerPortalLabelsHistoryAuto from './SettingsCustomerPortalLabelsHistoryAuto';
import SettingsCustomerPortalLabelsHistoryInvoice from './SettingsCustomerPortalLabelsHistoryInvoice';


export default class SettingsCustomerPortalLabelsHistory extends AbstractParent {


  /**
   * @type  {string}
   */
  date = ___('Date');

  /**
   * @type  {string}
   */
  status = ___('Status');

  /**
   * @type  {string}
   */
  pending = ___('Pending');

  /**
   * @type  {string}
   */
  failed = ___('Failed');

  /**
   * @type  {string}
   */
  paid = ___('Paid');

  /**
   * @type  {string}
   */
  details = ___('Details');

  /**
   * @type  {string}
   */
  secure3d_text = ___('Expecting 3D Secure authentication — Click to confirm your payment:');

  /**
   * @type  {string}
   */
  secure3d_button = ___('I confirm');

  /**
   * @type  {SettingsCustomerPortalLabelsHistoryAuto}
   */
  auto;

  /**
   * @type  {SettingsCustomerPortalLabelsHistoryInvoice}
   */
  invoice;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      auto: SettingsCustomerPortalLabelsHistoryAuto,
      invoice: SettingsCustomerPortalLabelsHistoryInvoice,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}