//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SubscriptionContractShippingAddress extends AbstractParent {


  /**
   * @type  {string}
   */
  first_name = null;

  /**
   * @type  {string}
   */
  last_name;

  /**
   * @type  {string}
   */
  address;

  /**
   * @type  {string}
   */
  apartment = null;

  /**
   * @type  {string}
   */
  city;

  /**
   * @type  {string}
   */
  zip;

  /**
   * @type  {string}
   */
  country_code;

  /**
   * @type  {string}
   */
  province_code = null;

  /**
   * @type  {string}
   */
  company = null;

  /**
   * @type  {string}
   */
  phone = null;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}