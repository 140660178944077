const PRODUCTS_URL = '/products.json?limit=99&page=';
const PRODUCT_URL = '/products/{handle}.json';
const COLLECTIONS_URL = '/collections.json?page=';
const COLLECTION_PRODUCTS_URL = '/collections/{handle}/products.json?page={page}';

export const PER_PAGE = 30;

export default class Api {

  /**
   * @param {number} page
   * @returns {Promise<any>}
   */
  static getProductsPage = (page = 1) => {
    return fetch(`${PRODUCTS_URL}${page}`)
      .then(response => response.json())
      .then(r => r.products);
  };

  /**
   * @param {number[]|null} ids
   * @param {number} startPage
   * @param {Object[]} loadedCollections
   * @returns {Promise<any>}
   */
  static getCollections = (ids = null, startPage = 1, loadedCollections = []) => {
    return Api.getCollectionsPage(startPage).then(collections => {
      const targetCollections = ids ? collections.filter(c => ids.includes(c.id)) : collections;
      loadedCollections = [...loadedCollections, ...targetCollections];

      if ((ids && loadedCollections.length === ids.length) || collections.length < PER_PAGE) {
        return loadedCollections;
      }

      return Api.getCollections(ids, startPage + 1, loadedCollections);
    });
  };

  /**
   * @param {number} page
   * @returns {Promise<any>}
   */
  static getCollectionsPage = page => {
    return fetch(`${COLLECTIONS_URL}${page}`)
      .then(response => response.json())
      .then(r => r.collections);
  }

  /**
   * @param {string} handle
   * @param {number} startPage
   * @param {Object[]} loadedProducts
   * @returns {Promise<any>}
   */
  static getAllCollectionProducts = (handle, startPage = 1, loadedProducts = []) => {
    return Api.getCollectionProductsPage(handle, startPage).then(products => {
        loadedProducts = [...loadedProducts, ...products];

        if (products.length < PER_PAGE) {
          return loadedProducts;
        }

        return Api.getAllCollectionProducts(handle, startPage + 1, loadedProducts);
      });
  };

  /**
   * @param {string} handle
   * @param {number} page
   * @returns {Promise<any>}
   */
  static getCollectionProductsPage = (handle, page = 1) => {
    return fetch(COLLECTION_PRODUCTS_URL.replace('{handle}', handle).replace('{page}', page))
      .then(response => response.json())
      .then(r => r.products);
  };

  /**
   * @param {number[]} cids
   * @returns {Promise<Object[]>}
   */
  static getProductsFromSpecificCollections = cids => {
    let collectionProducts = [];
    return Api.getCollections(cids).then(collections => {
      return Promise.all(
        collections.map(c => {
          // eslint-disable-next-line max-nested-callbacks
          return Api.getAllCollectionProducts(c.handle).then(products => {
            // eslint-disable-next-line max-nested-callbacks
            collectionProducts = [...collectionProducts, ...products.map(p => p.id)];
          });
        }),
      ).then(() => collectionProducts);
    });
  }

  /**
   * @param {string} handle
   * @return {Promise<any>}
   */
  static getProduct = handle => {
    return fetch(PRODUCT_URL.replace('{handle}', handle))
      .then(response => response.json())
      .then(data => data.product);
  }
}