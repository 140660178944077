/* eslint func-names: 0 */

import Settings from "../classes/Settings";
import Rule from "../containers/Rule";
import { DAY, WEEK, YEAR, MONTH } from "../classes/TimeInterval";
import {escape} from "../../../assets/js/helpers/utils";
import Box from '../containers/Box';
import CartUpsell from '../classes/CartUpsell';

export default class AppConfig {

  /**
   * @type {Env}
   */
  env;

  /**
   * @type {Snippet}
   */
  snippet;

  /**
   * @type {Customization}
   */
  customization;

  /**
   * @type {Settings}
   */
  settings;

  /**
   * @type {Rule[]}
   */
  rules;

  /**
   * @type {Box[]}
   */
  boxes;

  /**
   * @type {Object}
   */
  upsells;

  /**
   * @type {string}
   */
  timezone;

  /**
   * @type {boolean}
   */
  isEligible;

  /**
   * @param {Env} env
   * @param {Snippet} snippet
   * @param {Customization} customization
   * @constructor
   */
  constructor(env, snippet, customization) {
    this.env = env;
    this.snippet = snippet;
    this.customization = customization;
  }

  /**
   * @returns {false}
   */
  get isCartUpsellModalEnabled () {
    return this.upsells.cart.show_modal && this.customization.isCheckoutEnabled;
  }

  /**
   *
   */
  init = settings => {
    if (typeof settings.boxes === 'undefined') {
      settings.boxes = [];
    }

    this.rules = settings.rules.map(r => new Rule(r));
    this.boxes = settings.boxes.map(b => new Box(b));
    this.settings = new Settings(settings.settings);
    this.timezone = settings.timezone;
    this.isEligible = settings.isShopEligible;

    this.initUpsells(settings.upsells);
  };

  /**
   * @param {Object} upsells
   */
  initUpsells = (upsells = {}) => {
    this.upsells = {};
    if (upsells?.cart) {
      this.upsells.cart = new CartUpsell(upsells.cart);
    }
  }

  /**
   *
   * @param {string} period
   * @returns {string}
   */
  getPeriodLabel(period) {
    const labels = this.settings.customer_portal_labels.frequency;
    let result;
    switch (period) {
      case YEAR: result = labels.years; break;
      case WEEK: result = labels.weeks; break;
      case DAY: result = labels.days; break;
      case MONTH: result = labels.months; break;
      default: result = labels.months;
    }

    return escape(result);
  };
};
