import BoxC from '../classes/Box';
import {DISCOUNT_TYPES} from '../classes/BoxDiscount';
import {countProducts, sort} from '../helpers/utils';
import {EXACT, RANGE} from '../classes/BoxSize';

export const BOX_ID_PROP = '_ros_box_id';
export const BOX_SIZE_PROP = '_box_size';

export default class Box extends BoxC {

  /**
   * @type {boolean}
   */
  one_time_purchase = true;

  /**
   * @type {number}
   */
  contractId;

  /**
   * @type {Rule}
   */
  rule;

  /**
   * @type {Object[]}
   */
  items = [];

  /**
   * @returns {boolean}
   */
  get isOneTimePurchaseAllowed () {
    return !this.contractId && !this.rule.purchase_only_as_subscription;
  }

  /**
   * @returns {string|null}
   */
  get discountType () {
    return this.discounts?.length ? this.discounts[0].type : null;
  }

  /**
   * @type {BoxSize|null}
   */
  get selectedSize () {
    return this.sizes.find(s => s.active);
  }

  /**
   * @returns {boolean}
   */
  get hasExactSizes () {
    return this.sizes?.some(s => s.type === EXACT);
  }

  /**
   * @returns {RuleSubscriptionFrequency}
   */
  get activeFrequency () {
    return this.rule.subscription_frequencies.find(f => f.active);
  }

  /**
   * @returns {Object}
   */
  get activeRuleSellingPlan () {
    const {activeFrequency} = this;
    return this.rule.selling_plans_not_inner.find(sp => sp.selling_plan_id === activeFrequency?.selling_plan_id);
  }

  /**
   * @returns {Object[]}
   */
  get availableItems () {
    return this.items.filter(i => i.available !== false);
  }

  /**
   * @returns {Object[]}
   */
  get itemsForAtc () {
    const sellingPlan = this.actualSellingPlan;
    const size = this.selectedSize;

    return this.availableItems.map(i => {
      const item = {
        id: i.id,
        quantity: i.quantity,
        properties: {[BOX_ID_PROP]: this.id},
      };

      if (size) {
        item.properties[BOX_SIZE_PROP] = size.min;
      }

      if (sellingPlan) {
        item.selling_plan = sellingPlan.shopify_id;
      }

      return item;
    });
  }

  /**
   * @returns {boolean}
   */
  get hasError () {
    return this.items.some(i => i.error);
  }

  /**
   * @returns {boolean}
   */
  get hasNotAvailableItems () {
    return this.items.some(i => (i.available === false || !!i.error) && !i.can_be_skipped);
  }

  /**
   * @returns {boolean}
   */
  get isFull () {
    if (!this.sizes?.length) {
      return false;
    }

    const itemsCount = countProducts(this.items);
    const {selectedSize} = this;
    if (selectedSize) {
      return itemsCount >= selectedSize.min;
    }

    return this.sizes[0].max && itemsCount >= this.sizes[0].max;
  }

  /**
   * @returns {BoxDiscount|null}
   */
  get actualDiscount () {
    if (!this.discounts?.length) {
      return null;
    }

    const {discountType} = this;

    if (discountType === DISCOUNT_TYPES.EXACT_COUNT) {
      return this.#actualSizeDiscount;
    }

    if (discountType === DISCOUNT_TYPES.COUNT) {
      return this.#actualCountDiscount;
    }

    if (discountType === DISCOUNT_TYPES.AMOUNT) {
      return this.#actualAmountDiscount;
    }

    return null;
  }

  /**
   * @returns {boolean}
   */
  get canBeAddedToCart () {
    if (!this.items?.length || this.hasNotAvailableItems) {
      return false;
    }

    if (!this.sizes?.length) {
      return true;
    }

    const {selectedFrequency} = this;
    if (selectedFrequency?.disabled) {
      return false;
    }

    const size = this.selectedSize || this.sizes[0];
    if (size?.disabled) {
      return false;
    }

    const itemsCount = countProducts(this.items);
    if (size.type === RANGE) {
      return size.min <= itemsCount && (!size.max || itemsCount <= size.max);
    }

    return size.min === itemsCount;
  }

  /**
   * @returns {BoxSellingPlan}
   */
  get actualSellingPlan () {
    if (this.one_time_purchase) {
      return null;
    }

    const discount = this.actualDiscount;
    const rulePlanId = this.activeRuleSellingPlan?.id;

    return this.sellingPlans.find(sp => {
      const isSamePlan = !sp.rule_selling_plan_id || sp.rule_selling_plan_id === rulePlanId;
      return isSamePlan && (!discount || !sp.box_discount_id || sp.box_discount_id === discount.id);
    });
  }

  /**
   * @returns {Object}
   */
  get dataForUpdate () {
    const data = {size: this.selectedSize?.min, items: this.itemsForAtc};

    const discount = this.actualDiscount;
    if (discount) {
      data.discount = discount.value;
    }

    const frequency = this.activeFrequency;
    if (!frequency.disabled && !frequency.inUse) {
      data.frequency = frequency;
      data.frequency.frequency_number = frequency.frequency.frequency_number;
      data.frequency.frequency_unit = frequency.frequency.frequency_unit;
    }

    return data;
  }

  /**
   * @param {BoxDiscount|null} discount
   * @returns {BoxDiscount|null}
   */
  getNextDiscount = (discount = null) => {
    if (!this.discounts?.length) {
      return null;
    }

    discount = discount || this.actualDiscount;

    const discountType = discount?.type || this.discountType;

    if (discountType === DISCOUNT_TYPES.EXACT_COUNT) {
      return this.#expectedSizeDiscount;
    }

    if (discountType === DISCOUNT_TYPES.COUNT) {
      return this.#getNextCountDiscount(discount);
    }

    if (discountType === DISCOUNT_TYPES.AMOUNT) {
      return this.#getNextAmountDiscount(discount);
    }

    return null;
  }

  /**
   * @param {BoxDiscount} discount
   * @returns {number|null}
   */
  getDiscountMinItems = discount => {
    if (discount.type === DISCOUNT_TYPES.COUNT) {
      return discount.min_items;
    }

    if (discount.box_size_id) {
      return this.#getDiscountSize(discount)?.min;
    }

    return null;
  }

  /**
   * @param {string} field
   * @param {boolean} asc
   */
  sortSizes = (field, asc = true) => sort(this.sizes, field, asc);

  /**
   * @param {Object} item
   */
  addItem = item => {
    const existing = this.items.find(i => i.id === item.id);
    if (existing) {
      existing.quantity++;
    } else {
      this.items.push({...item, quantity: 1});
    }
  }

  /**
   * @param {number} vid
   * @param {number} adjustment
   */
  adjustItemQuantity = (vid, adjustment) => {
    if (adjustment > 0 && this.isFull) {
      return;
    }

    const item = this.items.find(i => i.id === vid);
    let newQuantity = item.quantity + adjustment;
    if (newQuantity <= 0) {
      newQuantity = 1;
    }

    item.quantity = newQuantity;
    item.error = null;
  }

  /**
   * @param {number} vid
   */
  removeItem = vid => {
    const itemIndex = this.items.findIndex(i => i.id === vid);
    if (itemIndex === -1) {
      return;
    }

    this.items.splice(itemIndex, 1);
  }

  /**
   * @returns {BoxDiscount|null}
   */
  get #actualSizeDiscount () {
    const {selectedSize} = this;
    const itemsCount = countProducts(this.items);
    if (!selectedSize || selectedSize.min !== itemsCount) {
      return null;
    }

    return this.#getSizeDiscount(selectedSize);
  }

  /**
   * @returns {BoxDiscount|null}
   */
  get #expectedSizeDiscount () {
    const {selectedSize} = this;
    const itemsCount = countProducts(this.items);
    if (!selectedSize || selectedSize.min === itemsCount) {
      return null;
    }

    return this.#getSizeDiscount(selectedSize);
  }

  /**
   * @returns {BoxDiscount}
   */
  get #actualCountDiscount () {
    this.#sortDiscounts('min_items', false);
    const itemsCount = countProducts(this.items);
    return this.discounts.find(d => d.min_items <= itemsCount);
  }

  /**
   * @returns {BoxDiscount}
   */
  get #actualAmountDiscount () {
    const amount = this.items.reduce((sum, i) => sum + (i.price * i.quantity), 0);
    this.#sortDiscounts('min_amount', false);
    return this.discounts.find(d => d.min_amount <= amount);
  }

  /**
   * @param {BoxDiscount} discount
   * @returns {BoxDiscount|null}
   */
  #getNextCountDiscount = discount => {
    this.#sortDiscounts('min_items');
    return this.#getDiscountAfter(discount);
  }

  /**
   * @param {BoxDiscount} discount
   * @returns {BoxDiscount}
   */
  #getNextAmountDiscount = discount => {
    this.#sortDiscounts('min_amount');
    return this.#getDiscountAfter(discount);
  }

  /**
   * @param {BoxDiscount} discount
   * @returns {BoxDiscount|null}
   */
  #getDiscountAfter = discount => {
    const index = discount ? this.discounts.findIndex(d => d.id === discount.id) : -1;
    for (let i = index + 1; i < this.discounts.length; i++) {
      if (this.discounts[i].value) {
        return this.discounts[i];
      }
    }

    return null;
  }

  /**
   * @param {BoxDiscount} discount
   * @returns {BoxSize}
   */
  #getDiscountSize = discount => this.sizes.find(s => s.id === discount.box_size_id);

  /**
   * @param {BoxSize} size
   * @returns {BoxDiscount}
   */
  #getSizeDiscount = size => this.discounts.find(d => d.box_size_id === size?.id);

  /**
   * @param {string} field
   * @param {boolean} asc
   */
  #sortDiscounts = (field, asc = true) => sort(this.discounts, field, asc);
}