//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsTextLabels extends AbstractParent {


  /**
   * @type  {string}
   */
  widget_title = ___('Purchase options');

  /**
   * @type  {string}
   */
  one_time_purchase = ___('One-time purchase');

  /**
   * @type  {string}
   */
  subscribe = ___('Subscribe');

  /**
   * @type  {string}
   */
  subscribe_and_save = ___('Subscribe');

  /**
   * @type  {string}
   */
  frequency = ___('Delivery frequency');

  /**
   * @type  {string}
   */
  price_per_delivery = '{{price.per.delivery}} per delivery';

  /**
   * @type  {string}
   */
  custom = 'Custom';

  /**
   * @type {string}
   */
  save_badge = ___('SAVE {{discount}}');

  /**
   * @type {string}
   */
  sale_badge = ___('SALE');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}