import AppConfig from "../../config/config";
import resolve from "../../resolve";

export default class Buttons {

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   *
   */
  constructor () {
    this.#config = resolve(AppConfig);
  }

  /**
   *
   * @param {boolean} isSubscriptionOn
   * @param {HTMLElement} atc
   * @param {HTMLElement} buyNow
   */
  update (atc, buyNow, isSubscriptionOn) {
    this.updateAtc(isSubscriptionOn, atc);
  }

  /**
   *
   * @param {boolean} subscriptionSelected
   * @param {HTMLElement} atc
   */
  updateAtc (subscriptionSelected, atc) {
    if (!atc || !this.#config.settings.general.should_replace_text) {
      return;
    }

    let originalText = atc.getAttribute('data-spurit-ros-original');
    if (!originalText) {
      originalText = atc.innerHTML;
      atc.setAttribute('data-spurit-ros-original', originalText);
    }

    atc.innerHTML = subscriptionSelected ? this.#config.settings.general.atc_replacement_text : originalText;
  }

  /**
   *
   * @param {boolean} subscriptionSelected
   * @param {HTMLElement} buyNow
   */
  updateBuyNow (subscriptionSelected, buyNow) {
    if (buyNow) {
      buyNow.style.display = subscriptionSelected ? 'none' : '';
    }
  }
}