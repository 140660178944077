//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsShippingAddress extends AbstractParent {


  /**
   * @type  {string}
   */
  label = ___('Shipping address');

  /**
   * @type  {string}
   */
  empty = ___('No shipping address');

  /**
   * @type  {string}
   */
  edit = ___('EDIT SHIPPING ADDRESS');

  /**
   * @type  {string}
   */
  first_name = ___('First name (optional)');

  /**
   * @type  {string}
   */
  last_name = ___('Last name');

  /**
   * @type  {string}
   */
  address = ___('Address');

  /**
   * @type  {string}
   */
  apartment = ___('Apartment, suite, etc. (optional)');

  /**
   * @type  {string}
   */
  city = ___('City');

  /**
   * @type  {string}
   */
  zip = ___('ZIP / Postal code');

  /**
   * @type  {string}
   */
  country = ___('Country / Region');

  /**
   * @type  {string}
   */
  province = ___('State / Province');

  /**
   * @type  {string}
   */
  company = ___('Company (optional)');

  /**
   * @type  {string}
   */
  phone = ___('Phone (optional)');

  /**
   * @type  {string}
   */
  required = ___('Fill in required fields');

  /**
   * @type  {string}
   */
  cancel = ___('Cancel').toUpperCase();

  /**
   * @type  {string}
   */
  save = ___('Save').toUpperCase();

  /**
   * @type  {string}
   */
  success = ___('Your subscription shipping address has been updated');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}