import Mustache from "mustache";
import { AppLibrary } from "../../helpers/AppLibrary";
import SubscriptionContractProduct from '../../classes/SubscriptionContractProduct';

const pageWrapperTemplate = require('../../templates/subscriptions/wrapper.html');
const dashboardTemplate = require('../../templates/subscriptions/dashboard.html');
const subscriptionsTableTemplate = require('../../templates/subscriptions/tables/subscriptions.html');
const subscriptionsTableMobileTemplate = require('../../templates/subscriptions/mobile/tables/subscriptions.html');
const productsTableTemplate = require('../../templates/subscriptions/tables/products.html');
const productsTableMobileTemplate = require('../../templates/subscriptions/mobile/tables/products.html');
const fulfillmentsTableTemplate = require('../../templates/subscriptions/tables/fulfillments.html');
const fulfillmentsTableMobileTemplate = require('../../templates/subscriptions/mobile/tables/fulfillments.html');
const ordersTableTemplate = require('../../templates/subscriptions/tables/orders.html');
const ordersTableMobileTemplate = require('../../templates/subscriptions/mobile/tables/orders.html');
const mainActions = require('../../templates/subscriptions/main-actions.html');
const addressTemplate = require('../../templates/subscriptions/address.html');
const subscriptionViewTemplate = require('../../templates/subscriptions/view.html');
const banner = require('../../templates/subscriptions/banner.html');
const closeIcon = require('../../templates/icons/close.html');
const portalLinkTemplate = require('../../templates/subscriptions/link.html');
const popupTemplate = require('../../templates/subscriptions/popups/common.html');
const cancellationReasonsTemplate = require('../../templates/subscriptions/popups/cancellation-reasons.html');
const addressFormTemplate = require('../../templates/subscriptions/popups/address-form.html');
const locationTemplateSelect = require('../../templates/subscriptions/popups/location-select.html');
const caretIcon = require('../../templates/icons/caret.html');
const editIcon = require('../../templates/icons/edit.html');
const warningIcon = require('../../templates/icons/warning.html');
const status3dSecureTemplate = require('../../templates/subscriptions/partials/status-3d-secure.html');
const addedItems = require('../../templates/subscriptions/partials/added-items.html');
const swapItems = require('../../templates/subscriptions/partials/swap-items.html');

export const CSS_CLASSES = {
  link: 'link text-link',
  buttonPrimary: 'button button--primary btn btn--primary Button spurit-ros__btn',
  buttonSecondary: 'button button--secondary btn btn--secondary btn--secondary-accent btn-secondary Button Button--secondary spurit-ros__btn',
  fieldInput: 'field__input form__input',
  fieldTextarea: 'field__input text-area form__input form__textarea',
  fieldSelect: 'field__input form__input select__select',
  backgroundColor: 'color-background-1',
  modalClose: 'link link--text spurit-ros__close',
};

export default class TemplatesManager {

  /**
   * @param {Object} variables
   * @param {boolean} mobile
   * @returns {Object}
   */
  renderSubscriptionViewSections (variables, mobile) {
    variables = this.setCommonVariables(variables);

    const sections = {};

    sections.main_actions = Mustache.render(mainActions, variables);

    const productsTemplate = mobile ? productsTableMobileTemplate : productsTableTemplate;
    sections.products_table = Mustache.render(productsTemplate, variables);

    if (variables.fulfillments.length) {
      const fulfillmentsTemplate = mobile ? fulfillmentsTableMobileTemplate : fulfillmentsTableTemplate;
      sections.fulfillments_table = Mustache.render(fulfillmentsTemplate, variables);
    }

    if (variables.orders) {
      const ordersTemplate = mobile ? ordersTableMobileTemplate : ordersTableTemplate;
      sections.orders_table = Mustache.render(ordersTemplate, variables);
    }

    if (variables.shipping_address) {
      sections.shipping_address = this.renderAddress(variables.shipping_address);
    }

    if (variables.billing_address) {
      sections.billing_address = this.renderAddress(variables.billing_address);
    }

    return sections;
  }

  /**
   * @param {Object} address
   * @returns {string}
   */
  renderAddress (address) {
    return Mustache.render(addressTemplate, address);
  }

  /**
   * @param {Object} variables
   * @returns {string}
   */
  renderPageWrapper (variables) {
    variables = this.setCommonVariables(variables);
    return Mustache.render(pageWrapperTemplate, variables);
  }

  /**
   * @param {Object} variables
   * @returns {string}
   */
  renderDashboard (variables) {
    variables = this.setCommonVariables(variables);
    return Mustache.render(dashboardTemplate, variables);
  }

  /**
   * @param {Object} variables
   * @returns {string}
   */
  renderSubscriptionView (variables) {
    variables = this.setCommonVariables(variables);
    return Mustache.render(subscriptionViewTemplate, variables);
  }

  /**
   * @param {string} content
   * @param {boolean} success
   * @returns {string}
   */
  renderBanner (content, success = true) {
    return Mustache.render(banner, {status: success ? 'success' : 'error', content, close: closeIcon});
  }

  /**
   *
   * @param {string} content
   * @returns {string}
   */
  renderPortalLink (content) {
    return Mustache.render(portalLinkTemplate, {...this.setCommonVariables(), manage_subscriptions: content, lang: AppLibrary.getUrlLang()});
  }

  /**
   * @param {Object} variables
   * @param {boolean} mobile
   * @returns {string}
   */
  renderSubscriptionsTable (variables, mobile) {
    variables = this.setCommonVariables(variables);
    const template = mobile ? subscriptionsTableMobileTemplate : subscriptionsTableTemplate;

    return Mustache.render(template, variables);
  }

  /**
   * @param {string} content
   * @param {Object[]} actions
   * @param {boolean} header
   * @returns {string}
   */
  renderPopup (content, actions, header) {
    const variables = this.setCommonVariables();
    return Mustache.render(popupTemplate, {...variables, content, actions, header, hasActions: !!actions.length});
  }

  /**
   * @param {Object} settings
   * @returns {string}
   */
  renderCancellationReasons (settings) {
    const variables = this.setCommonVariables(settings);
    return Mustache.render(cancellationReasonsTemplate, variables);
  }

  /**
   * @param {Object} data
   * @param {Object} labels
   * @param {Object} countries
   * @param {Object|null} provinces
   * @returns {string}
   */
  renderAddressForm (data, labels, countries, provinces = null) {
    const countrySelect = this.renderLocationSelect(
      labels.country_label,
      'country_code',
      Object.values(countries),
    );

    const countryHasProvinces = provinces && Object.keys(provinces).length;

    let provinceSelect = null;
    if (countryHasProvinces) {
      provinceSelect = this.renderLocationSelect(
        labels.province_label,
        'province_code',
        Object.values(provinces),
        true,
      );
    }

    const variables = this.setCommonVariables({
      ...data,
      ...labels,
      country_select: countrySelect,
      province_select: provinceSelect,
    });

    return Mustache.render(addressFormTemplate, variables);
  }

  /**
   * @param {string} label
   * @param {string} name
   * @param {Object[]} options
   * @param {boolean} addEmpty
   * @returns {string}
   */
  renderLocationSelect (label, name, options, addEmpty = false) {
    const variables = this.setCommonVariables({
      label: label,
      select_name: name,
      options: options,
      add_empty_option: addEmpty,
    });

    return Mustache.render(locationTemplateSelect, variables);
  }

  /**
   * 
   * @param {string} details 
   * @param {string} next_action_url 
   * @param {string} next_action_text 
   * @returns {string}
   */
  renderStatus3dSecure (details, next_action_url, next_action_text) {
    const variables = this.setCommonVariables({
      details,
      next_action_url,
      next_action_text,
    });

    return Mustache.render(status3dSecureTemplate, variables);
  }

  /**
   * @param {Object} variables
   * @returns {Object}
   */
  setCommonVariables (variables = {}) {
    if (!variables.classes) {
      variables.classes = CSS_CLASSES;
    }

    if (!variables.close) {
      variables.close = closeIcon;
    }

    if (!variables.caret_icon) {
      variables.caret_icon = caretIcon;
    }

    if (!variables.edit_icon) {
      variables.edit_icon = editIcon;
    }

    if (!variables.warning_icon) {
      variables.warning_icon = warningIcon;
    }

    return variables;
  }

  /**
   * @param {string} message
   * @param {Object[]} items
   * @returns {string}
   */
  addedItemsMessage (message, items) {
    return Mustache.render(addedItems, {message, items: items.map(SubscriptionContractProduct.getFullTitle)});
  }

  /**
   * @param {string} titleToRemove
   * @param {string} titleToAdd
   * @param {Object} phrases
   * @returns {string}
   */
  swapItemsMessage (titleToRemove, titleToAdd, phrases) {
    return Mustache.render(swapItems, {title_to_remove: titleToRemove, title_to_add: titleToAdd, phrases});
  }
}