import {CUSTOM_SELLING_PLAN} from "./WidgetManager";
import resolve from "../../resolve";
import AppConfig from "../../config/config";
import Selectors from "../Selectors";
import { AppLibrary } from "../../helpers/AppLibrary";

const CLOSEST_FORM_SELECTOR = 'form[action*="/cart/add"]';
const HIDDEN_PAYMENT_GATEWAYS_CLASS = 'ros-shopify-payment-button-hide';
const MORE_OPTIONS_BTN_SELECTOR = '.shopify-payment-button__more-options';
const BUY_NOW_SECTION_SELECTOR = '[data-shopify="payment-button"]';
const DELAY_BEFORE_REDIRECTION = 500;

export default class FastCheckout {

  /**
   * @type {object}
   */
  #global;

  /**
   * @type {object}
   */
  #cartAdapter;

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Rule}
   */
  #rule;

  /**
   * @type {Cart}
   */
  #atc;

  /**
   * @type {Selectors}
   */
  #selectors;

  /**
   * @type {HTMLElement}
   */
  #inputElement;

  /**
   *
   * @type {HTMLElement|null}
   */
  #buyNowSection = null;

  /**
   *
   * @type {boolean}
   */
  #subscriptionSelected = false;

  /**
   *
   * @type {boolean}
   */
  #buyNowSectionWasClicked = false;

  /**
   *
   * @type {boolean}
   */
  #initialized = false

  /**
   * @param {Cart} atc
   */
  constructor (atc) {
    this.#atc = atc;

    this.#config = resolve(AppConfig);
    this.#global = resolve('global');
    this.#cartAdapter = this.#global.cartPool.getAdapter(resolve(AppLibrary).appName);
    this.#selectors = resolve(Selectors);

  }

  /**
   * @param {boolean} subscriptionSelected
   * @param {Rule} rule
   * @param {boolean} allowReInit
   */
  init(subscriptionSelected, rule = null, allowReInit = false) {
    if (this.#initialized && !allowReInit) {
      return;
    }

    if (rule) {
      this.#rule = rule;
    }

    this.#subscriptionSelected = subscriptionSelected;
    this.#initBuyNowSection();
    if (this.#hideBuyNow()) {
      return;
    }
    if (!this.#inputElement) {
      this.#inputElement = this.#createAndInsertHiddenInput();
    }

    this.#setEvents();

    this.#initialized = true;
  }

  /**
   *
   */
  #initBuyNowSection() {
    this.#buyNowSection = this.#global.utils.nearest(this.#atc.button, BUY_NOW_SECTION_SELECTOR);
  }

  /**
   *
   * @returns {boolean}
   */
  #hideBuyNow() {
    const {hideBuyNowSection} = this.#config.customization;
    const {hide} = this.#config.customization.buyNow;
    if (this.#buyNowSection && hideBuyNowSection) {
      this.#buyNowSection.style.display = 'none';
      if (hide) {
        hide();
      }
      return true;
    }

    return false;
  }

  /**
   *
   */
  #setEvents() {
    this.#removeAllEvents();

    if (this.#rule.invoice.is_enabled) {
      if (this.#subscriptionSelected) {
        this.#addBuyNowClickEvent();
        this.#togglePaymentButtonsVisibility(false);
      } else {
        this.#togglePaymentButtonsVisibility(true);
      }
    } else {
      this.#addBuyNowSectionClickEvent();
    }
  }

  /**
   *
   * @returns {HTMLInputElement}
   */
  #createAndInsertHiddenInput() {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'selling_plan');

    let closestForm = this.#atc.button.closest(CLOSEST_FORM_SELECTOR);
    if (!closestForm) {
      closestForm = document.querySelector(CLOSEST_FORM_SELECTOR);
    }

    if (closestForm) {
      closestForm.appendChild(input);
    } else {
      this.#atc.button.before(input);
    }

    return input;
  }

  /**
   *
   * @param {boolean} visibility
   */
  #togglePaymentButtonsVisibility(visibility) {
    const buyNowSection = document.querySelectorAll(BUY_NOW_SECTION_SELECTOR);
    if (visibility) {
      buyNowSection.forEach(b => b.classList.remove(HIDDEN_PAYMENT_GATEWAYS_CLASS));
    } else {
      buyNowSection.forEach(b => b.classList.add(HIDDEN_PAYMENT_GATEWAYS_CLASS));
    }
  }

  /**
   *
   */
  async #enableForCustomFrequency() {
    const planId = await this.#atc.prepareNewSellingPlan(this.#rule, this.#atc.customFrequency);
    if (planId) {
      this.#enableHiddenInput(planId);
    } else {
      throw new Error('New selling plan is not found');
    }
  }

  /**
   *
   * @param {string} planId
   */
  async #enableForFixedFrequency(planId) {
    await this.#atc.prepareSellingPlan(this.#rule);
    this.#enableHiddenInput(planId);
  }

  /**
   *
   * @param {string} sellingPlanId
   */
  #enableHiddenInput(sellingPlanId) {
    if (this.#inputElement) {
      this.#inputElement.value = sellingPlanId;
    }
  }

  /**
   *
   */
  #disableHiddenInput() {
    if (this.#inputElement) {
      this.#inputElement.value = '';
    }
  }

  /**
   *
   */
  #addBuyNowSectionClickEvent() {
    if (!this.#buyNowSection) {
      console.warn('Buy now section is not found');
      return;
    }

    this.#buyNowSection.addEventListener('click', this.#onBuyNowSectionClick, true);
  }

  /**
   *
   */
  #removeAllEvents() {
    if (this.#buyNowSection) {
      this.#buyNowSection.removeEventListener('click', this.#onBuyNowSectionClick, true);
    }

    document.removeEventListener('click', this.#onBuyNowClick, true);
  }

  /**
   *
   */
  #addBuyNowClickEvent() {
    document.addEventListener('click', this.#onBuyNowClick, true);
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async #toggleHiddenInput() {
    const {sellingPlanElement} = this.#atc;
    if (!sellingPlanElement || sellingPlanElement.disabled) {
      this.#disableHiddenInput();
      return;
    }

    const planId = sellingPlanElement.value;
    if (planId === CUSTOM_SELLING_PLAN) {
      await this.#enableForCustomFrequency();
    } else {
      await this.#enableForFixedFrequency(planId);
    }
  }

  /**
   *
   * @param {Event} e
   */
  #onBuyNowClick = (e) => {
    if (
      e.target.matches(this.#selectors.currentBuyNowSelector)
      || e.target.matches(MORE_OPTIONS_BTN_SELECTOR)
    ) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      this.#global.atc.lineItemByTargetElement(this.#atc.button, async item => {
        const cartData = this.#cartAdapter.getForDraftOrder();

        const frequency = this.#atc.getSelectedFrequencyName(this.#rule);
        const sellingPlanId = await this.#atc.getSelectedSellingPlanId(this.#rule);
        const options = {
          note_attributes: [
            {
              name: `${this.#config.env.initialOrderAtributePrefix}-0`,
              value: `${this.#rule.id}-${item.id}-${sellingPlanId}-${frequency}`,
            },
          ],
          tags: this.#config.settings.general.tag,
        };

        const lineItem = {
          variant_id: item.id,
          quantity: item.quantity,
          properties: {
            [this.#config.settings.general.cart_label]: frequency,
          },
        };

        const draftOrderDiscount = this.#rule.getDraftOrderDiscount(sellingPlanId);
        if (draftOrderDiscount) {
          lineItem.applied_discount = draftOrderDiscount;
        }

        cartData.line_items = [lineItem];

        this.#global.checkout.submitCustomDraftOrder(cartData, options)
          // eslint-disable-next-line no-return-assign
          .then(result => location.href = result.draft_order.invoice_url);
      });
    }
  }

  /**
   *
   * @param {Event} e
   */
  #onBuyNowSectionClick = async (e) => {
    if (this.#buyNowSectionWasClicked) {
      return;
    }

    e.preventDefault();
    e.stopImmediatePropagation();

    const event = new Event('click');
    this.#buyNowSectionWasClicked = true;

    try {
      await this.#toggleHiddenInput();
    } catch (error) {
      this.#buyNowSectionWasClicked = false;
      console.warn(error);
      return;
    }

    setTimeout(() => e.target.dispatchEvent(event), DELAY_BEFORE_REDIRECTION);
  }
}