import {CartEvents} from './EventListener';
import resolve from '../resolve';
import {Popup} from './Popup';
import AppConfig from '../config/config';
import Api from '../components/api/Api';
import {BOX_ID_PROP} from '../containers/Box';

const OPERATION_TYPE_ADD = 'add';
const OPERATION_TYPE_REMOVE = 'remove';

export default class Actions {

  /**
   * @type {Popup}
   */
  #popup;

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Api}
   */
  #api;

  /**
   * @type {Object}
   */
  #pendingOperations = {
    [OPERATION_TYPE_ADD]: [],
    [OPERATION_TYPE_REMOVE]: [],
  };

  /**
   * @type {Object[]}
   */
  #boxItems = [];

  /**
   *
   */
  constructor () {
    this.#popup = resolve(Popup);
    this.#config = resolve(AppConfig);
    this.#api = resolve(Api);
  }

  /**
   * @returns {SettingsCustomerPortalLabelsBuildABox}
   */
  get #labels () {
    return this.#config.settings.customer_portal_labels.box;
  }

  /**
   *
   */
  init = () => {
    this.#boxItems = Spurit.globalSnippet.cart.items.filter(i => i.properties[BOX_ID_PROP]);
    this.#disableBoxItemsQuantity();
    this.#setListeners();
  };

  /**
   *
   */
  #setListeners = () => {
    window.addEventListener(CartEvents.change, e => this.#onCartUpdated(e.detail));
    window.addEventListener(CartEvents.update, e => this.#onCartUpdated(e.detail));

    document.body.addEventListener('click', e => {
      const deleteBoxButton = e.target.closest('[data-action^="delete-box_"]');
      if (deleteBoxButton) {
        this.#onDeleteBoxClick(deleteBoxButton);
      }
    });
  };

  /**
   *
   */
  #disableBoxItemsQuantity = () => {
    this.#boxItems.forEach(i => {
      const inputs = document.querySelectorAll(this.#getQuantityInputSelector(i));
      for (const input of inputs) {
        this.#disableQuantityControls(input);
      }
    });
  };

  /**
   * @param {Object} item
   * @returns {string}
   */
  #getQuantityInputSelector = item => {
    return `[name="updates[]"][data-quantity-variant-id="${item.variant_id}"]:not([disabled])`;
  }

  /**
   * @param {HTMLInputElement} input
   */
  #disableQuantityControls = input => {
    input.setAttribute('disabled', '1');
    const buttons = input.parentElement.querySelectorAll('button');
    buttons.forEach(b => b.setAttribute('disabled', '1'));
  }

  /**
   * @param {HTMLButtonElement} button
   */
  #onDeleteBoxClick = button => {
    const [, id] = button.dataset.action.split('_');
    const items = this.#getBoxItems(+id);
    this.#setPendingOperation(OPERATION_TYPE_REMOVE, items.map(i => i.variant_id));
    this.#api.shopify.removeCartItems(items).then(() => window.location.reload());
  };

  /**
   * @param {Object} detail
   */
  #onCartUpdated = detail => {
    const isAddition = !!detail.items_added?.length;
    const isDeletion = !!detail.items_removed?.length;
    if (!isAddition && !isDeletion) {
      return;
    }

    if (this.#processPendingOperations(detail)) {
      return;
    }

    const item = this.#getUpdatedItem(detail);
    if (item) {
      this.#processExistingItem(item, detail, isDeletion);
      return;
    }
    
    this.#processRemovedItem(this.#getDeletedItem(detail.items_removed[0]), detail);
  };

  /**
   * @param {Object} item
   * @param {Object} detail
   * @param {boolean} isDeletion
   */
  #processExistingItem = (item, detail, isDeletion) => {
    if (!item.properties[BOX_ID_PROP]) {
      return;
    }

    this.#onItemQuantityUpdated(detail, item, isDeletion);
  }

  /**
   * @param {Object} item
   * @param {Object} detail
   */
  #processRemovedItem = (item, detail) => {
    if (!item?.properties[BOX_ID_PROP]) {
      return;
    }

    this.#restoreItem(item).then();
    this.#showItemDeletedPopup(item);
  }

  /**
   * @param {Object} detail
   * @returns {boolean}
   */
  #processPendingOperations = detail => {
    const isAddition = !!detail.items_added?.length;
    if (isAddition) {
      if (this.#isPendingOperation(detail, OPERATION_TYPE_ADD)) {
        this.#setPendingOperation(OPERATION_TYPE_ADD);
        return true;
      }
    } else if (this.#isPendingOperation(detail, OPERATION_TYPE_REMOVE)) {
      this.#setPendingOperation(OPERATION_TYPE_REMOVE);
      return true;
    }

    return false;
  };

  /**
   * @param {Object} detail
   * @param {string} type
   * @returns {boolean}
   */
  #isPendingOperation = (detail, type) => {
    const items = detail[type === OPERATION_TYPE_ADD ? 'items_added' : 'items_removed'];
    return items.every(i => this.#pendingOperations[type].includes(i.variant_id));
  };

  /**
   * @param {string} type
   * @param {number[]} ids
   */
  #setPendingOperation = (type, ids = []) => {
    this.#pendingOperations[type] = ids;
  }

  /**
   * @param {Object} detail
   * @param {Object} item
   * @param {boolean} isDeletion
   */
  #onItemQuantityUpdated = (detail, item, isDeletion) => {
    const line = detail.items.findIndex(i => i.key === item.key);
    const quantity = item.quantity + (isDeletion ? 1 : -1);

    const operationType = quantity > item.quantity ? OPERATION_TYPE_ADD : OPERATION_TYPE_REMOVE;
    this.#setPendingOperation(operationType, [item.variant_id]);
    this.#api.shopify.setCartLineQuantity(line + 1, quantity).then();
    this.#showQuantityUpdatedPopup();
  };

  /**
   * @param {Object} item
   */
  #showItemDeletedPopup = item => {
    this.#popup
      .onClose(() => window.location.reload())
      .setActions([{
        action: `delete-box_${item.properties[BOX_ID_PROP]}`,
        content: this.#labels.delete,
        destructive: true,
      }])
      .setContent(this.#labels.remove)
      .setHeading(this.#labels.part)
      .show();
  };

  /**
   * @param {Object} item
   * @returns {Promise<Response>}
   */
  #restoreItem = item => {
    const data = {id: item.variant_id, quantity: item.quantity, properties: item.properties};
    if (item.selling_plan_allocation) {
      data.selling_plan = item.selling_plan_allocation.selling_plan.id;
    }

    this.#setPendingOperation(OPERATION_TYPE_ADD, [item.variant_id]);
    return this.#api.shopify.addToCart([data]);
  };

  /**
   *
   */
  #showQuantityUpdatedPopup = () => {
    this.#popup
      .onClose(() => window.location.reload())
      .setActions([{content: 'OK', action: 'close-popup'}])
      .setContent(this.#labels.change)
      .setHeading(this.#labels.part)
      .show();
  };

  /**
   * @param {Object} detail
   * @returns {Object|null}
   */
  #getUpdatedItem = detail => {
    let item = null;
    if (detail.items_added?.length) {
      [item] = detail.items_added;
    } else if (detail.items_removed?.length) {
      [item] = detail.items_removed;
    }

    return detail.items.find(i => i.properties[BOX_ID_PROP] && i.variant_id === item.variant_id);
  };

  /**
   * @param {Object} item
   * @returns {Object|null}
   */
  #getDeletedItem = item => this.#boxItems.find(i => i.variant_id === item?.variant_id);

  /**
   * @param {number} id
   * @returns {Object[]}
   */
  #getBoxItems = id => this.#boxItems.filter(i => +i.properties[BOX_ID_PROP] === id);
}