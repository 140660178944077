import ApiBase from './ApiBase';

const ROUTES = {
  BOXES: {
    GET: '/boxes',
    UPDATE: '/boxes',
    DELETE: '/boxes',
    QUANTITY: '/boxes/quantity',
    PRODUCTS: '/boxes/products',
  },
};

export default class BoxApi extends ApiBase {

  /**
   * @param {number} id
   * @return {Promise<Response>}
   */
  get = id => this.makeCommonGetRequest(ROUTES.BOXES.GET, {id});

  /**
   * @param {number} id
   * @param {Object} box
   * @returns {Promise<Response>}
   */
  update = (id, box) => {
    return fetch(this.makeUrl(ROUTES.BOXES.UPDATE), this.makePutData({...this.authParams, id, box}));
  };

  /**
   * @param {number} id
   * @param {number} quantity
   * @returns {Promise<Response>}
   */
  setQuantity = (id, quantity) => {
    return fetch(this.makeUrl(ROUTES.BOXES.QUANTITY), this.makePutData({...this.authParams, id, quantity}));
  }

  /**
   * @param {number} id
   * @returns {Promise<Response>}
   */
  delete = id => fetch(this.makeUrl(ROUTES.BOXES.DELETE), this.makeDeleteData({id, ...this.authParams}));

  /**
   * @param {number} id
   * @returns {Promise<Response>}
   */
  products = id => {
    return fetch(
      this.makeUrl(ROUTES.BOXES.PRODUCTS, {}, {shop_id: +this.config.snippet.shopId, id}),
      {headers: this.requestHeaders(true)},
    ).then(response => {
      return response.ok ? response.json() : Promise.reject(response);
    });
  }
}