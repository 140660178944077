import AbstractParent from './AbstractParent';

export default class CartUpsellTextsWidget extends AbstractParent {

  /**
   * @type {string}
   */
  label_with_discount = 'Subscribe & save {{discount}}';

  /**
   * @type {string}
   */
  label_without_discount = 'Subscribe for auto-deliveries';

  /**
   * @type {string}
   */
  new_price = 'New price:';

  /**
   * @type {string}
   */
  update = 'Update item';

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}