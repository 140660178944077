/**
 * @param variable
 * @returns {boolean}
 */
export function isObject (variable) {
  return typeof variable === 'object' && variable !== null;
}

/**
 *
 * @param {Object} variable
 * @returns {Object}
 */
export function cloneDeep (variable) {
  if (!isObject(variable)) {
    return variable;
  }

  return Object.entries(variable).reduce((result, [key, value]) => {
    result[key] = cloneDeep(value);
    return result;
  }, Array.isArray(variable) ? [] : {});
}

/**
 * @param {Object} object
 * @param {Object} source
 * @returns {Object}
 */
export function defaultsDeep (object, source) {
  if (!isObject(source) || !isObject(object)) {
    return source;
  }

  const result = Array.isArray(source) ? [] : {};

  Object.entries(source).forEach(([key, sourceVal]) => {
    if (typeof object[key] === 'undefined') {
      result[key] = sourceVal;
      return;
    }

    const objectVal = object[key];

    if (!isObject(sourceVal) || !isObject(objectVal)) {
      result[key] = objectVal;
      return;
    }

    result[key] = defaultsDeep(objectVal, sourceVal);
  });

  Object.entries(object).forEach(([key, objectVal]) => {
    if (typeof source[key] === 'undefined') {
      result[key] = objectVal;
      return;
    }

    result[key] = defaultsDeep(objectVal, result[key]);
  });

  return result;
}

/**
 * @param {Discount|null} discount1
 * @param {Discount|null} discount2
 * @returns {boolean}
 */
export function compareDiscountValues (discount1, discount2) {
  if (!(+discount1?.value)) {
    discount1 = null;
  }

  if (!(+discount2?.value)) {
    discount2 = null;
  }

  if (!discount1 && !discount2) {
    return true;
  }

  return discount1?.type === discount2?.type && +discount1?.value === +discount2?.value;
}

/**
 * @param {Discount|null} discount1
 * @param {Discount|null} discount2
 * @returns {boolean}
 */
export function compareDiscounts (discount1, discount2) {
  if (!discount1 && !discount2) {
    return true;
  }

  return +discount1?.after_cycle === +discount2?.after_cycle ? compareDiscountValues(discount1, discount2) : false;
}

/**
 * @param {Object[]} items
 * @param {string} field
 * @param {boolean} asc
 */
export function sort (items, field, asc = true) {
  items.sort((a, b) => {
    return asc ? a[field] - b[field] : b[field] - a[field];
  });
}

/**
 * @param {Object[]} items
 * @returns {number}
 */
export function countProducts (items) {
  return items.reduce((sum, i) => sum + +i.quantity, 0);
}

/**
 * @param {HTMLElement} elem
 * @param {boolean} visible
 */
export function toggleElement (elem, visible) {
  elem.style.display = visible ? '' : 'none';
}