//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsSubscriptions extends AbstractParent {


  /**
   * @type  {string}
   */
  all = ___('All');

  /**
   * @type  {string}
   */
  active = ___('Active');

  /**
   * @type  {string}
   */
  failed = ___('Failed');

  /**
   * @type  {string}
   */
  paused = ___('Paused');

  /**
   * @type  {string}
   */
  cancelled = ___('Cancelled');

  /**
   * @type  {string}
   */
  subscription = ___('Subscription');

  /**
   * @type  {string}
   */
  products = ___('Products');

  /**
   * @type  {string}
   */
  next = ___('Next order/invoice');

  /**
   * @type  {string}
   */
  status = ___('Status');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}