import resolve from "../resolve";
import AppConfig from "../config/config";

/**
 *
 * @type {string}
 */
export const SELLING_PLAN_SELECT_SELECTOR = 'select.spurit-ros__frequency_select';

/**
 *
 * @type {string}
 */
export const SELLING_PLAN_CHECKBOX_SELECTOR = 'input.spurit-ros__frequency-option';

/**
 *
 * @type {string}
 */
export const CUSTOM_SELLING_PLAN_SELECTOR = `${SELLING_PLAN_CHECKBOX_SELECTOR}[value="custom"]`;

/**
 *
 * @type {string}
 */
export const SELECTED_SELLING_PLAN_SELECTOR = `${SELLING_PLAN_SELECT_SELECTOR}, ${SELLING_PLAN_CHECKBOX_SELECTOR}:checked`;

/**
 *
 * @type {string}
 */
export const CUSTOM_FREQUENCY_NUMBER_SELECTOR = 'input.spurit-ros__custom-frequency-number';

/**
 *
 * @type {string}
 */
export const CUSTOM_FREQUENCY_UNIT_SELECTOR = 'select.spurit-ros__custom-frequency-unit';

/**
 *
 * @type {string}
 */
export const SUBSCRIBE_OPTION_SELECTOR = 'input.spurit-ros__subscribe-option';


/**
 *
 * @type {string}
 */
export const BUY_NOW_SELECTOR = 'form[action="/cart/add"] .shopify-payment-button__button';

/**
 *
 * @type {string}
 */
export const NOTES_FOR_CUSTOMER_SELECTOR = 'div.spurit-ros__notes-for-customer';

/**
 *
 * @type {string}
 */
export const THEME_APP_BLOCK_SELECTOR = '#spurit-subscription-customize-widget';

/**
 *
 * @type {string}
 */
export const POSITION_BEFORE = 'before';

const MOBILE_BREAKPOINT = 750;

/**
 *
 */
export default class Selectors {

  /**
   *
   * @type {string}
   */
  static PRODUCT_WIDGET_SELECTOR = 'product_widget';

  /**
   *
   * @type {string}
   */
  static PRICE_INDIVIDUAL_SELECTOR = 'product_price_individual';

  /**
   *
   * @type {string}
   */
  static FEATURED_PRODUCT_WIDGET_SELECTOR = 'featured_product_widget';

  /**
   *
   * @type {string}
   */
  static MANAGE_SUBSCRIPTIONS_BUTTON_SELECTOR = 'manage_subscriptions_button';

  /**
   *
   * @type {string}
   */
  static QUICK_VIEW_WIDGET_SELECTOR = 'quick_view_widget';

  /**
   *
   * @type {string}
   */
  static BUY_NOW_SELECTOR = 'button_buy_now';

  /**
   *
   * @type {array}
   */
  static WIDGET_USED_PAGES = ['index', 'products', 'collections', 'pages'];

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Object}
   */
  #global;

  /**
   * @type {string}
   */
  currentBuyNowSelector

  /**
   *
   */
  constructor (page = null) {
    this.#config = resolve(AppConfig);
    this.#global = resolve('global');
    this.init(page);
  }

  /**
   * @returns {Object|null}
   */
  get cartUpsellSelector () {
    const {selectors} = this.#config.settings.general;
    if (this.isMobile) {
      return selectors.upsell_widget_placement_mobile?.selector
        ? selectors.upsell_widget_placement_mobile
        : selectors.upsell_widget_placement_desktop;
    }

    return selectors.upsell_widget_placement_desktop?.selector
      ? selectors.upsell_widget_placement_desktop
      : selectors.upsell_widget_placement_mobile;
  }

  /**
   * @returns {string|null}
   */
  get cartUpsellPosition () {
    return this.transformSelectorPosition(this.cartUpsellSelector?.position);
  }

  /**
   * @returns {boolean}
   */
  get isMobile () {
    return document.documentElement.clientWidth < MOBILE_BREAKPOINT;
  }

  /**
   *
   */
  init (page) {
    const {selectors} = this.#config.settings.general;
    const {SELECTOR} = this.#global.constants;
    const module = this.#global.selectors;

    if (Selectors.WIDGET_USED_PAGES.includes(page)) {
      module.add(selectors.widget_placement.selector, Selectors.PRODUCT_WIDGET_SELECTOR);
      module.add(selectors.button_atc_product.selector, SELECTOR.PRODUCT_ADD_TO_CART);
    }
    if (selectors.button_atc_collection && selectors.button_atc_collection.selector) {
      module.add(selectors.button_atc_collection.selector, SELECTOR.COLLECTION_ADD_TO_CART);
    }
    if (selectors.price_individual && selectors.price_individual.selector) {
      module.add(selectors.price_individual.selector, Selectors.PRICE_INDIVIDUAL_SELECTOR);
    }
    if (selectors.featured_product_widget && selectors.featured_product_widget.selector) {
      module.add(selectors.featured_product_widget.selector, Selectors.FEATURED_PRODUCT_WIDGET_SELECTOR);
    }
    if (selectors.manage_subscriptions_button && selectors.manage_subscriptions_button.selector) {
      module.add(selectors.manage_subscriptions_button.selector, Selectors.MANAGE_SUBSCRIPTIONS_BUTTON_SELECTOR);
    }
    if (selectors.quick_view_widget && selectors.quick_view_widget.selector) {
      module.add(selectors.quick_view_widget.selector, Selectors.QUICK_VIEW_WIDGET_SELECTOR);
    }
    if (selectors.cart_price_subtotal && selectors.cart_price_subtotal.selector) {
      module.add(selectors.cart_price_subtotal.selector, SELECTOR.CART_SUBTOTAL);
    }
    if (selectors.is_ajax) {
      module.add(selectors.ajax_checkout_button.selector, SELECTOR.CART_CHECKOUT);

      if (selectors.ajax_cart_price_subtotal && selectors.ajax_cart_price_subtotal.selector) {
        module.add(selectors.ajax_cart_price_subtotal.selector, SELECTOR.CART_SUBTOTAL);
      }
    }

    let buyNowSelector;
    if (selectors.button_buy_now && selectors.button_buy_now.selector) {
      buyNowSelector = selectors.button_buy_now.selector;
    } else {
      buyNowSelector = this.#config.customization.buyNowSelector || BUY_NOW_SELECTOR;
    }
    this.currentBuyNowSelector = buyNowSelector;
    module.add(buyNowSelector, Selectors.BUY_NOW_SELECTOR);
  }

  /**
   * @param {string} position
   * @returns {null|string}
   */
  transformSelectorPosition = position => {
    if (position === 'after') {
      return 'afterend';
    }

    if (position === 'before') {
      return 'beforebegin';
    }

    return null;
  }

  /**
   * @param {string} type
   * @returns {string}
   */
  get = type => this.#global.selectors.get(type);

  /**
   * @returns {HTMLElement[]}
   */
  getProductPageAtcButtons = () => this.getElements(this.#global.constants.SELECTOR.PRODUCT_ADD_TO_CART);

  /**
   * @returns {HTMLElement[]}
   */
  getBuyNowButtons = () => this.getElements(Selectors.BUY_NOW_SELECTOR);

  /**
   * @param {string} type
   * @returns {HTMLElement[]}
   */
  getElements = type => this.#global.selectors.getElements(type);

  /**
   * @returns {string}
   */
  getSingleProductWidgetSelector () {
    const selectors = [];

    const productSelector = this.get(Selectors.PRODUCT_WIDGET_SELECTOR);
    if (productSelector.length) {
      selectors.push(productSelector[0]);
    }

    const featuredSelector = this.get(Selectors.FEATURED_PRODUCT_WIDGET_SELECTOR);
    if (featuredSelector.length) {
      selectors.push(featuredSelector[0]);
    }

    return selectors.join(', ');
  }

  /**
   * @param {HTMLElement} container
   * @param {string} type
   * @returns {HTMLCollection|null}
   */
  getElementsInContainer (container, type) {
    const selectors = this.get(type);
    return selectors.length ? container.querySelectorAll(selectors[0]) : null;
  }

  /**
   * @param {HTMLElement} container
   * @param {string} type
   * @returns {HTMLElement|null}
   */
  getSingleElementInContainer (container, type) {
    return this.getElementsInContainer(container, type)[0] || null;
  }

  /**
   * @param {HTMLElement} container
   * @returns {HTMLElement|null}
   */
  getAtcInContainer (container) {
    return this.getSingleElementInContainer(container, this.#global.constants.SELECTOR.PRODUCT_ADD_TO_CART);
  }

  /**
   * @returns {string|null}
   */
  getQuickViewWidgetSelector () {
    return this.get(Selectors.QUICK_VIEW_WIDGET_SELECTOR)[0] || null;
  }

  /**
   * @returns {HTMLElement[]}
   */
  getProductFormWidgetTargets () {
    const targets = this.getThemeAppBlocks();
    if (targets.length || !this.getSingleProductWidgetSelector()) {
      return targets;
    }

    return [...document.querySelectorAll(this.getSingleProductWidgetSelector())];
  }

  /**
   * @returns {HTMLElement[]}
   */
  getThemeAppBlocks() {
    return [...document.querySelectorAll(THEME_APP_BLOCK_SELECTOR)];
  }

  /**
   *
   * @return {HTMLElement[]}
   */
  getQuickViewWidgetTargets () {
    return this.getElements(Selectors.QUICK_VIEW_WIDGET_SELECTOR);
  }

  /**
   *
   * @param {HTMLElement} target
   * @returns {string|null}
   */
  getWidgetPosition (target) {
    const {selectors} = this.#config.settings.general;
    if (selectors.widget_placement.selector && target.matches(selectors.widget_placement.selector)) {
      return selectors.widget_placement.position;
    }

    if (selectors.featured_product_widget.selector && target.matches(selectors.featured_product_widget.selector)) {
      return selectors.featured_product_widget.position;
    }

    if (selectors.quick_view_widget.selector && target.matches(selectors.quick_view_widget.selector)) {
      return selectors.quick_view_widget.position;
    }

    return null;
  }

  /**
   * 
   * @returns {{element: HTMLElement, position: string}}
   */
   getManageSubscriptionsButtonPosition() {
    const { selectors } = this.#config.settings.general;
    return {
      element: this.getElements(Selectors.MANAGE_SUBSCRIPTIONS_BUTTON_SELECTOR)[0] || null,
      position: selectors.manage_subscriptions_button?.position || POSITION_BEFORE,
    };
  }

  /**
   *
   * @param {function} onAppear
   */
  onQuickViewAppear (onAppear) {
    this.#global.selectors.onElementsAppear(Selectors.QUICK_VIEW_WIDGET_SELECTOR, onAppear);
  }
}


