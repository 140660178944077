/* eslint-disable max-lines */
import Mustache from 'mustache';
import modalTemplate from './templates/modal.html';
import listTemplate from './templates/list.html';
import emptyMessageTemplate from './templates/empty.html';
import no_image from './templates/noimage.html';
import Api, { PER_PAGE } from './Api';

const WRAPPER_CLASS = 'product-selector__wrapper';
const PARTIALLY_CHECKED_CLASS = 'partial';

const BODY_SELECTOR = '.product-selector__body';
const PRODUCTS_LIST_SELECTOR = '.product-selector__products-list';
const VARIANTS_LIST_SELECTOR = '.product-selector__variants-list';
const LOADER_SELECTOR = '.product-selector__loader';
const SEARCH_FIELD_SELECTOR = '.product-selector__search-input input';

const NOT_CHECKED_VARIANTS_SELECTOR = `${VARIANTS_LIST_SELECTOR} input[type="checkbox"]:not(:checked)`;
const CHECKED_VARIANTS_SELECTOR = `${VARIANTS_LIST_SELECTOR} input[type="checkbox"]:checked:not(:disabled)`;

const ACTIONS = {
  CLOSE: 'close-product-selector',
  SAVE: 'apply-products-selection',
};

const SEARCH_TIMEOUT = 1000;

export default class ProductSelector {

  /**
   * @type {number[]}
   */
  disabledItems = [];

  /**
   * @type {number[]}
   */
  visibleItems = [];

  /**
   * @type {boolean}
   */
  multiple = true;

  /**
   * @type {boolean}
   */
  #loading = false;

  /**
   * @type {boolean}
   */
  #visible = false;

  /**
   * @type {HTMLElement}
   */
  #container;

  /**
   * @type {boolean}
   */
  #nextPageLoading = false;

  /**
   * @type {number}
   */
  #currentPage = 1;

  /**
   * @type {boolean}
   */
  #allProductsLoaded = false;

  /**
   * @type {Object[]}
   */
  #products = [];

  /**
   * @type {string|null}
   */
  #search = null;

  /**
   *
   */
  #searchIdentifier;

  /**
   * @type {boolean}
   */
  #wrapperClicked = false;

  /**
   * @type {Object}
   */
  #texts = {
    title: 'Select products',
    cancel: 'CANCEL',
    save: 'SAVE',
    no_products: 'Product not found',
    no_available_products: 'No available products',
  };

  /**
   *
   */
  constructor () {
    this.#setContainer();
    this.#setListeners();
  }

  /**
   * @param {Object[]} items
   * @returns {null}
   */
  onItemsSelected = items => null;

  /**
   * @param {Object} texts
   */
  setTexts = texts => {
    this.#texts = {...this.#texts, ...texts};
  }

  /**
   *
   */
  show = () => {
    this.makeVisible();
    if (this.#products.length) {
      this.showProducts();
      return;
    }

    this.showLoading();
    if (this.#loading) {
      return;
    }

    this.#loadAndShow();
  };

  /**
   *
   */
  showLoading = () => {
    this.#container.innerHTML = Mustache.render(modalTemplate, {
      search_value: this.#search,
      save_disabled: true,
      texts: this.#texts,
      loading: true,
    });
  };

  /**
   *
   */
  makeVisible () {
    this.#container.style.display = 'flex';
    this.#visible = true;
  }

  /**
   *
   */
  disableSaveButton = () => {
    const btn = this.#container.querySelector(`button[data-action="${ACTIONS.SAVE}"]`);
    btn.setAttribute('disabled', true);
    btn.classList.add('loading');
  };

  /**
   *
   */
  showProducts = () => {
    if (!this.#visible) {
      return;
    }

    const list = this.#getProductsList(this.#products);
    this.#container.innerHTML = Mustache.render(modalTemplate, {
      save_disabled: !this.multiple,
      search_value: this.#search,
      texts: this.#texts,
      list: list,
    });
    this.#setScrollListener();
    this.#afterProductPageShown();
  };

  /**
   * @param {Object[]} products
   */
  appendProducts = products => {
    this.#container.querySelector(PRODUCTS_LIST_SELECTOR)
      .insertAdjacentHTML('beforeend', this.#getProductsList(products));
    this.#afterProductPageShown();
  };

  /**
   *
   */
  hide = () => {
    this.#container.style.display = 'none';
    this.#visible = false;
  };

  /**
   * @param {number[]} ids
   */
  addDisabledItems = ids => {
    this.disabledItems = [...this.disabledItems, ...ids];
    this.resetLoadedItems();
  };

  /**
   * @param {number[]} ids
   */
  removeDisabledItems = ids => {
    this.disabledItems = this.disabledItems.filter(id => !ids.includes(id));
    this.resetLoadedItems();
  }

  /**
   * @param {boolean} loading
   */
  setLoading = loading => {
    this.#loading = loading;
    if (this.#visible) {
      this.show();
    }
  };

  /**
   *
   */
  resetLoadedItems = () => {
    this.#allProductsLoaded = false;
    this.#currentPage = 1;
    this.#products = [];
  }

  /**
   * @returns {boolean}
   */
  get #isLoaderVisible () {
    if (!this.#visible) {
      return true;
    }

    const body = this.#container.querySelector(BODY_SELECTOR);
    const loader = body.querySelector(LOADER_SELECTOR);
    return loader && loader.offsetTop < body.scrollTop + body.clientHeight;
  }

  /**
   * @returns {Object[]}
   */
  get #selectedItems () {
    return Array.from(this.#container.querySelectorAll(CHECKED_VARIANTS_SELECTOR)).map(c => {
      const id = +c.dataset.id;
      const product = this.#findProductByVariantCheckbox(c);
      const variant = product.variants.find(v => v.id === id);

      return {id, product: {title: product.title}, variant: {title: variant.title}};
    });
  }

  /**
   * @returns {boolean}
   */
  get #isSelectionAllowed () {
    return this.multiple || !this.#container.querySelector(CHECKED_VARIANTS_SELECTOR);
  }

  /**
   * @returns {Object[]}
   */
  get #availableVariants () {
    return this.#products.reduce((variants, p) => {
      return [...variants, ...p.variants.filter(v => !v.disabled)];
    }, []);
  }

  /**
   *
   */
  #loadAndShow = () => {
    this.#loadProductsPage().then(this.showProducts);
  }

  /**
   *
   */
  #loadAndAppend = () => {
    this.#loadProductsPage().then(this.appendProducts);
  }

  /**
   * @param {HTMLInputElement} checkbox
   * @returns {Object|null}
   */
  #findProductByVariantCheckbox = checkbox => {
    const productCheckbox = checkbox.closest('ul').previousElementSibling.querySelector('input');
    const pid = +productCheckbox.dataset.id;

    return this.#products.find(p => p.id === pid);
  }

  /**
   *
   */
  #onSaveClick () {
    const items = this.#selectedItems;
    if (!items.length) {
      this.hide();
      return;
    }

    this.disableSaveButton();
    this.onItemsSelected(items);
  }

  /**
   *
   */
  #getProductsList = products => Mustache.render(listTemplate, { products, no_image });

  /**
   * @returns {Promise<any>}
   */
  #loadProductsPage = () => {
    this.#nextPageLoading = true;

    return Api.getProductsPage(this.#currentPage).then(products => {
      const visibleProducts = this.#formatProducts(this.#filterProducts(products));
      this.#products = [...this.#products, ...visibleProducts];
      if (products.length < PER_PAGE) {
        this.#allProductsLoaded = true;
      }

      this.#nextPageLoading = false;
      return visibleProducts;
    });
  };

  /**
   *
   */
  #setContainer = () => {
    this.#container = document.createElement('div');
    this.#container.className = WRAPPER_CLASS;
    document.body.appendChild(this.#container);
  };

  /**
   *
   */
  #setListeners = () => {
    if (!this.#container) {
      throw new Error('Unable to set listeners without container mounted');
    }

    this.#container.addEventListener('mousedown', e => {
      if (e.target.className === WRAPPER_CLASS) {
        this.#wrapperClicked = true;
      }
    });

    this.#container.addEventListener('mouseup', e => {
      if (e.target.className === WRAPPER_CLASS && this.#wrapperClicked) {
        this.hide();
      }

      this.#wrapperClicked = false;
    });

    this.#container.addEventListener('click', e => {
      if (e.target.closest(`[data-action="${ACTIONS.CLOSE}"]`)) {
        this.hide();
        return;
      }

      if (e.target.dataset.action === ACTIONS.SAVE) {
        this.#onSaveClick();
      }
    });

    this.#container.addEventListener('change', e => {
      if (e.target.tagName === 'INPUT' && e.target.getAttribute('type') === 'checkbox') {
        this.#onCheckboxClick(e.target);
      }
    });

    this.#container.addEventListener('input', e => {
      const input = e.target.closest(SEARCH_FIELD_SELECTOR);
      if (input) {
        this.#onSearchUpdate(input.value);
      }
    });
  };

  /**
   *
   */
  #setScrollListener = () => {
    this.#container.querySelector(BODY_SELECTOR).addEventListener('scroll', e => {
      if (e.target.className === 'product-selector__body') {
        this.#onBodyScroll();
      }
    });
  };

  /**
   * @param {string} search
   */
  #onSearchUpdate = search => {
    clearTimeout(this.#searchIdentifier);

    this.#searchIdentifier = setTimeout(() => {
      this.#setSearch(search);
      this.showLoading();
      this.resetLoadedItems();
      this.#loadAndShow();
    }, SEARCH_TIMEOUT);
  };

  /**
   * @param {string} search
   */
  #setSearch = search => {
    this.#search = search || null;
  }

  /**
   * @param {HTMLInputElement} checkbox
   */
  #onCheckboxClick = checkbox => {
    const variantsList = checkbox.closest(VARIANTS_LIST_SELECTOR);
    if (variantsList) {
      this.#onVariantToggle(checkbox);
      return;
    }

    this.#onProductToggle(checkbox);
  };

  /**
   * @param {HTMLInputElement} checkbox
   */
  #onProductToggle = checkbox => {
    const li = checkbox.closest('li');
    const checkboxes = Array.from(li.querySelectorAll(`${VARIANTS_LIST_SELECTOR} input[type="checkbox"]`));
    checkboxes.forEach(c => {
      c.checked = checkbox.checked;
    });

    this.#setPartialCheckboxState(checkbox, false);
  };

  /**
   * @param {HTMLInputElement} checkbox
   */
  #onVariantToggle = checkbox => {
    if (this.multiple) {
      this.#onVariantToggleMultiple(checkbox);
    } else {
      this.#onVariantToggleSingle(checkbox);
    }
  };

  /**
   * @param {HTMLInputElement} checkbox
   */
  #onVariantToggleSingle = checkbox => {
    const saveButton = this.#container.querySelector('[data-action="apply-products-selection"]');
    if (checkbox.checked) {
      saveButton.removeAttribute('disabled');
      this.#disableNotCheckedVariants();
    } else {
      saveButton.setAttribute('disabled', 'true');
      this.#enableNotCheckedVariants();
    }
  }

  /**
   *
   */
  #disableNotCheckedVariants = () => {
    Array.from(this.#container.querySelectorAll(NOT_CHECKED_VARIANTS_SELECTOR)).forEach(c => {
      c.setAttribute('disabled', true);
    });
  }

  /**
   *
   */
  #enableNotCheckedVariants = () => {
    Array.from(this.#container.querySelectorAll(NOT_CHECKED_VARIANTS_SELECTOR)).forEach(c => {
      if (!this.disabledItems.includes(+c.dataset.id)) {
        c.removeAttribute('disabled');
      }
    });
  }

  /**
   * @param {HTMLInputElement} checkbox
   */
  #onVariantToggleMultiple = checkbox => {
    const variantsList = checkbox.closest(VARIANTS_LIST_SELECTOR);
    const checkboxes = Array.from(variantsList.querySelectorAll('input[type="checkbox"]'));
    const totalVariantsCount = checkboxes.length;
    const selectedVariantsCount = checkboxes.filter(c => c.checked).length;

    const productCheckbox = variantsList.closest('li').querySelector('input[type="checkbox"]');
    productCheckbox.checked = selectedVariantsCount === totalVariantsCount;

    this.#setPartialCheckboxState(
      productCheckbox,
      selectedVariantsCount && selectedVariantsCount < totalVariantsCount,
    );
  }

  /**
   *
   */
  #onBodyScroll = () => {
    if (this.#allProductsLoaded || this.#nextPageLoading || !this.#isLoaderVisible) {
      return;
    }

    this.#currentPage++;
    this.#loadAndAppend();
  };

  /**
   *
   */
  #afterProductPageShown = () => {
    if (this.#allProductsLoaded) {
      this.#removeLoader();
      if (!this.#availableVariants.length) {
        this.#showEmptyMessage();
      }
    }

    if (this.#isLoaderVisible) {
      this.#onBodyScroll();
    }
  };

  /**
   *
   */
  #removeLoader = () => this.#container.querySelector(LOADER_SELECTOR).remove();

  /**
   *
   */
  #showEmptyMessage = () => {
    this.#container.querySelector(BODY_SELECTOR).innerHTML = Mustache.render(emptyMessageTemplate, {
      texts: this.#texts,
      search: this.#search,
    });
  }

  /**
   * @param {HTMLInputElement} checkbox
   * @param {boolean} checked
   */
  #setPartialCheckboxState = (checkbox, checked) => {
    if (checked) {
      checkbox.nextElementSibling.classList.add(PARTIALLY_CHECKED_CLASS);
    } else {
      checkbox.nextElementSibling.classList.remove(PARTIALLY_CHECKED_CLASS);
    }
  };

  /**
   * @param {Object[]} products
   * @returns {Object[]}
   */
  #filterProducts = products => {
    if (this.#search) {
      products = products.filter(p => p.title.includes(this.#search));
    }

    if (this.visibleItems) {
      products = products.filter(p => !!this.visibleItems.find(item => item.pid === p.id));
    }

    return products.map(p => ({...p, variants: this.#getFilteredProductVariants(p)})).filter(p => p.variants.length);
  };

  /**
   * @param {Object} product
   * @returns {Object[]}
   */
  #getFilteredProductVariants = product => {
    const variants = product.variants.filter(v => v.available);
    if (!this.visibleItems) {
      return variants;
    }

    if (this.visibleItems.some(item => item.pid === product.id && !item.vid)) {
      return variants;
    }

    return variants.filter(v => this.visibleItems.some(item => item.vid === v.id));
  };

  /**
   * @param {Object[]} products
   * @returns {Object[]}
   */
  #formatProducts = products => {
    return products.map(p => {
      const variants = this.#formatVariants(p.variants);
      const disabled = !this.multiple || !variants.some(v => !v.disabled);

      return {...p, image: p.images[0]?.src, disabled, variants};
    });
  };

  /**
   * @param {Object[]} variants
   */
  #formatVariants = variants => variants.map(v => {
    return { ...v, disabled: this.disabledItems.includes(v.id) || !this.#isSelectionAllowed};
  });
}