import resolve from '../../resolve';
import {CANCELLED} from '../../classes/SubscriptionContract';
import AppConfig from '../../config/config';

export class Permissions {

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   *
   */
  constructor () {
    this.#config = resolve(AppConfig);
  }

  /**
   * @return {boolean}
   */
  get canChangeFrequency () {
    return this.#settings.is_frequency_changeable;
  }

  /**
   * @return {boolean}
   */
  get canChangeNextOrderDate () {
    return this.#settings.is_next_order_date_changeable;
  };

  /**
   * @return {boolean}
   */
  get canChangeFulfillmentDate () {
    return this.#settings.is_delivery_date_changeable;
  }

  /**
   * @return {boolean}
   */
  get canSkipPayment () {
    return this.#settings.is_payment_skipable;
  }

  /**
   * @return {boolean}
   */
  get canMakeAdditionalOrder () {
    return this.#settings.is_additional_order_allowed;
  }

  /**
   * @return {boolean}
   */
  get canPause () {
    return this.#settings.is_pausable;
  }

  /**
   * @return {boolean}
   */
  get canResume () {
    return this.canPause;
  }

  /**
   * @return {boolean}
   */
  get canCancel () {
    return this.#settings.is_cancellable;
  }

  /**
   * @param {SubscriptionContract} contract
   * @return {boolean}
   */
  canAddProduct = contract => this.#settings.is_product_addition_allowed && contract.status !== CANCELLED;

  /**
   * @param {SubscriptionContract} contract
   * @return {boolean}
   */
  canAddOneTimePurchase = contract => {
    return this.#settings.is_one_purchase_products_allowed && !contract.isPrepaid && contract.status !== CANCELLED;
  };

  /**
   * @param {SubscriptionContract} contract
   * @param {string} lineId
   * @return {boolean}
   */
  canSwapProduct = (contract, lineId) => {
    const product = contract.subscription_contract_products.find(p => p.line_id === lineId);
    return this.isSwapAllowedForContract(contract) && !product.one_time_purchase;
  };

  /**
   * @param {SubscriptionContract} contract
   * @return {boolean}
   */
  canSwapSomeItems = contract => {
    const canSwap = this.isSwapAllowedForContract(contract);
    return canSwap && contract.subscription_contract_products.some(p => !p.one_time_purchase) || contract.boxes.length;
  };

  /**
   * @param {SubscriptionContract} contract
   * @returns {boolean}
   */
  isSwapAllowedForContract = contract => this.#settings.is_product_swap_allowed && contract.status !== CANCELLED;

  /**
   * @param {SubscriptionContract} contract
   * @param {string} lineId
   * @return {false|boolean|string|*}
   */
  canRemoveProduct = (contract, lineId) => {
    const product = contract.subscription_contract_products.find(p => p.line_id === lineId);
    const isNotCancelled = contract.status !== CANCELLED;
    const canAddProduct = this.canAddProduct(contract);
    const hasManyItems = this.#hasManyItems(contract);

    return isNotCancelled && ((canAddProduct && hasManyItems) || product.one_time_purchase);
  };

  /**
   * @param {SubscriptionContract} contract
   * @returns {boolean}
   */
  canRemoveBox = contract => {
    return contract.status !== CANCELLED && this.canAddProduct(contract) && this.#hasManyItems(contract);
  }

  /**
   * @param {Object} contract
   * @returns {boolean}
   */
  canEditProductQuantity = contract => contract.status !== CANCELLED && this.#settings.is_product_qty_editable;

  /**
   * @return {SettingsSubscription}
   */
  get #settings () {
    return this.#config.settings.subscription;
  }

  /**
   * @param {SubscriptionContract} contract
   * @returns {boolean}
   */
  #hasManyItems = contract => {
    return contract.subscription_contract_products.filter(p => !p.one_time_purchase).length + contract.boxes.length > 1;
  }
}
