import AbstractParent from './AbstractParent';

export default class SettingsCustomerPortalLabelsSubscription extends AbstractParent {


  /**
   * @type  {string}
   */
  products = ___('Products');

  /**
   * @type  {string}
   */
  initial_price = `${___('Initial price')}:`;

  /**
   * @type  {string}
   */
  price_after_payments = `${___('Price after {{number_of_payments}} payment(s)')}:`;

  /**
   * @type  {string}
   */
  price = ___('Price');

  /**
   * @type  {string}
   */
  quantity = ___('Quantity');

  /**
   * @type  {string}
   */
  cancel = ___('Cancel');

  /**
   * @type  {string}
   */
  save = ___('Save');

  /**
   * @type  {string}
   */
  success = ___('Product quantity has been saved');

  /**
   * @type  {string}
   */
  total = ___('Total');

  /**
   * @type  {string}
   */
  shipping = ___('Shipping');

  /**
   * @type  {string}
   */
  total_for_next_order = ___('Total for next order');

  /**
   * @type  {string}
   */
  started = `${___('Started')}:`;

  /**
   * @type {string}
   */
  total_to_prepay = ___('Total amount to prepay');

  /**
   * @type {string}
   */
  type = `${___('Subscription type')}:`;

  /**
   * @type {string}
   */
  prepaid = ___('Prepaid');

  /**
   * @type {string}
   */
  pay_per_delivery = ___('Pay-per-delivery');

  /**
   * @type {string}
   */
  one_time_purchase = ___('One-time purchase');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}