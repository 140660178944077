//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';

export const FIXED = 'fixed';
export const PERCENTAGE = 'percentage';

export default class Discount extends AbstractParent {


  /**
   * @type  {FIXED|PERCENTAGE|'fixed'|'percentage'}
   */
  type = PERCENTAGE;

  /**
   * @type  {number}
   */
  value = 0;

  /**
   * @type  {number}
   */
  after_cycle = 0;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @param {number} price
   * @returns {number}
   */
  apply = price => {
    if (this.type === PERCENTAGE) {
      return Math.round(price * (1 - this.value / 100) * 100) / 100;
    }

    return Math.max(0, price - this.value);
  }
}