export default class PromiseWrapper {

  /**
   *
   * @param {Promise[]} promises
   * @returns {Promise<unknown[]>|Promise<{[p: string]: PromiseSettledResult<*>}>}
   */
  static allSettled(promises) {
    if (!Promise.allSettled) {
      return Promise.all(promises.map(p => Promise.resolve(p).then(value => ({
        status: 'fulfilled',
        value: value,
      }), error => ({
        status: 'rejected',
        reason: error,
      }))));
    }

    return Promise.allSettled(promises);
  }
}