import Actions from "./Actions";
import { escape } from "../../../../assets/js/helpers/utils";
import AppConfig from "../../config/config";
import resolve from "../../resolve";
import CountriesManager from "./CountriesManager";
import TemplatesManager from "./TemplatesManeger";
import SubscriptionContractProduct from '../../classes/SubscriptionContractProduct';
import {Permissions} from './Permissions';
import Api from '../api/Api';

const POPUP_OVERLAY_CLASS = 'spurit-ros__overlay';
const POPUP_CLASS = 'spurit-ros__popup';
const POPUP_COMMENT_CLASS = 'spurit-ros__comment';

export const ACTIONS = {
  CLOSE: 'close-popup',
  CANCEL: 'show-cancel-popup',
  PAUSE: 'show-pause-popup',
  RESUME: 'show-resume-popup',
  UPDATE_FREQUENCY: 'show-update-frequency-popup',
  UPDATE_NEXT_ORDER_DATE: 'show-update-next-order-date-popup',
  CHECK: 'check',
  SKIP: 'show-skip-popup',
  ORDER_NOW: 'show-order-now-popup',
  SHIPPING_ADDRESS: 'show-shipping-address-popup',
  REMOVE_PRODUCT: 'show-remove-modal',
};

export default class PopupManager {

  /**
   * @type {Object}
   */
  storage = {};

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Permissions}
   */
  #permissions;

  /**
   * @type {Api}
   */
  #api;

  /**
   *
   */
  #countries;

  /**
   * @type {SubscriptionContract}
   */
  #subscription;

  /**
   * @type {TemplatesManager}
   */
  #templatesManager;

  /**
   *
   * @type {boolean}
   */
  #invoiceMode = false;

  /**
   *
   * @type {boolean}
   */
  #disabled = false;

  /**
   *
   * @type {boolean}
   */
  #lastMouseDownOnOverlay = false;

  /**
   *
   */
  constructor () {
    this.#api = resolve(Api);
    this.#config = resolve(AppConfig);
    this.#countries = resolve(CountriesManager);
    this.#templatesManager = resolve(TemplatesManager);
    this.#permissions = resolve(Permissions);
  }

  /**
   *
   * @param {SubscriptionContract} subscription
   */
  init (subscription) {
    this.setSubscription(subscription);

    document.body.append(PopupManager.#makeOverlay());

    document.body.addEventListener('mousedown', e => {
      this.#lastMouseDownOnOverlay = e.target.classList.contains(POPUP_OVERLAY_CLASS);
    });

    document.body.addEventListener('mouseup', e => {
      if (this.#lastMouseDownOnOverlay && e.target.classList.contains(POPUP_OVERLAY_CLASS) && !this.#disabled) {
        this.closePopup();
      }
    });

    // eslint-disable-next-line complexity
    document.body.addEventListener('click', e => {
      const target = e.target.closest('[data-action]');
      if (!target) {
        return;
      }

      const action = target.getAttribute('data-action');

      if (action === ACTIONS.CLOSE) {
        if (!this.#disabled) {
          this.closePopup();
        }

        return;
      }

      if (action === ACTIONS.REMOVE_PRODUCT) {
        this.#setItemToDelete(e);
        this.showConfirmPopup(
          this.#labels.add_remove_product.sure,
          Actions.LIST.REMOVE_ITEM,
          'add_remove_product',
        );
        return;
      }

      this.handleSubscriptionActions(e);
    });

    document.body.addEventListener('change', e => {
      // cancellation reasons change handler
      if (e.target.name === 'reason') {
        this.#onReasonCheck(e.target);
        return;
      }

      // country select change handler
      if (e.target.name === 'country_code') {
        this.#updateProvinceSelect(e.target);
      }
    });
  }

  handleSubscriptionActions (e) {
    const action = e.target.getAttribute('data-action');

    // pause/resume buttons handler
    if ((action === ACTIONS.PAUSE || action === ACTIONS.RESUME) && this.#permissions.canPause) {
      const labelsGroup = action === ACTIONS.PAUSE ? 'pause' : 'resume';
      const content = this.#labels[labelsGroup].modal;
      this.showConfirmPopup(content, action.split('-')[1], labelsGroup);
      return true;
    }

    // cancel button handler
    if (action === ACTIONS.CANCEL && this.#permissions.canCancel) {
      if (this.#config.settings.cancellation_flow.show_reasons) {
        this.showCancellationReasonsPopup();
      } else {
        this.showConfirmPopup(this.#labels.cancel.modal, Actions.LIST.CANCEL_SUBSCRIPTION, 'cancel');
      }

      return true;
    }

    // save frequency button handler
    if (action === ACTIONS.UPDATE_FREQUENCY && this.#permissions.canChangeFrequency) {
      e.preventDefault();
      this.showUpdateFrequencyPopup();
      return true;
    }

    // save next order date button handler
    if (action === ACTIONS.UPDATE_NEXT_ORDER_DATE && this.#permissions.canChangeNextOrderDate) {
      e.preventDefault();
      this.showUpdateOrderDatePopup();
      return true;
    }

    // skip button handler
    if (action === ACTIONS.SKIP && this.#permissions.canSkipPayment) {
      const content = this.#labels.skip[this.#invoiceMode ? 'invoice' : 'auto'].modal;
      this.showConfirmPopup(content, Actions.LIST.SKIP_ORDER, 'skip');
      return true;
    }

    // edit shipping address button handler
    if (action === ACTIONS.SHIPPING_ADDRESS) {
      this.showAddressPopup();
      return true;
    }

    // order now button handler
    if (action === ACTIONS.ORDER_NOW && this.#permissions.canMakeAdditionalOrder) {
      this.showLoading();
      this.#api.contract.hasFailedOrder(this.#subscription.id)
        .then(response => {
          if (response) {
            this.showOrderNowWithFailedPopup();
          } else {
            this.showOrderNowPopup();
          }
        })
        .catch(() => this.closePopup());
      return true;
    }

    return false;
  }

  /**
   *
   * @param {SubscriptionContract} subscription
   */
  setSubscription (subscription) {
    this.#subscription = subscription;
    this.#invoiceMode = subscription.invoice.is_enabled;
  }

  /**
   *
   * @returns {string|null}
   */
  get selectedCancellationReason () {
    const popup = PopupManager.#currentPopup;
    if (!popup) {
      return null;
    }

    const reasonInput = popup.querySelector('input[name="reason"]:checked');
    if (!reasonInput) {
      return null;
    }

    let fixedReason = reasonInput.value;
    if (fixedReason.indexOf('.') !== fixedReason.length - 1) {
      fixedReason += '.';
    }

    let commentField = popup.querySelector('textarea');

    const activeCommentField = PopupManager.#activeComment;
    if (activeCommentField) {
      commentField = activeCommentField.querySelector('textarea');
    }

    return `${fixedReason}${commentField ? ` ${commentField.value}` : ''}`;
  }

  /**
   *
   */
  hideError () {
    const popup = PopupManager.#currentPopup;
    const errorField = popup.querySelector('.spurit-ros__error-message');

    errorField.innerText = '';
    popup.classList.remove('invalid');

    const invalids = popup.querySelectorAll('.invalid');
    [].forEach.call(invalids, invalid => invalid.classList.remove('invalid'));
  }

  /**
   *
   */
  closePopup () {
    const existingPopup = PopupManager.#currentPopup;
    if (existingPopup) {
      PopupManager.#hidePopup(existingPopup);
    }

    this.#disabled = false;
  }

  /**
   * @param {Object} data
   */
  showAddressPopup (data = null) {
    data = data || this.#subscription.shipping_address || {};
    const countries = this.#countries.get(data.country_code, data.province_code);
    const provinces = countries[data.country_code]?.provinces;

    const labels = this.#labels.shipping_address;
    const formattedLabels = Object.keys(labels).reduce((result, key) => {
      return {...result, [`${key}_label`]: labels[key]};
    }, {});

    this.showPopup(
      this.#templatesManager.renderAddressForm(data, formattedLabels, countries, provinces),
      this.#getDefaultActions(Actions.LIST.SAVE_ADDRESS, 'shipping_address'),
    );
  }

  /**
   * @param {SubscriptionContractProduct} itemToRemove
   * @param {Object} itemToAdd
   */
  showSwapConfirmation = (itemToRemove, itemToAdd) => {
    const phrases = this.#config.settings.customer_portal_labels.add_remove_product;
    const itemToRemoveTitle = itemToRemove.getFullTitle();
    const itemToAddTitle = SubscriptionContractProduct.getFullTitle(itemToAdd);
    const message = this.#templatesManager.swapItemsMessage(itemToRemoveTitle, itemToAddTitle, phrases);

    this.storage.swap = {delete: itemToRemove, add: itemToAdd.id};

    this.showPopup(message, [{
      content: phrases.cancel,
      action: ACTIONS.CLOSE,
    }, {
      content: phrases.confirm_btn,
      action: Actions.LIST.SWAP_ITEMS,
      primary: true,
    }]);
  }

  /**
   *
   */
  showCancellationReasonsPopup () {
    const content = this.#templatesManager.renderCancellationReasons(this.#config.settings.cancellation_flow);
    const popup = this.showPopup(content, [{
      content: this.#labels.cancel.ok,
      action: Actions.LIST.CANCEL_SUBSCRIPTION,
      primary: true,
    }]);

    PopupManager.#currentPopup.querySelector('[data-action="cancel"]').addEventListener(
      'click',
      e => {
        const selectedReason = popup.querySelector('input[name="reason"]:checked');
        if (!selectedReason) {
          this.showError(this.#config.settings.cancellation_flow.error_message);
          e.stopPropagation();
          return false;
        }

        return true;
      },
    );
  }

  /**
   * @param {string} content
   * @param {string} action
   * @param {string} labelsGroup
   */
  showConfirmPopup (content, action, labelsGroup) {
    content = escape(content);
    this.showPopup(content, this.#getDefaultActions(action, labelsGroup));
  }

  /**
   *
   * @param {string} message
   * @param {Array} invalidInputs
   */
  showError (message, invalidInputs = []) {
    const popup = PopupManager.#currentPopup;
    const errorField = popup.querySelector('.spurit-ros__error-message');

    errorField.innerText = message;
    popup.classList.add('invalid');

    invalidInputs.forEach(input => input.classList.add('invalid'));
  }

  /**
   *
   */
  showLoading () {
    this.showPopup(
      '<div id="spurit-ros-popup-loader" class="spurit-ros__loader"></div>',
      [],
      false,
    );

    this.#disabled = true;
  }

  /**
   *
   */
  showOrderNowPopup () {
    this.showPopup(
      this.#labels.order_now[this.#invoiceMode ? 'invoice' : 'auto'].modal,
      this.#getDefaultActions(Actions.LIST.ORDER_NOW, 'order_now'),
    );
  }

  /**
   *
   */
  showOrderNowWithFailedPopup () {
    this.showPopup(
      this.#labels.order_now.proceeding_with_failed,
      [{
        action: Actions.LIST.ORDER_NOW_BOTH,
        content: this.#labels.order_now.proceed_with_both,
      }, {
        action: Actions.LIST.ORDER_NOW_FAILED,
        content: this.#labels.order_now.proceed_with_failed,
        primary: true,
      }],
    );
  }

  /**
   *
   * @param {string} content
   * @param {Object[]} actions
   * @param {boolean} header
   * @returns {Element}
   */
  showPopup (content, actions = [], header = true) {
    this.#disabled = false;

    const overlay = PopupManager.#overlay;
    overlay.innerHTML = this.#templatesManager.renderPopup(content, actions, header);
    overlay.style.display = 'flex';

    return PopupManager.#currentPopup;
  }

  /**
   *
   */
  showUpdateFrequencyPopup () {
    this.showPopup(
      this.#labels.frequency[this.#invoiceMode ? 'invoice' : 'auto'].modal,
      this.#getDefaultActions(Actions.LIST.UPDATE_FREQUENCY, 'frequency'),
    );
  }

  /**
   *
   */
  showUpdateOrderDatePopup () {
    this.showPopup(
      this.#labels.next_payment[this.#invoiceMode ? 'invoice' : 'auto'].modal,
      this.#getDefaultActions(Actions.LIST.UPDATE_NEXT_ORDER_DATE, 'next_payment'),
    );
  }

  /**
   * @param {Object} labels
   */
  showNewProductTypePopup (labels) {
    this.showPopup(labels.how_often, [{
      action: Actions.LIST.ADD_ITEM_AS_SUBSCRIPTION,
      content: labels.basis,
      full_width: true,
    }, {
      action: Actions.LIST.ADD_ITEM_AS_ONE_TIME_PURCHASE,
      content: labels.one_time_purchase,
      full_width: true,
    }]);
  }

  /**
   *
   * @param {string} mainActionName
   * @param {string} labelsGroup
   * @returns {Object[]}
   */
  #getDefaultActions (mainActionName, labelsGroup) {
    return [{
      action: ACTIONS.CLOSE,
      content: this.#labels[labelsGroup].cancel,
    }, {
      action: mainActionName,
      content: this.#labels[labelsGroup].ok || this.#labels[labelsGroup].save,
      primary: true,
    }];
  }

  /**
   *
   * @param {Element|EventTarget} target
   */
  #onReasonCheck (target) {
    this.hideError();

    const label = target.parentElement;

    if (this.#config.settings.cancellation_flow.show_comment_field) {
      const visibleTextarea = label.parentElement.querySelector('.spurit-ros__comment.show');
      if (visibleTextarea) {
        visibleTextarea.classList.remove('show');
      }

      label.nextElementSibling.classList.add('show');
    }
  }

  /**
   *
   * @param {Element} countrySelect
   */
  #updateProvinceSelect (countrySelect) {
    const row = countrySelect.parentElement.parentElement;
    const provinceSelect = row.querySelector('select[name="province_code"]');

    if (provinceSelect) {
      provinceSelect.parentElement.remove();
    }

    const provinces = this.#countries.getProvinces(countrySelect.value);
    if (Object.keys(provinces).length) {
      const div = document.createElement('div');
      div.innerHTML = this.#templatesManager.renderLocationSelect(
        this.#labels.shipping_address.province,
        'province_code',
        Object.values(provinces),
        true,
      );

      row.appendChild(div.firstElementChild);
    }
  }

  /**
   * @param {Event} e
   */
  #setItemToDelete = e => {
    this.storage.itemToDelete = {box: false};
    this.storage.itemToDelete.id = e.target.closest('[data-line-id]')?.dataset.lineId;
    if (!this.storage.itemToDelete.id) {
      this.storage.itemToDelete.id = e.target.closest('[data-box-id]')?.dataset.boxId;
      this.storage.itemToDelete.box = true;
    }
  }

  /**
   *
   * @returns {SettingsCustomerPortalLabels}
   */
  get #labels () {
    return this.#config.settings.customer_portal_labels;
  }

  /**
   *
   * @returns {Element}
   */
  static get #currentPopup () {
    return document.querySelector(`.${POPUP_CLASS}`);
  }

  /**
   *
   * @returns {Element}
   */
  static get #overlay () {
    return document.body.querySelector(`.${POPUP_OVERLAY_CLASS}`);
  }

  /**
   *
   * @param {Element} popup
   */
  static #hidePopup (popup) {
    popup.parentElement.style.display = 'none';
    popup.remove();
  }

  /**
   *
   * @returns {HTMLDivElement}
   */
  static #makeOverlay () {
    const overlay = document.createElement('div');
    overlay.className = POPUP_OVERLAY_CLASS;
    overlay.style.display = 'none';

    return overlay;
  }

  /**
   *
   * @returns {Element}
   */
  static get #activeComment () {
    return document.body.querySelector(`.${POPUP_COMMENT_CLASS}.show`);
  }
}