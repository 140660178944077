// noinspection ES6UnusedImports
/* eslint-disable no-continue */

import Cart from "../cart/Cart";
import Product from "./widget/Product";
import Selectors from "./Selectors";
import { PAGE_CUSTOM } from "./CustomerPortal";
import WidgetManager from "./widget/WidgetManager";
import resolve from "../resolve";
import CollectionAtc from "./atc/CollectionAtc";
import Manager from "./widget/WidgetManager";
import Rules from "./Rules";

const MUTATION_REFRESH_INTERVAL = 200;
const MUTATION_REPEAT_INTERVAL = 50;

export const PAGE_COLLECTION = 'collections';
export const PAGE_PRODUCT = 'products';
export const PAGE_INDEX = 'index';
export const PAGE_SEARCH = 'search';
export const PAGE_CART = 'cart';

export default class Application {

  /**
   * @type {Selectors}
   */
  #selectors;

  /**
   * @type {Product}
   */
  #product;

  /**
   * @type {Rules}
   */
  #rules;

  /**
   * @type {Cart} cart
   */
  #cart;

  /**
   * @type {number}
   */
  #refreshTimeout;

  /**
   *
   * @type {WidgetManager[]}
   */
  #managers = [];

  /**
   * @type {number}
   */
  #lastRefreshTime = 0;

  /**
   * @type {number|null}
   */
  #mutationRepeatInterval = null;

  /**
   * @type {MutationObserver|null}
   */
  #mutationObserver = null;

  /**
   * @type {MutationObserver|null}
   */
  #pricesObserver = null;

  /**
   * @type {string[]}
   */
  static supportedPages = [PAGE_COLLECTION, PAGE_PRODUCT, PAGE_INDEX, PAGE_SEARCH, PAGE_CART, PAGE_CUSTOM];

  /**
   * @type {string[]}
   */
  static pagesWithCollections = [PAGE_INDEX, PAGE_SEARCH, PAGE_COLLECTION];

  /**
   *
   */
  constructor (page) {
    this.#selectors = resolve(Selectors, [page]);
    this.#product = resolve(Product);
    this.#rules = resolve(Rules);
    this.#cart = resolve(Cart);
  }

  /**
   * @param {string} page
   */
  run (page) {
    WidgetManager.showWidgetStub();
    if (!this.#rules.activeRules.length) {
      return;
    }

    if (this.constructor.pagesWithCollections.includes(page)) {
      resolve(CollectionAtc).setCollectionListeners();
    }

    this.#selectors.onQuickViewAppear(() => this.#showWidgets(this.#getQuickViewAppTargets()));

    const {targets, atc} = this.#getAppTargets();
    const maxWidgetsCount = this.#getMaxWidgetsCount(targets, atc);
    if (maxWidgetsCount) {
      this.#showWidgets();
    } else {
      this.#watchAtc();
    }

    this.#watchVariants();
    this.#cart.init();
  }

  /**
   * @param {boolean} quickView
   * @return {{atc: HTMLElement[], buyNow: HTMLElement[], targets: HTMLElement[]}}
   */
  #getAppTargets (quickView = false) {
    let targets;
    if (quickView) {
      targets = this.#selectors.getQuickViewWidgetTargets();
    } else {
      targets = this.#selectors.getProductFormWidgetTargets();
    }

    const atc = this.#selectors.getProductPageAtcButtons();
    const buyNow = this.#selectors.getBuyNowButtons();

    return {targets, atc, buyNow};
  }

  /**
   * @returns {{atc: HTMLElement[], buyNow: HTMLElement[], targets: HTMLElement[]}}
   */
  #getQuickViewAppTargets () {
    const {targets, atc, buyNow} = this.#getAppTargets(true);

    if (!targets.length) {
      return {targets: [], atc: [], buyNow: [], prices: []};
    }

    const newAtc = this.#getNotProcessedAtc(atc);
    const newBuyNow = this.#getNotProcessedBuyNow(buyNow);

    return {targets, atc: newAtc, buyNow: newBuyNow};
  }

  /**
   * @param {HTMLElement[]} buttons
   * @return {HTMLElement[]}
   */
  #getNotProcessedAtc = buttons => this.#getNotProcessedButtons(buttons, 'atc');

  /**
   * @param {HTMLElement[]} buttons
   * @return {HTMLElement[]}
   */
  #getNotProcessedBuyNow = buttons => this.#getNotProcessedButtons(buttons, 'buyNow');

  /**
   * @param {HTMLElement[]} buttons
   * @param {string} type
   * @return {HTMLElement[]}
   */
  #getNotProcessedButtons (buttons, type) {
    return buttons.filter(button => !this.#managers.some(manager => manager[type] === button));
  }

  /**
   * @param {HTMLElement} target
   * @param {HTMLElement} atc
   * @param {HTMLElement} buyNow
   */
  #processWidgetTarget (target, atc, buyNow) {
    this.#product.resolve(atc).then(product => {
      if (!product) {
        return;
      }

      const rule = this.#rules.getActualRuleFor(product);
      const position = this.#selectors.getWidgetPosition(target);
      this.#showWidget({target, atc, buyNow}, position, product, rule);
    });
  }

  /**
   *
   */
  #refreshWidgets () {
    this.#stopRefresh();

    const managersToRemove = [];

    this.#managers.forEach((manager, i) => {
      const {target} = manager;
      if (!target) {
        manager.removeWidget();
        managersToRemove.push(i);
        return;
      }

      this.#product.resolve(manager.atc).then(product => {
        manager.refresh(this.#rules.getActualRuleFor(product), product);
      });
    });

    this.#removeUnusedManagers(managersToRemove);
    this.#startRefresh();
  }

  /**
   * @param {number[]} unused
   */
  #removeUnusedManagers (unused) {
    unused.forEach(i => this.#managers.splice(i, 1));
  }

  /**
   *
   */
  #setPriceObserver () {
    if (this.#pricesObserver) {
      return;
    }

    const selectors = this.#selectors.get(Selectors.PRICE_INDIVIDUAL_SELECTOR);
    if (!selectors.length) {
      return;
    }

    this.#pricesObserver = new MutationObserver(records => {
      for (const record of records) {
        if (!record.removedNodes.length) {
          continue;
        }

        for (const node of record.removedNodes) {
          if (node.nodeType !== 1) {
            continue;
          }

          if (node.matches(selectors[0]) || node.querySelector(selectors[0])) {
            this.#refreshWidgets();
            return;
          }
        }
      }
    });
    this.#pricesObserver.observe(document.body, {attributes: true, childList: true, subtree: true});
  }

  /**
   *
   */
  #startRefresh () {
    this.#refreshTimeout = setTimeout(() => this.#refreshWidgets(), 1000);
  }

  /**
   *
   */
  #stopRefresh () {
    clearTimeout(this.#refreshTimeout);
  }

  /**
   * @param {Object} elements
   * @param {string} position
   * @param {Object} product
   * @param {Rule} rule
   */
  #showWidget (elements, position, product, rule) {
    const manager = resolve(Manager, [elements.target, elements.atc, elements.buyNow, position]);
    this.#managers.push(manager);

    if (rule) {
      manager.showWidget(rule, product);
    }
  }

  /**
   *
   */
  #showWidgets (elements = null) {
    const {targets, atc, buyNow} = elements || this.#getAppTargets();
    const maxWidgetsCount = this.#getMaxWidgetsCount(targets, atc);

    if (maxWidgetsCount && this.#mutationObserver) {
      this.#mutationObserver.disconnect();
      this.#mutationObserver = null;
    }

    if (maxWidgetsCount) {
      for (let i = 0; i < maxWidgetsCount; i++) {
        this.#processWidgetTarget(targets[i], atc[i], buyNow[i]);
      }

      this.#setPriceObserver();
      this.#startRefresh();
    }
  }

  /**
   *
   */
  #watchVariants () {
    document.body.addEventListener('change', e => {
      if (e.target.name === 'id' || e.target.classList.contains('single-option-selector')) {
        this.#refreshWidgets();
      }
    });
  }

  /**
   *
   */
  #watchAtc () {
    const mutationCallback = () => {
      const currentTime = new Date().getTime();
      if (currentTime - this.#lastRefreshTime < MUTATION_REFRESH_INTERVAL) {
        if (!this.#mutationRepeatInterval) {
          this.#mutationRepeatInterval = setInterval(mutationCallback, MUTATION_REPEAT_INTERVAL);
        }
        return;
      }

      this.#lastRefreshTime = currentTime;
      if (this.#mutationRepeatInterval) {
        clearInterval(this.#mutationRepeatInterval);
        this.#mutationRepeatInterval = null;
      }
      this.#showWidgets();
    };

    this.#mutationObserver = new MutationObserver(mutationCallback);
    this.#mutationObserver.observe(
      document.body, {attributes: true, childList: true, subtree: true},
    );
  }

  /**
   * @param {HTMLElement[]} targets
   * @param {HTMLElement[]} atc
   * @returns {number}
   */
  #getMaxWidgetsCount = (targets, atc) => Math.min(targets.length, atc.length);

  /**
   * @param {string} type
   * @returns {boolean}
   */
  static supportsPage = type => Application.supportedPages.includes(type);
};