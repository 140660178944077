//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsSkipInvoice extends AbstractParent {


  /**
   * @type  {string}
   */
  button = ___('SKIP NEXT INVOICE');

  /**
   * @type  {string}
   */
  modal = ___('Are you sure? Your next invoice date will be moved.');

  /**
   * @type  {string}
   */
  success = ___('Your next invoice has been skipped');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}