/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';

export default class SettingsNotificationsUpcomingPaymentReminder extends AbstractParent {

  /**
   * @type  {boolean}
   */
  is_enabled = false;

  /**
   * @type  {boolean}
   */
  from_name = '{{shop.name}}';

  /**
   * @type  {string}
   */
  email_subject = ___('[{{shop.name}}] You will be charged for your subscription on {{subscription.next_order_date}}');

  /**
   * @type  {string}
   */
  email_body = ___('<h2>You have an upcoming payment</h2><p>Your next subscription order will be automatically placed on {{subscription.next_order_date}}. To change your subscription details, go into your account by clicking \'Manage your subscription\'.</p>');

  /**
   * @type  {string}
   */
  portal_link_text = ___('Manage your subscription');

  /**
   * @type  {string}
   */
  store_link_text = ___('or Visit our store');

  /**
   * @type  {string}
   */
  additional_instruction = ___('If you have any questions, reply to this email or contact us at {{shop.email}}');

  /**
   * @type  {string}
   */
  phrase_to_unsubscribe = ___('Unsubscribe from these notifications');

  /**
   * @type  {boolean}
   */
  include_products = true;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}