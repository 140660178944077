import {COLLECTIONS, PRODUCTS, STORE} from '../classes/Rule';
import resolve from '../resolve';
import AppConfig from '../config/config';
import ProductSelectorApi from './ProductSelector/Api';

const DATE_AFTER_OLD_RULE_PRIORITY = Date.parse('2023-03-21');

export default class Rules {

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Object}
   */
  #cache = {};

  /**
   *
   */
  constructor () {
    this.#config = resolve(AppConfig);
  }

  /**
   * @returns {Rule[]}
   */
  get activeRules () {
    return this.#config.rules.filter(rule => rule.is_enabled);
  }

  /**
   * @param {Object} product
   * @returns {Rule}
   */
  getActualRuleFor = product => {
    if (!product) {
      return null;
    }

    const ruleFromCache = this.#cache[product.selected_variant.id];
    if (ruleFromCache) {
      return ruleFromCache;
    }

    const actualRules = this.activeRules.filter(rule => this.canBeAppliedToProduct(rule, product));
    const blockedByBoxRule = actualRules.some(rule => {
      return this.#config.boxes.find(box => box.rule_id === rule.id && box.hide_widget);
    });
    if (blockedByBoxRule) {
      return null;
    }

    this.#cache[product.selected_variant.id] = actualRules.reduce((actualRule, rule) => {
      if (!actualRule) {
        return rule;
      }

      const installed = this.#config.snippet.appInstalled;
      const checkTarget = installed ? DATE_AFTER_OLD_RULE_PRIORITY > Date.parse(installed) : true;
      return rule.hasGreaterPriority(actualRule, checkTarget) ? rule : actualRule;
    }, null);

    return this.#cache[product.selected_variant.id];
  };

  /**
   * @param {Rule} rule
   * @param {Object} product
   * @returns {boolean}
   */
  canBeAppliedToProduct = (rule, product) => {
    if (!rule.invoice.is_enabled && !this.#config.isEligible) {
      return false;
    }

    if (rule.apply_to === COLLECTIONS) {
      let targetCollection = null;
      rule.collections.forEach(id => {
        if (!product.collections.includes(id)) {
          return true;
        }

        targetCollection = id;
        return false;
      });

      return !!targetCollection;
    }

    if (rule.apply_to === PRODUCTS) {
      return rule.products.some(p => {
        return p.pid === product.id && (!p.vid || p.vid === product.selected_variant.id);
      });
    }

    return true;
  }

  /**
   * @param {SubscriptionContract} subscription
   * @returns {Rule[]}
   */
  getSameAsSubscription = subscription => this.#config.rules.filter(rule => rule.isSameAsSubscription(subscription));

  /**
   * @param {SubscriptionContract} subscription
   * @returns {Promise}
   */
  getProductVariantIdsForSubscription = subscription => {
    return this.convertTargetsToIds(this.getTargetsAllowedForSubscription(subscription));
  };

  /**
   * @param {SubscriptionContract} subscription
   * @returns {{cids: number[], items: {pid: number, vid: number}[]}|null}
   */
  getTargetsAllowedForSubscription = subscription => {
    const targets = {items: [], cids: []};
    const actualRules = this.getSameAsSubscription(subscription);
    if (actualRules.some(r => r.apply_to === STORE)) {
      return null;
    }

    actualRules.forEach(rule => {
      const ruleTargets = rule.targets;
      targets.items = [...targets.items, ...ruleTargets.items];
      targets.cids = [...targets.cids, ...ruleTargets.cids];
    });

    return targets;
  };

  /**
   * @param {Rule} rule
   * @returns {Promise}
   */
  convertRuleTargetsToIds = rule => this.convertTargetsToIds(rule.targets);

  /**
   * @param {Object[]} targets
   * @returns {Promise}
   */
  convertTargetsToIds = targets => {
    return new Promise(success => {
      if (!targets) {
        success(null);
        return;
      }

      if (!targets.cids.length) {
        success(targets.items);
        return;
      }

      ProductSelectorApi.getProductsFromSpecificCollections(targets.cids).then(pids => {
        success([...targets.items, ...pids.map(pid => ({pid}))]);
      });
    });
  };
}