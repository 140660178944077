import AbstractParent from './AbstractParent';
import SubscriptionContractProduct from './SubscriptionContractProduct';
import SubscriptionContract from './SubscriptionContract';
import Box from './Box';

export default class SubscriptionContractBox extends AbstractParent {
  /**
   * @param {number}
   */
  id;

  /**
   * @param {number}
   */
  subscription_contract_id;

  /**
   * @param {number}
   */
  box_id;

  /**
   * @param {string}
   */
  name;

  /**
   * @param {number}
   */
  quantity;

  /**
   * @type {number}
   */
  size;

  /**
   * @type {Box}
   */
  box_page;

  /**
   * @param {SubscriptionContractProduct[]}
   */
  items = [];

  /**
   * @type {SubscriptionContract}
   */
  contract;

  /**
   * @return {Object}
   */
  get classMapping () {
    return {
      items: SubscriptionContractProduct,
      contract: SubscriptionContract,
      box_page: Box,
    };
  };

  /**
   * @param  {Object} properties
   */
  constructor (properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @returns {number}
   */
  get price () {
    return this.totalPrice / this.quantity;
  }

  /**
   * @returns {number}
   */
  get totalPrice () {
    return this.items.reduce((price, item) => price + (item.price * item.quantity), 0);
  }

  /**
   * @param {number} value
   */
  setQuantity (value) {
    const quantityAdjustment = value / this.quantity;
    this.items.forEach(item => {
      item.quantity = Math.round(item.quantity * quantityAdjustment);
    });

    this.quantity = value;
  }
}