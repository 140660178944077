import * as Mustache from "mustache";
import badgeTemplate from "../../templates/badge.html";
import { makeFormattedDiscount } from "../../../../assets/js/helpers/prices";

export default class Badge {

  /**
   * @type {string}
   */
  #moneyFormat;

  /**
   * @param {string|null} moneyFormat
   */
  constructor (moneyFormat = null) {
    this.#moneyFormat = moneyFormat || Spurit?.globalSnippet?.money_format;
  }

  /**
   * @param {string} label
   * @param {Object} discount
   * @return {string}
   */
  render = (label, discount) => {
    const discountText = makeFormattedDiscount(discount, this.#moneyFormat);
    return Mustache.render(badgeTemplate, {content: (label || '').replace('{{discount}}', discountText)});
  }
}