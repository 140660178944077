//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import { DAY, MONTH, WEEK, YEAR } from './TimeInterval';
import { PAY_PER_DELIVERY, PREPAID } from "./RuleSubscriptionFrequency";
import { getNewOrderDate } from "../../../assets/js/helpers/dates";

export default class SubscriptionContractFrequency extends AbstractParent {

  /**
   * @type {number|null}
   */
  subscription_contract_id = null;

  /**
   * @type  {number}
   */
  frequency_number = 1;

  /**
   * @type  {DAY|WEEK|MONTH|YEAR|'DAY'|'WEEK'|'MONTH'|'YEAR'}
   */
  frequency_unit = MONTH;

  /**
   * @type  {PREPAID|PAY_PER_DELIVERY|'PREPAID'|'PAY_PER_DELIVERY'}
   */
  type = PAY_PER_DELIVERY;

  /**
   * @type  {number}
   */
  deliveries_per_charge = 2;

  /**
   * @type {string|number|null}
   */
  charge_day = null;

  /**
   * @type {number}
   */
  cutoff_days = 0;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {};
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @returns {boolean}
   */
  get isChargeDaySet () {
    return this.isChargeEveryMonthDay || this.isChargeEveryWeekDay;
  }

  /**
   * @returns {boolean}
   */
  get isChargeEveryWeekDay () {
    return this.frequency_unit === WEEK && this.charge_day !== null && !this.isPrepaid;
  }

  /**
   * @returns {boolean}
   */
  get isChargeEveryMonthDay () {
    return this.frequency_unit === MONTH && this.charge_day !== null && !this.isPrepaid;
  }

  /**
   * @returns {boolean}
   */
  get isPrepaid () {
    return this.type === PREPAID;
  }

  /**
   * @return {string}
   */
  get name () {
    return `${this.frequency_number} ${this.frequency_unit.toLowerCase()}${this.frequency_number > 1 ? 's' : ''}`;
  }

  /**
   * @param {string|Date|null} from
   * @returns {Date}
   */
  getNextOrderDateAfter (from = null) {
    return getNewOrderDate(this, from);
  }
}