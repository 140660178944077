import * as Mustache from 'mustache';
import { makeFormattedDiscount } from '../../../../assets/js/helpers/prices';
import { isEmpty } from '../../../../assets/js/helpers/utils';
import { SELECT, WIDGET_TYPES } from '../../classes/SettingsGeneral';
import Badge from "./Badge";
import resolve from "../../resolve";

const DISCOUNT_VARIABLE = '{{discount}}';

export default class Widget {

  /**
   * @type {Rule}
   */
  #rule;

  /**
   * @type {Object}
   */
  #settings;

  /**
   * @type {string}
   */
  #moneyFormat;

  /**
   * @type {string}
   */
  #standardPrice;

  /**
   *
   * @param {Rule} rule
   * @param {Object} settings
   * @param {string} moneyFormat
   * @param {string|null} standardPrice
   */
  constructor (
    rule,
    settings,
    moneyFormat,
    standardPrice = null,
  ) {
    this.#rule = rule;
    this.#settings = settings;
    this.#moneyFormat = moneyFormat;
    this.#standardPrice = standardPrice;
  }

  /**
   *
   * @returns {Object}
   */
  get selectedFrequency() {
    const frequencies = this.#rule.subscription_frequencies;
    return frequencies.find(f => f.checked === true) ?? frequencies[0];
  }

  /**
   *
   * @returns {string}
   */
  get #discountLabel () {
    const frequency = this.selectedFrequency;
    if (!frequency) {
      return this.#textLabels.subscribe;
    }

    const discount = this.#getFirstDiscount(frequency.discounts);
    const selectedFrequencyHasDiscount = frequency.should_apply_discount && parseInt(discount.value, 10);

    return selectedFrequencyHasDiscount ? this.#textLabels.subscribe_and_save : this.#textLabels.subscribe;
  }

  /**
   * @return {string|null}
   */
  #renderSaveBadge (frequency) {
    const discount = this.#getFirstDiscount(frequency.discounts);
    return discount?.value ? resolve(Badge, [this.#moneyFormat]).render(this.#textLabels.save_badge, discount) : null;
  }

  /**
   * @return {Object|null}
   */
  get #selectedDiscount () {
    const frequency = this.selectedFrequency;
    if (!frequency || !frequency.should_apply_discount) {
      return null;
    }

    const discount = this.#getFirstDiscount(frequency.discounts);
    return discount.value ? discount : null;
  }

  /**
   *
   * @returns {Object}
   */
  get #textLabels () {
    return this.#settings.text_labels;
  }

  /**
   * @returns {string}
   */
  get #widgetTemplate() {
    switch (this.#settings.general.widget_type) {
      case WIDGET_TYPES.TILES:
        return require('../../templates/widget-types/tiles.html');
      case WIDGET_TYPES.SEPARATED_BLOCKS:
      case WIDGET_TYPES.SOLID_BLOCK:
      default:
        return require('../../templates/widget-types/solid-block.html');
    }
  }

  /**
   *
   * @returns {string}
   */
  get #sellingPlansTemplate() {
    if (this.#settings.general.widget_type === WIDGET_TYPES.TILES) {
      return require('../../templates/selling-plans/tiles.html');
    } else if (this.#rule.subscription_frequencies.length > 1 && this.#settings.general.frequency_style === SELECT) {
      return require('../../templates/selling-plans/select.html');
    } else {
      return require('../../templates/selling-plans/radios.html');
    }
  }

  /**
   * @returns {Object}
   */
  #getFirstDiscount = discounts => discounts.find(d => !d.after_cycle);

  /**
   * @return {Object[]}
   */
  #getFormattedFrequencies () {
    return this.#rule.subscription_frequencies.map(f => ({
      checked: !!f.checked,
      label: f.name,
      position: f.position,
      subscription_price: f.subscription_price,
      price_per_delivery: f.price_per_delivery,
      save_badge: this.#renderSaveBadge(f),
      selling_plan: f.selling_plan_id,
      visible: f.visible,
      notes_for_customer: typeof f.notes_for_customer === 'string' ? f.notes_for_customer.replace(/\n/gu, "<br>") : '',
    }));
  }

  /**
   * @param {number} id
   * @param {Object[]} frequencies
   * @param {boolean} customChecked
   * @param {Object} textLabels
   * @return {string}
   */
  #getSellingPlansHtml (id, frequencies, customChecked, textLabels) {
    const frequencyLabels = this.#settings.customer_portal_labels.frequency;

    return Mustache.render(this.#sellingPlansTemplate, {
      id,
      frequencies,
      custom_checked: customChecked,
      frequencies_list_title: textLabels.frequency,
      is_frequency_number_changeable: this.#rule.is_frequency_number_changeable && !frequencies.length,
      custom_label: textLabels.custom,
      days: frequencyLabels.days,
      weeks: frequencyLabels.weeks,
      months: frequencyLabels.months,
      years: frequencyLabels.years,
    });
  }

  /**
   * Add discount to template. Don't use mustache to escape only "subscribe_label", but not "discount"
   * @param {string} template
   * @returns {string}
   */
  #getTemplateWithDiscount (template) {
    const discount = this.#selectedDiscount;
    if (!discount) {
      return template;
    }

    return template.replaceAll(DISCOUNT_VARIABLE, makeFormattedDiscount(discount, this.#moneyFormat));
  }

  /**
   *
   * @param {number} id
   * @param {Object|null} state
   * @returns {Object}
   */
  #getVariables (id = 0, state = null) {
    const frequencies = this.#getFormattedFrequencies();
    let subscribeChecked = this.#settings.general.is_enabled_subscribe || this.#rule.purchase_only_as_subscription;
    let customChecked = false;

    if (state) {
      subscribeChecked = state.subscriptionSelected;
      if (state.planId === 'custom') {
        customChecked = true;
      } else {
        frequencies[frequencies.findIndex(f => +f.selling_plan === +state.planId)].checked = true;
      }
    } else if (this.#settings.general.widget_type === WIDGET_TYPES.TILES) {
      if (subscribeChecked && !isEmpty(frequencies) && !frequencies.some(f => f.checked)) {
        frequencies[0].checked = true;
      }
    } else if (!customChecked) {
      customChecked = isEmpty(frequencies);
      if (!customChecked && !frequencies.some(f => f.checked)) {
        frequencies[0].checked = true;
      }
    }

    return {
      id,
      rule_id: this.#rule.id,
      title: this.#textLabels.widget_title,
      subscribe_label: this.#discountLabel,
      one_time_purchase_label: this.#textLabels.one_time_purchase,
      allow_one_time_purchase: !this.#rule.purchase_only_as_subscription,
      subscribe_by_default: subscribeChecked,
      price: this.#standardPrice,
      frequencies,
      hide_frequencies: frequencies.length === 1 && !frequencies[0].visible,
      widget_type: this.#settings.general.widget_type,
      selling_plans: this.#getSellingPlansHtml(id, frequencies, customChecked, this.#textLabels),
    };
  }

  /**
   *
   * @param {number} id
   * @param {Object|null} state
   * @return {string}
   */
  render (id = 0, state = null) {
    return this.#getTemplateWithDiscount(Mustache.render(this.#widgetTemplate, this.#getVariables(id, state)));
  }
}
