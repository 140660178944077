import Application from "./Application";
import CustomerPortal from "./CustomerPortal";
import Storage from "../config/Storage";
import resolve from "../resolve";
import { AppLibrary } from "../helpers/AppLibrary";

/**
 * @returns {Object}
 */
function initGlobal () {
  resolve('global').prices.money.setFormatDefault(window.Spurit.globalSnippet.money_format);
}

/**
 * @param {string} page
 * @param {string} pageName
 * @returns {CustomerPortal|Application|null}
 */
function getApp (page, pageName) {
  resolve(Storage).setLoginToken();

  return new Promise(success => {
    if (CustomerPortal.supportsPage(page, pageName)) {
      success(resolve(CustomerPortal));
    } else if (Application.supportsPage(page)) {
      success(resolve(Application, [page]));
    } else {
      success(null);
    }
  });
}

/**
 *
 */
export default function runApp () {
  initGlobal();

  const library = resolve(AppLibrary);
  const page = library.getCurrentPage();
  const pageName = library.getCurrentPageName();

  getApp(page, pageName).then(app => {
    if (app) {
      app.run(page, pageName);
    }
  });
}