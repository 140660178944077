//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SettingsCustomerPortalLabelsSkipAuto from './SettingsCustomerPortalLabelsSkipAuto';
import SettingsCustomerPortalLabelsSkipInvoice from './SettingsCustomerPortalLabelsSkipInvoice';


export default class SettingsCustomerPortalLabelsSkip extends AbstractParent {


  /**
   * @type  {string}
   */
  cancel = ___('Cancel').toUpperCase();

  /**
   * @type  {string}
   */
  ok = ___('OK');

  /**
   * @type  {SettingsCustomerPortalLabelsSkipAuto}
   */
  auto;

  /**
   * @type  {SettingsCustomerPortalLabelsSkipInvoice}
   */
  invoice;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      auto: SettingsCustomerPortalLabelsSkipAuto,
      invoice: SettingsCustomerPortalLabelsSkipInvoice,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}