import AtcAbstract from './AtcAbstract';

export default class CollectionAtc extends AtcAbstract {

  /**
   * @returns {{frequency_unit, frequency_number}}
   */
  #customFrequency;

  /**
   * @returns {{frequency_unit, frequency_number}}
   */
  get customFrequency () {
    return this.#customFrequency;
  }

  /**
   *
   * @param {{frequency_unit, frequency_number}} customFrequency
   */
  setCustomFrequency(customFrequency) {
    this.#customFrequency = customFrequency;
  }

  /**
   *
   */
  setCollectionListeners () {
    this.global.atc.onBeforeEventPrevented((event, target) => {
      return this.#isCollectionAtc(target) && this.#isAtcOnlyForSubscription(target);
    });

    this.global.atc.onBefore((event, target, lineItems, mode, next) => {
      this.#onBeforeCollectionAtc(target, lineItems, mode, next).then();
    });
  }

  /**
   *
   * @returns {boolean}
   */
  async #isAtcOnlyForSubscription (atc) {
    const product = await this.product.resolve(atc);
    if (!product) {
      return false;
    }

    const rule = this.rules.getActualRuleFor(product);
    return this.#isSellingPlanRequired(product, rule);
  }

  /**
   *
   * @param {HTMLElement} target
   * @returns {boolean}
   */
  #isCollectionAtc (target) {
    const selectors = this.global.selectors.get(this.global.constants.SELECTOR.COLLECTION_ADD_TO_CART);
    if (!selectors.length) {
      return false;
    }

    return target.matches(selectors);
  }

  /**
   *
   * @param {HTMLElement} target
   * @param {string} mode
   * @param {Array} lineItems
   * @param {function} next
   */
  async #onBeforeCollectionAtc (target, lineItems, mode, next) {
    if (!this.#isCollectionAtc(target)) {
      next(mode, lineItems);
    }

    this.setButton(target);
    const product = await this.product.resolve(target);
    if (!product) {
      next(mode, lineItems);
    }

    const rule = this.rules.getActualRuleFor(product);
    if (!this.#isSellingPlanRequired(product, rule)) {
      next(mode, lineItems);
    }

    this.#handleAtcFromCollection(lineItems, rule).finally(() => next(mode, lineItems));
  }

  /**
   *
   * @param {Array} lineItems
   * @param {Rule} rule
   * @returns {Promise}
   */
  #handleAtcFromCollection (lineItems, rule) {
    const frequency = rule.getFirstSellingPlan();
    const planId = frequency.selling_plan_id;

    if (planId) {
      return this.handleFixedFrequency(planId, rule, lineItems);
    } else {
      const customFrequency = {
        frequency_number: frequency.frequency_number.toString(),
        frequency_unit: this.config.getPeriodLabel(frequency.frequency_unit),
      };
      return this.handleCustomFrequency(customFrequency, rule, lineItems);
    }
  }

  /**
   *
   * @param {Object} product
   * @param {Rule} rule
   * @returns {boolean}
   */
  #isSellingPlanRequired (product, rule) {
    return rule && (product.requires_selling_plan || rule.purchase_only_as_subscription);
  }

}