import Mustache from 'mustache';
import template from '../templates/upsells/cart/widget.mustache';
import {makeFormattedDiscount, moneyNewCurrency} from '../../../assets/js/helpers/prices';
import {CENTS_IN_DOLLAR} from '../components/widget/Prices';

export default class Widget {

  /**
   * @param {Object} phrases
   * @param {Rule} rule
   * @param {Object} item
   * @param {string} moneyFormat
   * @param {boolean} enabled
   * @returns {string}
   */
  static render = (phrases, rule, item, moneyFormat, enabled = false) => {
    return Mustache.render(template, Widget.#getVariables(phrases, rule, item, moneyFormat, enabled));
  }

  /**
   * @param {Object} phrases
   * @param {Rule} rule
   * @param {Object} item
   * @param {string} moneyFormat
   * @param {boolean} enabled
   * @returns {Object}
   */
  static #getVariables = (phrases, rule, item, moneyFormat, enabled = false) => {
    const selectedFrequency = this.#setSelectedFrequency(rule);
    const discount = Widget.#getActualDiscount(rule);
    const pricePerDelivery = Widget.#getPricePerDelivery(item, discount);

    const pricePerCharge = pricePerDelivery * selectedFrequency.deliveries_per_charge;
    const perDeliveryLabel = phrases.per_delivery_label
      ? this.#getPerDeliveryLabel(phrases.per_delivery_label, pricePerDelivery, pricePerCharge, moneyFormat)
      : null;

    return {
      subscribe_label: Widget.#getSubscribeLabel(phrases, moneyFormat, rule.alwaysHasDiscount ? discount : null),
      price_per_charge: moneyNewCurrency(pricePerCharge, moneyFormat),
      manyFrequencies: rule.subscription_frequencies.length > 1,
      frequency_label: phrases.frequency_label,
      per_delivery_label: perDeliveryLabel,
      new_price_label: phrases.new_price,
      update_label: phrases.update,
      item_key: item.key,
      enabled,
      rule,
    };
  }

  /**
   * @param {Rule} rule
   * @returns {RuleSubscriptionFrequency}
   */
  static #setSelectedFrequency = rule => {
    let selectedFrequency = rule.subscription_frequencies.find(f => f.selected);
    if (!selectedFrequency) {
      [selectedFrequency] = rule.subscription_frequencies;
      selectedFrequency.selected = true;
    }

    return selectedFrequency;
  }

  /**
   * @param {Rule} rule
   * @returns {Discount|null}
   */
  static #getActualDiscount = rule => {
    const selectedFrequency = rule.subscription_frequencies.find(d => d.selected);
    if (!selectedFrequency.should_apply_discount) {
      return null;
    }

    return selectedFrequency.discounts.find(d => !d.after_cycle);
  }

  /**
   * @param {Object} item
   * @param {Discount|null} discount
   * @returns {number}
   */
  static #getPricePerDelivery = (item, discount = null) => {
    if (item.price_per_delivery) {
      return item.price_per_delivery;
    }

    const pricePerDelivery = item.price / CENTS_IN_DOLLAR;
    return discount ? discount.apply(pricePerDelivery) : pricePerDelivery;
  }

  /**
   * @param {string} label
   * @param {number} pricePerDelivery
   * @param {number} pricePerCharge
   * @param {string} moneyFormat
   * @returns {string|null}
   */
  static #getPerDeliveryLabel = (label, pricePerDelivery, pricePerCharge, moneyFormat) => {
    if (pricePerDelivery === pricePerCharge) {
      return null;
    }

    return label.replace('{{price.per.delivery}}', moneyNewCurrency(pricePerDelivery, moneyFormat));
  }

  /**
   * @param {Object} phrases
   * @param {Object|null} discount
   * @param {string} moneyFormat
   * @returns {string}
   */
  static #getSubscribeLabel = (phrases, moneyFormat, discount = null) => {
    if (!discount) {
      return phrases.label_without_discount;
    }

    return phrases.label_with_discount.replace('{{discount}}', makeFormattedDiscount(discount, moneyFormat));
  }
}