//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsCancel extends AbstractParent {


  /**
   * @type  {string}
   */
  button = ___('CANCEL SUBSCRIPTION');

  /**
   * @type  {string}
   */
  contact = ___('CONTACT US TO CANCEL YOUR SUBSCRIPTION');

  /**
   * @type  {string}
   */
  modal = ___('Are you sure you want to cancel your subscription?');

  /**
   * @type  {string}
   */
  no = ___('NO');

  /**
   * @type  {string}
   */
  yes = ___('YES');

  /**
   * @type  {string}
   */
  ok = ___('OK');

  /**
   * @type  {string}
   */
  success = ___('Your subscription has been cancelled');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}