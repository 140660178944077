import AbstractParent from './AbstractParent';

export const DISCOUNT_TYPES = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  EXACT_COUNT: 'EXACT_COUNT',
  COUNT: 'COUNT',
  AMOUNT: 'AMOUNT',
};

export default class BoxDiscount extends AbstractParent {

  /**
   * @type {number}
   */
  id;

  /**
   * @type {number}
   */
  value;

  /**
   * @type {number|null}
   */
  box_size_id;

  /**
   * @type {number|null}
   */
  min_items = null;

  /**
   * @type {number|null}
   */
  min_amount = null;

  /**
   * @type {string}
   */
  type;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @param {number} price
   * @returns {number}
   */
  apply = price => Math.round(price * (1 - this.value / 100) * 100) / 100;
}