import WidgetManager from '../widget/WidgetManager';
import AtcAbstract, { CUSTOM_FREQUENCY } from './AtcAbstract';
import { CUSTOM_FREQUENCY_NUMBER_SELECTOR, CUSTOM_FREQUENCY_UNIT_SELECTOR, SELECTED_SELLING_PLAN_SELECTOR } from '../Selectors';

export default class WidgetAtc extends AtcAbstract {

  /**
   * @type {HTMLElement}
   */
  #widget;

  /**
   * @type {HTMLElement}
   */
  button;

  /**
   *
   * @type {boolean}
   */
  #isListenersSet = false;

  /**
   * @returns {{frequency_unit, frequency_number}}
   */
  get customFrequency () {
    return {
      frequency_number: this.#widget.querySelector(CUSTOM_FREQUENCY_NUMBER_SELECTOR).value,
      frequency_unit: this.#widget.querySelector(CUSTOM_FREQUENCY_UNIT_SELECTOR).value,
    };
  }

  /**
   *
   * @param {HTMLElement|null} widget
   * @param {HTMLElement} button
   * @returns {WidgetAtc}
   */
  setElements (button, widget = null) {
    super.setButton(button);
    this.#widget = widget;

    return this;
  }

  /**
   *
   * @param {Rule} rule
   * @returns {string}
   */
  getSelectedFrequencyName (rule) {
    const planId = this.sellingPlanElement.value;
    return planId === CUSTOM_FREQUENCY ? this.getCustomFrequencyName() : this.getFixedFrequencyName(rule, planId);
  }

  /**
   *
   * @returns {string}
   */
  getCustomFrequencyName () {
    const frequency = this.customFrequency;
    return `${frequency.frequency_number} ${this.config.getPeriodLabel(frequency.frequency_unit)}`;
  }

  /**
   *
   * @param {Rule} rule
   * @returns {string}
   */
  async getSelectedSellingPlanId (rule) {
    let planId = this.sellingPlanElement.value;

    if (planId === CUSTOM_FREQUENCY) {
      planId = await this.prepareNewSellingPlan(rule, this.customFrequency);
    }

    return planId;
  }

  /**
   *
   */
  setWidgetListeners () {
    if (this.#isListenersSet) {
      return;
    }

    this.global.atc.onBeforeEventPrevented((event, target) => this.#isButtonWithWidget(target));

    this.global.atc.onBefore((event, target, lineItems, mode, next) => {
      if (!this.#isButtonWithWidget(target)) {
        next(mode, lineItems);
        return;
      }

      this.#handle(lineItems).finally(() => next(mode, lineItems));
    });

    this.#isListenersSet = true;
  }

  /**
   * @returns {HTMLElement|null}
   */
  get sellingPlanElement () {
    return this.#widget ? this.#widget.querySelector(SELECTED_SELLING_PLAN_SELECTOR) : null;
  }

  /**
   *
   * @param {Object[]} lineItems
   * @returns {Promise}
   */
  #handle (lineItems) {
    const ruleId = +this.#widget.getAttribute('data-rule-id');
    const rule = this.config.rules.find(r => r.id === ruleId);
    const planId = this.sellingPlanElement.value;

    if (planId === CUSTOM_FREQUENCY) {
      return this.handleCustomFrequency(this.customFrequency, rule, lineItems);
    } else {
      return this.handleFixedFrequency(planId, rule, lineItems);
    }
  }

  /**
   *
   * @param {HTMLElement} target
   * @returns {boolean}
   */
  #isButtonWithWidget (target) {
    return target === this.button && WidgetManager.isSubscriptionOn(this.#widget);
  }
}