import AbstractParent from './AbstractParent';

export default class NotificationCustomizations extends AbstractParent {

  /**
   * @type  {string}
   */
  accent_color = '#1990c6';

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {};
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}