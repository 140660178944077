import resolve from "../../resolve";
import AppConfig from "../../config/config";

export default class Product {

  /**
   * @type {Object}
   */
  lastResolvedProduct;

  /**
   * @type {AppConfig}
   */
  #config;

  /**
   * @type {Object}
   */
  #global;

  /**
   *
   */
  constructor () {
    this.#config = resolve(AppConfig);
    this.#global = resolve('global');
  }

  /**
   *
   * @param {HTMLElement} atc
   * @returns {Promise}
   */
  resolve (atc) {
    return new Promise(success => {
      this.#global.atc.lineItemByTargetElement(atc, item => {
        const options = this.#getOptions(atc);
        if (!item.id && !options.length) {
          success(null);
          return;
        }

        const product = this.#resolveByParams(item.id, options);
        this.lastResolvedProduct = product;
        success(product);
      });
    });
  }

  /**
   *
   * @param {HTMLElement} atc
   * @returns {string[]}
   */
  #getOptions(atc) {
    const {selectedLineItem} = this.#config.customization;
    let options;
    if (selectedLineItem.optionsSelector) {
      options = this.#global.utils.closestElements(atc, selectedLineItem.optionsSelector).map(option => {
        if (option.tagName === 'INPUT' && option.type === 'radio' && option.checked) {
          return option.value;
        }
        if (option.tagName === 'SELECT' && option.type === 'select-one') {
          return option.value;
        }
        return false;
      })
        .filter(option => !!option);
    } else {
      options = selectedLineItem.getOptions(atc);
    }

    return options;
  }

  /**
   * @param {Object} product
   * @param {number} vid
   * @return {Object}
   */
  #getVariantById = (product, vid) => product.variants.find(v => v.id === vid);

  /**
   * @param {Object} product
   * @param {string[]} options
   * @return {Object}
   */
  #getVariantByOptions = (product, options) => {
    return product.variants.find(v => v.options.every(o => options.includes(o)));
  }

  /**
   * @param {number|null} vid
   * @param {string[]} options
   * @returns {Object}
   */
  #resolveByParams = (vid = null, options = []) => {
    let product = null;

    Object.values(this.#config.snippet.products).forEach(p => {
      const variant = vid ? this.#getVariantById(p, vid) : this.#getVariantByOptions(p, options);
      if (!variant) {
        return true;
      }

      product = p;
      product.selected_variant = variant;

      return false;
    });

    return product;
  }
}