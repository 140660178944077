const MIN_VALUE = 1;
const MAX_VALUE_THREE_DIGIT = 999;

export default class Validator {

  /**
   * @type {Object}
   */
  static INPUTS = {
    PRODUCT_QUANTITY: 'spurit-ros-product-quantity',
  };

  /**
   *
   */
  init () {
    document.body.addEventListener('input', e => {
      if (e.target.name === Validator.INPUTS.PRODUCT_QUANTITY) {
        this.onProductQuantityInput(e.target);
      }
    });

    document.body.addEventListener('focusout', e => {
      if (e.target.name === Validator.INPUTS.PRODUCT_QUANTITY && e.target.value === '') {
        e.target.value = 1;
      }
    });
  }

  /**
   * @param {HTMLElement} input
   */
  onProductQuantityInput(input) {
    const newValue = parseInt(input.value, 10);
    if (newValue < 1) {
      input.value = '';
    } else {
      input.value = Math.min(Math.max(newValue, MIN_VALUE), MAX_VALUE_THREE_DIGIT);
    }
  }
}