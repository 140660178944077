//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SettingsCustomerPortalLabelsNextPaymentAuto from './SettingsCustomerPortalLabelsNextPaymentAuto';
import SettingsCustomerPortalLabelsNextPaymentInvoice from './SettingsCustomerPortalLabelsNextPaymentInvoice';


export default class SettingsCustomerPortalLabelsNextPayment extends AbstractParent {


  /**
   * @type  {string}
   */
  button = ___('Change date');

  /**
   * @type  {string}
   */
  dismiss = ___('Cancel');

  /**
   * @type  {string}
   */
  save = ___('Save');

  /**
   * @type  {string}
   */
  cancel = ___('Cancel').toUpperCase();

  /**
   * @type  {string}
   */
  ok = ___('OK');

  /**
   * @type  {SettingsCustomerPortalLabelsNextPaymentAuto}
   */
  auto;

  /**
   * @type  {SettingsCustomerPortalLabelsNextPaymentInvoice}
   */
  invoice;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      auto: SettingsCustomerPortalLabelsNextPaymentAuto,
      invoice: SettingsCustomerPortalLabelsNextPaymentInvoice,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}