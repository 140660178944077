//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import Product from './Product';
import Invoice from './Invoice';
import RuleSubscriptionFrequency from './RuleSubscriptionFrequency';
import RuleProductVariant from './RuleProductVariant';

export const STORE = 'STORE';
export const COLLECTIONS = 'COLLECTIONS';
export const PRODUCTS = 'PRODUCTS';

export default class Rule extends AbstractParent {

  /**
   * @type  {number}
   */
  id;

  /**
   * @type  {number}
   */
  selling_plan_group_id;

  /**
   * @type  {number}
   */
  shop_id;

  /**
   * @type  {Date}
   */
  deleted_at = null;

  /**
   * @type  {string}
   */
  name;

  /**
   * @type  {STORE|COLLECTIONS|PRODUCTS|'STORE'|'COLLECTIONS'|'PRODUCTS'}
   */
  apply_to = PRODUCTS;

  /**
   * @type  {Product[]}
   */
  products;

  /**
   * @type  {int[]}
   */
  inner_variants;

  /**
   * @type  {int[]}
   */
  collections;

  /**
   * @type  {boolean}
   */
  is_enabled = true;

  /**
   * @type  {boolean}
   */
  api_data_deleted = false;

  /**
   * @type  {boolean}
   */
  is_frequency_number_changeable = false;

  /**
   * @type  {Invoice}
   */
  invoice;

  /**
   * @type  {boolean}
   */
  purchase_only_as_subscription = false;

  /**
   * @type  {RuleSubscriptionFrequency[]}
   */
  subscription_frequencies;

  /**
   * @type  {RuleProductVariant[]}
   */
  product_variants;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      products: Product,
      invoice: Invoice,
      subscription_frequencies: RuleSubscriptionFrequency,
      product_variants: RuleProductVariant,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @returns {boolean}
   */
  get alwaysHasDiscount () {
    return !this.subscription_frequencies.some(f => {
      return !f.should_apply_discount || !f.discounts.some(d => (!d.after_cycle && d.value));
    });
  }
}