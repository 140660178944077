import AbstractParent from './AbstractParent';
import CartUpsellTexts from './CartUpsellTexts';

export default class CartUpsell extends AbstractParent {

  /**
   * @type  {number}
   */
  shop_id;

  /**
   * @type  {boolean}
   */
  enabled = false;

  /**
   * @type  {boolean}
   */
  show_modal = true;

  /**
   * @type  {CartUpsellTexts|null}
   */
  texts = null;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      texts: CartUpsellTexts,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}