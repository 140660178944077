import RuleC, { COLLECTIONS, PRODUCTS, STORE } from '../classes/Rule';
import { getPhpDate } from "../../../assets/js/helpers/dates";
import { MONTH } from "../classes/TimeInterval";
import { FIXED, PERCENTAGE } from "../classes/Discount";
import { makeFormattedDiscount } from "../../../assets/js/helpers/prices";
import {compareDiscounts, compareDiscountValues, defaultsDeep} from '../helpers/utils';
import { makeIntervalLabel } from "../../../assets/js/helpers/utils";
import RuleSubscriptionFrequency from "../classes/RuleSubscriptionFrequency";

export const DEFAULT_AUTOCHARGE_NOTES = ___('You can edit or cancel your subscriptions anytime, based on your needs.');
export const DEFAULT_INVOICE_NOTES = ___('Starting from the second order you\'ll receive a payment link and won\'t be charged unless you choose to. Delivery right to your door!');

const DISCOUNT_VARIABLE = '{{discount}}';
const MAX_PERCENTS = 100;

/**
 * @type {(string)[]}
 */
const APPLY_TO_PRIORITY = [STORE, COLLECTIONS, PRODUCTS];

export default class Rule extends RuleC {

  /**
   * @type {{name: string}}
   */
  static #default = {
    id: null,
    name: Rule.defaultName,
    products: [],
    collections: [],
    invoice: Rule.defaultInvoice,
  };

  /**
   * @param {object} properties
   */
  constructor (properties = {}) {
    properties = defaultsDeep(properties, Rule.#default);
    if (!properties.subscription_frequencies) {
      properties.subscription_frequencies = [Rule.defaultFrequency];
      properties.subscription_frequencies[0].notes_for_customer = properties.invoice.is_enabled
        ? DEFAULT_INVOICE_NOTES
        : DEFAULT_AUTOCHARGE_NOTES;
    }

    properties.subscription_frequencies.forEach(frequency => {
      frequency.discounts.forEach(discount => {
        discount.value = parseFloat(discount.value);
      });
    });

    super(properties);

    this.subscription_frequencies.sort((a, b) => {
      return a.position - b.position;
    });
  }

  /**
   * @returns {int[]}
   */
  get productIds () {
    return [...this.products, ...this.inner_variants.map(v => v.variant_id)];
  }

  /**
   * @returns {{cids: number[], items: {pid: number, cid: number}[]}|null}
   */
  get targets () {
    if (this.apply_to === STORE) {
      return null;
    }

    const targets = {items: [], cids: []};
    if (this.apply_to === PRODUCTS) {
      targets.items = this.products;
    }

    if (this.apply_to === COLLECTIONS) {
      targets.cids = this.collections;
    }

    return targets;
  }

  /**
   * @returns {object}
   */
  static get defaultDiscount () {
    return {type: PERCENTAGE, value: 0, after_cycle: 0};
  }

  /**
   * @returns {object}
   */
  static get defaultFrequency () {
    const frequency = { frequency_number: 1, frequency_unit: MONTH };

    return {
      name: makeIntervalLabel(frequency, false, ___),
      frequency,
      visible: true,
      discounts: [],
    };
  }

  /**
   * @returns {object}
   */
  static get defaultInvoice () {
    return {
      remind_after_number_of_days: 1,
      stop_sending_after_unit: MONTH,
      stop_sending_after_number: 1,
    };
  }

  /**
   * @returns {string}
   */
  static get defaultName () {
    return `Rule (${getPhpDate()})`;
  }

  /**
   * @returns {{value_type: (string), title: string, value: number}|null}
   */
  getDraftOrderDiscount (sellingPlanId) {
    const discount = this.getActualDiscount(sellingPlanId);
    if (!discount || !discount.value) {
      return null;
    }

    return {
      value: discount.value,
      value_type: discount.type === FIXED ? 'fixed_amount' : 'percentage',
      title: 'Discount',
    };
  }

  /**
   * @param {number} price
   * @param {number} sellingPlanId
   * @returns {number|null}
   */
  getPricePerDelivery (price, sellingPlanId) {
    return this.#getDiscountedPrice(price, sellingPlanId);
  }

  /**
   * @param {number} price
   * @param {number} sellingPlanId
   * @returns {number}
   */
  getSubscriptionPrice (price, sellingPlanId) {
    const deliveriesPerCharge = this.getDeliveriesPerCharge(sellingPlanId);
    const pricePerDelivery = this.getPricePerDelivery(price, sellingPlanId);

    return pricePerDelivery * deliveriesPerCharge;
  }

  /**
   * @param {number|null} sellingPlanId
   * @returns {null|Discount}
   */
  getActualDiscount (sellingPlanId = null) {
    if (this.is_frequency_number_changeable && !this.subscription_frequencies.length) {
      return this.discount || null;
    }

    return this.getDiscountBySellingPlanId(sellingPlanId);
  }

  /**
   * @param {number|null} sellingPlanId
   * @returns {number}
   */
  getDeliveriesPerCharge (sellingPlanId = null) {
    const frequency = this.getFrequencyBySellingPlanId(sellingPlanId);
    return frequency ? frequency.deliveries_per_charge : 1;
  }

  /**
   * @param {Number} id
   * @returns {Discount|null}
   */
  getDiscountBySellingPlanId (id) {
    const frequency = this.getFrequencyBySellingPlanId(id);
    const shouldApplyDiscount = frequency && frequency.should_apply_discount;
    if (!shouldApplyDiscount) {
      return null;
    }

    return this.getInitialDiscount(frequency);
  }

  /**
   * @param {Object} frequency
   * @returns {Object}
   */
  getInitialDiscount = frequency => frequency.discounts[0];

  /**
   * @param {Number} sellingPlanId
   * @param {Object} textLabels
   * @param {String|null} moneyFormat
   * @returns {String}
   */
  makeLabelWithFormattedDiscount (sellingPlanId, textLabels, moneyFormat = null) {
    const frequency = this.getFrequencyBySellingPlanId(sellingPlanId);
    if (!frequency || !frequency.should_apply_discount || !frequency?.discounts.find(d => !d.after_cycle)?.value) {
      return textLabels.subscribe;
    }

    const discount = makeFormattedDiscount(frequency?.discounts.find(d => !d.after_cycle), moneyFormat);
    return textLabels.subscribe_and_save.replaceAll(DISCOUNT_VARIABLE, discount);
  }

  /**
   * @param {Number} id
   * @returns {RuleSubscriptionFrequency|null}
   */
  getFrequencyBySellingPlanId (id) {
    return this.subscription_frequencies.find(r => r.selling_plan_id === +id);
  }

  /**
   * @param {Rule} rule
   * @param {boolean} checkTarget
   * @return {boolean}
   */
  hasGreaterPriority (rule, checkTarget = false) {
    if (checkTarget && this.apply_to !== rule.apply_to) {
      return APPLY_TO_PRIORITY.indexOf(this.apply_to) > APPLY_TO_PRIORITY.indexOf(rule.apply_to);
    }

    return this.created_at > rule.created_at;
  }

  /**
   *
   * @returns {RuleSubscriptionFrequency}
   */
  getFirstSellingPlan () {
    if (!this.subscription_frequencies.length) {
      return new RuleSubscriptionFrequency(Rule.defaultFrequency);
    }

    return this.subscription_frequencies[0];
  }

  /**
   * @param {SubscriptionContract} subscription
   * @returns {boolean}
   */
  isSameAsSubscription = subscription => {
    if (!this.is_enabled) {
      return false;
    }

    if (this.invoice.is_enabled !== subscription.invoice.is_enabled) {
      return false;
    }

    const sameFrequency = this.getSameFrequencyAsSubscription(subscription);
    if (!sameFrequency) {
      return false;
    }

    const {actualDiscount} = subscription;
    const discountAfterOrders = sameFrequency.getDiscountAfterOrders(subscription.total_orders);
    if (!compareDiscountValues(actualDiscount, discountAfterOrders)) {
      return false;
    }

    const {pendingDiscount} = subscription;
    const nextRuleDiscount = sameFrequency.getDiscountAfter(discountAfterOrders);
    return compareDiscounts(pendingDiscount, nextRuleDiscount);
  }

  /**
   * @param {SubscriptionContract} subscription
   * @returns {RuleSubscriptionFrequency}
   */
  getSameFrequencyAsSubscription = subscription => {
    return this.subscription_frequencies.find(f => {
      return (
        f.frequency.frequency_number === subscription.frequency.frequency_number
        && f.frequency.frequency_unit === subscription.frequency.frequency_unit
        && f.deliveries_per_charge === subscription.frequency.deliveries_per_charge
        && f.charge_day === subscription.frequency.charge_day
        && f.cutoff_days === subscription.frequency.cutoff_days
      );
    });
  }

  /**
   * @param {number} price
   * @param {number} sellingPlanId
   * @returns {number}
   */
  #getDiscountedPrice (price, sellingPlanId) {
    const discount = this.getActualDiscount(sellingPlanId);
    if (!discount || !discount.value) {
      return price;
    }

    if (discount.type === 'percentage') {
      price -= (price * discount.value / MAX_PERCENTS);
    } else {
      price -= (discount.value / this.getDeliveriesPerCharge(sellingPlanId));
    }

    return price > 0 ? price : 0;
  }

  /**
   * @param {Rule} rule
   * @return {Rule}
   */
  static cleanRule (rule) {
    const cleanedRule = rule;

    delete cleanedRule.id;
    delete cleanedRule.shop_id;
    delete cleanedRule.selling_plan_group_id;

    cleanedRule.subscription_frequencies.forEach(frequency => delete frequency.selling_plan_id);
    cleanedRule.name = Rule.defaultName;
    cleanedRule.is_enabled = true;

    return cleanedRule;
  }
}
