//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsOrderNowAuto extends AbstractParent {


  /**
   * @type  {string}
   */
  button = ___('ORDER NOW');

  /**
   * @type  {string}
   */
  modal = ___('Are you sure? Your next order date will be moved.');

  /**
   * @type  {string}
   */
  success = ___('A new payment has been scheduled. Please refresh this page in a few minutes to check the status');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}