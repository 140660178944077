import { DAY, WEEK, YEAR } from "../../../cloud/js/classes/TimeInterval";
import { DATE_FORMAT, DAYS_IN_WEEK } from "../constants";
import moment from "moment-timezone";

/**
 * @param {string} date
 * @param {string} timezone
 * @param {string} format
 * @returns {string}
 */
function fromUtcToTimezone (date, timezone, format = DATE_FORMAT) {
  return moment
    .utc(date)
    .tz(timezone)
    .format(format);
}

/**
 * @param {Date} date
 * @param {boolean} onlyDate
 * @returns {string}
 */
function getPhpDate (date, onlyDate = false) {
  return moment(date)
    .utc()
    .format(`YYYY-MM-DD${onlyDate ? '' : ' HH:mm:ss'}`);
}

/**
 * @param {string|number} chargeDay
 * @param {string|number} adjustmentDelivery
 * @param {moment.Moment} date
 * @returns {Moment}
 */
function getMonthDateAccordingChargeDay (chargeDay, adjustmentDelivery, date) {
  if (!chargeDay) {
    return date.add(adjustmentDelivery, 'months');
  }
  if (chargeDay > date.daysInMonth()) {
    chargeDay = date.daysInMonth();
  }
  if (chargeDay === date.date()) {
    date.add(adjustmentDelivery, 'months');
  } else {
    date
      .startOf('month')
      .date(chargeDay)
      .add(adjustmentDelivery, 'months');
  }

  return date;
}

/**
 * @param {string|number} chargeDay
 * @param {string|number} adjustmentDelivery
 * @param {moment.Moment} date
 * @returns {Moment}
 */
function getWeekDateAccordingChargeDay (chargeDay, adjustmentDelivery, date) {
  const nextDate = date.clone().add(adjustmentDelivery, 'weeks');
  //chargeDay not selected
  if (isNaN(chargeDay)) {
    return nextDate;
  }
  //nextDate not Sunday, but chargeDay is Sunday
  if (nextDate.day() !== 0 && chargeDay === 0) {
    //next Sunday
    chargeDay = DAYS_IN_WEEK;
  }

  return nextDate.day(chargeDay);
}

/**
 *
 * @param {Object} frequency
 * @param {moment.Moment|null} from
 * @returns {moment.Moment}
 */
function getDateAfterInterval (frequency, from = null) {
  const adjustmentDelivery = frequency.frequency_number * frequency.deliveries_per_charge;
  const chargeDay = parseInt(frequency.charge_day, 10);

  let date = from ? from : moment.utc();

  switch (frequency.frequency_unit.toUpperCase()) {
    case DAY:
      date = date.add(adjustmentDelivery, 'days');
      break;
    case WEEK:
      date = getWeekDateAccordingChargeDay(chargeDay, adjustmentDelivery, date);
      break;
    case YEAR:
      date = date.add(adjustmentDelivery, 'years');
      break;
    default:
      date = getMonthDateAccordingChargeDay(chargeDay, adjustmentDelivery, date);
      break;
  }

  return date;
}

/**
 *
 * @param {Object} newFrequency
 * @param {string} from
 * @returns {Date}
 */
function getNewOrderDate (newFrequency, from) {
  const now = moment.utc();
  const fromMoment = moment.utc(from);

  let date = fromMoment.clone();

  do {
    date = getDateAfterInterval(newFrequency, date);
  } while (date < now);

  return date.set({
    hour: fromMoment.hour(),
    minute: fromMoment.minute(),
    second: fromMoment.second(),
  }).toDate();
}

/**
 * @returns {Date}
 */
function getTomorrow () {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return tomorrow;
}

export {
  getPhpDate,
  getDateAfterInterval,
  getMonthDateAccordingChargeDay,
  getNewOrderDate,
  getTomorrow,
  fromUtcToTimezone,
};
