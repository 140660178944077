import AppConfig from "./config/config";
import { Env } from "./config/env";
import { Snippet } from "./config/snippet";
import { Customization } from "./config/customization";
import { AppLibrary } from "./helpers/AppLibrary";
import { Loader } from "./components/Loader";
import { Settings } from "./config/settings";

/**
 * @type {Map<any, any>}
 */
let singletons = null;

function initSingletons () {
  const env = new Env();
  const library = new AppLibrary(env.appName);
  const snippet = new Snippet(env.appName, library);
  const customization = new Customization(env.appName);
  const settings = new Settings(env.appName);
  const loader = new Loader(library, env.appName);
  const config = new AppConfig(env, snippet, customization);

  singletons = new Map([
    ['global', () => Spurit?.global],
    [AppConfig, config],
    [AppLibrary, library],
    [Loader, loader],
    [Settings, settings],
  ]);
}

/**
 * @param {*} className
 * @param {array} args
 * @returns {*}
 */
const resolve = (className, args = []) => {
  if (!singletons) {
    initSingletons();
  }

  if (!singletons.has(className)) {
    // eslint-disable-next-line new-cap
    return new className(...args);
  }

  const instance = singletons.get(className);
  return typeof instance === 'function' ? instance(...args) : instance;
};

export default resolve;