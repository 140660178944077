/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';

export const TYPE_GOOGLE = 'google';

export default class Integration extends AbstractParent {

  /**
   * @type {number}
   */
  id;

  /**
   * @type {string|null}
   */
  name = null;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping () {
    return {};
  };

  /**
   * @param {Object} properties
   */
  constructor (properties) {
    super();
    this.setProperties(properties);
  }
}