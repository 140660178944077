//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const PAUSE_SUBSCRIPTION = 'PAUSE_SUBSCRIPTION';
export const SKIP_FAILED_ORDER = 'SKIP_FAILED_ORDER';

export default class SettingsFailedPaymentFlow extends AbstractParent {


  /**
   * @type  {number}
   */
  retry_attempts = 5;

  /**
   * @type  {number}
   */
  days_before_retrying = 1;

  /**
   * @type  {CANCEL_SUBSCRIPTION|PAUSE_SUBSCRIPTION|SKIP_FAILED_ORDER|'CANCEL_SUBSCRIPTION'|'PAUSE_SUBSCRIPTION'|'SKIP_FAILED_ORDER'}
   */
  max_failures_behavior = SKIP_FAILED_ORDER;

  /**
   * @type  {string}
   */
  skipped = ___('Failed order has been skipped');

  /**
   * @type  {string}
   */
  paused = ___('Subscription has been paused');

  /**
   * @type  {string}
   */
  cancelled = ___('Subscription has been cancelled');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}