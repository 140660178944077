import { AppLibrary } from "../helpers/AppLibrary";
import { defaultsDeep } from "../helpers/utils";

/**
 *
 * @param {string} appName
 * @param {AppLibrary} library
 * @constructor
 */
export const Snippet = function (appName, library) {
  const defaultSnippet = {
    appId: 0,
    shopHash: '',
    hasCartLabel: false,
    moneyFormat: '{{amount_with_comma_separator}}$',
    globalSnippet: typeof Spurit === 'undefined' ? {} : (Spurit.globalSnippet || {}),
    pageId: null,
  };

  const isSnippetSet = (
      typeof (Spurit) !== 'undefined'
      && typeof (Spurit[appName]) !== 'undefined'
      && typeof (Spurit[appName].snippet) !== 'undefined'
  );

  let snippet = defaultSnippet;
  if (isSnippetSet) {
    snippet = defaultsDeep(Spurit[appName].snippet, snippet);
  } else if (library.isPageWithSnippet()) {
    throw new Error('Snippet params is empty');
  }

  const isProductsEmpty = !snippet.products || !Object.keys(snippet.products).length;
  if (library.isPageWithWidget() && isProductsEmpty) {
    console.warn('Empty snippet products');
  }

  for (const key in snippet) {
    if ({}.hasOwnProperty.call(snippet, key)) {
      this[key] = snippet[key];
    }
  }
};