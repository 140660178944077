//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsNotificationsSubscriptionUpdated extends AbstractParent {


  /**
   * @type  {boolean}
   */
  is_enabled = true;

  /**
   * @type  {boolean}
   */
  from_name = '{{shop.name}}';

  /**
   * @type  {string}
   */
  email_subject = ___('[{{shop.name}}] Your subscription information updated');

  /**
   * @type  {string}
   */
  email_body = ___('<h2>Your subscription information has been updated.</h2><p>To view your subscription details, go into your account by clicking \'Manage your subscription\'.</p>');

  /**
   * @type  {string}
   */
  portal_link_text = ___('Manage your subscription');

  /**
   * @type  {string}
   */
  store_link_text = ___('or Visit our store');

  /**
   * @type  {string}
   */
  additional_instruction = ___('If you have any questions, reply to this email or contact us at {{shop.email}}');

  /**
   * @type  {string}
   */
  phrase_to_unsubscribe = ___('Unsubscribe from these notifications');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}