/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import NotificationCustomizations from './NotificationCustomizations';
import SettingsNotificationsNewSubscription from './SettingsNotificationsNewSubscription';
import SettingsNotificationsSubscriptionPaused from './SettingsNotificationsSubscriptionPaused';
import SettingsNotificationsSubscriptionResumed from './SettingsNotificationsSubscriptionResumed';
import SettingsNotificationsSubscriptionCancelled from './SettingsNotificationsSubscriptionCancelled';
import SettingsNotificationsPaymentFailed from './SettingsNotificationsPaymentFailed';
import SettingsNotificationsPaymentFailedLastAttempt from './SettingsNotificationsPaymentFailedLastAttempt';
import SettingsNotificationsInvoice from './SettingsNotificationsInvoice';
import SettingsNotificationsReminder from './SettingsNotificationsReminder';
import SettingsNotificationsSubscriptionUpdated from './SettingsNotificationsSubscriptionUpdated';
import SettingsNotificationsUpcomingPaymentReminder from './SettingsNotificationsUpcomingPaymentReminder';


export default class SettingsNotifications extends AbstractParent {


  /**
   * @type  {string}
   */
  previous_verified_email = null;

  /**
   * @type  {string}
   */
  sender_email = null;

  /**
   * @type  {NotificationCustomizations}
   */
  customizations;

  /**
   * @type  {SettingsNotificationsNewSubscription}
   */
  new_subscription;

  /**
   * @type  {SettingsNotificationsSubscriptionPaused}
   */
  subscription_paused;

  /**
   * @type  {SettingsNotificationsSubscriptionResumed}
   */
  subscription_resumed;

  /**
   * @type  {SettingsNotificationsSubscriptionCancelled}
   */
  subscription_cancelled;

  /**
   * @type  {SettingsNotificationsPaymentFailed}
   */
  payment_failed;

  /**
   * @type  {SettingsNotificationsPaymentFailedLastAttempt}
   */
  payment_failed_last_attempt;

  /**
   * @type  {SettingsNotificationsInvoice}
   */
  invoice;

  /**
   * @type  {SettingsNotificationsReminder}
   */
  reminder;

  /**
   * @type  {SettingsNotificationsSubscriptionUpdated}
   */
  subscription_updated;

  /**
   * @type  {SettingsNotificationsUpcomingPaymentReminder}
   */
  upcoming_payment_reminder;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      customizations: NotificationCustomizations,
      new_subscription: SettingsNotificationsNewSubscription,
      subscription_paused: SettingsNotificationsSubscriptionPaused,
      subscription_resumed: SettingsNotificationsSubscriptionResumed,
      subscription_cancelled: SettingsNotificationsSubscriptionCancelled,
      payment_failed: SettingsNotificationsPaymentFailed,
      payment_failed_last_attempt: SettingsNotificationsPaymentFailedLastAttempt,
      invoice: SettingsNotificationsInvoice,
      reminder: SettingsNotificationsReminder,
      subscription_updated: SettingsNotificationsSubscriptionUpdated,
      upcoming_payment_reminder: SettingsNotificationsUpcomingPaymentReminder,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}