import Mustache from 'mustache';
import ObjectValues from 'object.values';
import Application from "../components/Application";
import CustomerPortal from "../components/CustomerPortal";

export class AppLibrary {

  /**
   * @type {string}
   */
  appName;

  /**
   * @param {string} appName
   */
  constructor (appName) {
    this.appName = appName;
    this.__loadStatic_cache = {};

    if (!Object.values) {
      ObjectValues.shim();
    }
  }

  /**
   * @param {string} name
   * @param {string} value
   * @param {{}} options
   */
  setCookie (name, value, options = {}) {
    name = this.appName + '-' + name;
    let expires = options.expires ?? 0;

    if (typeof expires === 'number' && expires) {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      options.expires = d;
      expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + '=' + value;

    for (const propName in options) {
      if ({}.hasOwnProperty.call(options, propName)) {
        updatedCookie += '; ' + propName;
        const propValue = options[propName];
        if (propValue !== true) {
          updatedCookie += '=' + propValue;
        }
      }
    }

    document.cookie = updatedCookie;
  };

  /* eslint prefer-named-capture-group: 0 */
  /**
   * @param {string} name
   * @return {string|null}
   */
  getCookie (name) {
    name = this.appName + '-' + name;
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/gu, '\\$1') + "=([^;]*)", "u",
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
  };

  /**
   * @param {string} name
   */
  deleteCookie (name) {
    this.setCookie(name, "", {
      expires: -1,
    });
  };

  /**
   * @param {HTMLElement|string} target
   * @param {HTMLElement} element
   * @param {string} position "before", "after" or "inside"
   * @return {boolean}
   */
  appendElement (target, element, position = 'after') {
    if (!target) {
      return false;
    }
    const sibling = typeof target === 'string' ? document.querySelector(target) : target;

    if (sibling) {
      if (position === 'inside') {
        sibling.appendChild(element);
      } else if (position === 'before') {
        sibling.parentElement.insertBefore(element, sibling);
      } else if (sibling.nextElementSibling) {
        sibling.parentElement.insertBefore(element, sibling.nextElementSibling);
      } else {
        sibling.parentElement.appendChild(element);
      }
      return true;
    } else {
      throw new Error('Failed to find target element');
    }
  };

  /**
   * @return {string}
   */
  getCurrentPage () {
    const parts = AppLibrary.getUrlInfo().urlParts;
    const arrayIndex = +AppLibrary.getUrlInfo().isMultiLanguageUrl;
    if ((parts.length > 1 && parts[parts.length - 2] === 'products') || parts[1 + arrayIndex] === 'products_preview') {
      return 'products';
    }

    return parts[1 + arrayIndex] || 'index';
  };

  /**
   *
   * @returns {string|null}
   */
  getCurrentPageName () {
    const arrayIndex = +AppLibrary.getUrlInfo().isMultiLanguageUrl;
    return AppLibrary.getUrlInfo().urlParts[2 + arrayIndex] || null;
  }

  /**
   *
   * @return {boolean}
   */
  isPageWithSnippet = () => Application.supportedPages.includes(this.getCurrentPage());

  /**
   *
   * @returns {boolean}
   */
  isPageWithWidget = () => !CustomerPortal.supportsPage(this.getCurrentPage(), this.getCurrentPageName());

  /**
   * @param {Window|Node} element
   * @param {string} event
   * @param {function} handler
   * @param {boolean} useCapture
   */
  addEventListener (element, event, handler, useCapture = false) {
    if (event.indexOf(' ') !== -1) {
      event.split(' ').forEach(
        (ev) => this.addEventListener(element, ev, handler, useCapture),
      );
      return;
    }
    if (element.addEventListener) {
      element.addEventListener(event, handler, useCapture);
    } else if (element.attachEvent) {
      element.attachEvent('on' + event, handler);
    } else {
      element['on' + event] = handler;
    }
  }

  /**
   * @param {function} handler
   */
  onLoad (handler) {
    if (
      document.readyState === 'complete'
      || (document.readyState === 'interactive' && !(/MSIE *\d+\.\w+/iu).test(window.navigator.userAgent)) // в IE в этом режиме работа с DOM возможна, только если все скрипты разместить в футере
      || document.readyState === 'loaded' // Вариант для некоторых старых браузеров
    ) {
      handler();
    } else {
      this.addEventListener(
        document,
        'DOMContentLoaded',
        () => handler(),
      );
    }
  }

  /**
   * @return {{}}
   */
  requestGetVars () {
    return window.location.search
      .replace('?', '')
      .split('&')
      .reduce(
        (result, item) => {
          const parameter = item.split('=');
          result[decodeURIComponent(parameter[0])] = decodeURIComponent(parameter[1]);
          return result;
        },
        {},
      );
  }

  /**
   * @link https://github.com/janl/mustache.js
   * @return {{render: mustacheRender, parse: mustacheParse}}
   */
  mustache () {
    return Mustache;
  }

  /* eslint default-param-last: 0 */
  /* eslint no-magic-numbers: 0 */

  /* eslint @spurit/no-callbacks: 0 */
  /**
   * @param {string} method
   * @param {string} url
   * @param {*} data
   * @param {function(responseText: string, status: number, statusText: string)} callback
   */
  ajax (method = 'GET', url, data = null, callback = () => null) {
    if (typeof (XMLHttpRequest) === 'undefined') {
      throw new Error('XMLHttpRequest is not supported');
    }

    const XmlHttpObject = new XMLHttpRequest();
    url += (url.includes('?') ? '&' : '?') + 'hash=' + Math.random();
    method = method.toUpperCase();

    // Make request
    XmlHttpObject.open(method, url, true);
    XmlHttpObject.onreadystatechange = () => {
      if (XmlHttpObject.readyState === 4) {
        callback(
          XmlHttpObject.responseText,
          XmlHttpObject.status,
          XmlHttpObject.statusText,
        );
      }
    };
    if (method === 'POST') {
      XmlHttpObject.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    }
    XmlHttpObject.setRequestHeader('X-Requested-With', 'xmlhttprequest'); // HTTP_X_REQUESTED_WITH

    if (data !== null && typeof (data) !== 'string') {
      data = JSON.stringify(data);
    }
    XmlHttpObject.send(data);
  };

  /**
   * @param {string|string[]|Object|Object[]} resource one or more
   * @param {function()} callback
   * @param {string} type 'js' or 'css'
   */
  loadStatic (resource, callback = () => null, type = '') {
    if (Array.isArray(resource)) {
      if (resource.length) {
        let loadedCnt = 0;
        resource.forEach(u => this.loadStatic(
          u,
          () => {
            loadedCnt++;
            if (loadedCnt === resource.length) {
              callback();
            }
          },
          type,
        ));
      } else {
        callback();
      }
      return;
    }

    const url = resource.url || resource;
    if (typeof (this.__loadStatic_cache[url]) !== 'undefined') {
      callback();
      return;
    }

    if (resource.isLoadingAllowed && !resource.isLoadingAllowed()) {
      callback();
      return;
    }

    if (type !== 'js' && type !== 'css') {
      type = url.toLowerCase().split('?')[0].split('#')[0].split('.').pop();
    }
    if (type !== 'js' && type !== 'css') {
      throw new Error('Undefined type of static file "' + url + '"');
    }
    let tag;
    if (type === 'js') {
      tag = document.createElement('script');
      tag.type = 'text/javascript';
    } else {
      tag = document.createElement('link');
      tag.type = 'text/css';
      tag.rel = 'stylesheet';
    }
    if (tag.readyState) { // If the browser is Internet Explorer.
      tag.onreadystatechange = () => {
        if (tag.readyState === 'loaded' || tag.readyState === 'complete') {
          tag.onreadystatechange = null;
          this.__loadStatic_cache[url] = 1;
          callback();
        }
      };
    } else { // For any other browser.
      tag.onload = () => {
        this.__loadStatic_cache[url] = 1;
        callback();
      };
    }
    if (type === 'js') {
      tag.src = url;
    } else {
      tag.href = url;
    }
    document.getElementsByTagName('head')[0].appendChild(tag);
  };

  /**
   *
   * @param {HTMLElement[]} nodes
   * @param {HTMLElement|null} parent
   * @return {HTMLElement}
   */
  static findGeneralParent (nodes, parent = nodes[0].parentElement) {
    if (parent === document.body) {
      return parent;
    }

    for (const node of nodes) {
      if (node && !parent.contains(node)) {
        return AppLibrary.findGeneralParent(nodes, parent.parentElement);
      }
    }

    return parent;
  }

  /**
   * @param {array} urlParts
   * @return {boolean}
   */
  static isMultiLanguageUrl (urlParts) {
    return urlParts[1].length === 2 || urlParts[1].length === 5 && urlParts[1].charAt(2) === '-';
  }

  /**
   *
   * @returns {object}
   */
  static getUrlInfo () {
    const urlParts = window.location.pathname.split('/');
    return {
      'urlParts': urlParts,
      'isMultiLanguageUrl': AppLibrary.isMultiLanguageUrl(urlParts),
    };
  }

  /**
   *
   * @returns {string}
   */
  static getUrlLang () {
    return AppLibrary.getUrlInfo().isMultiLanguageUrl ? '/' + AppLibrary.getUrlInfo().urlParts[1] : '';
  }
}