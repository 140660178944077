import ApiBase from './ApiBase';

const ROUTES = {
  RULES: {
    ADD_PRODUCT: '/rules/{{id}}/inner-variant',
    ADD_PLAN: '/rules/{{id}}/frequencies',
  },

  ANALYTICS: {
    VIEW: '/anx/view',
    ADD: '/anx/add',
  },
};

export default class WidgetApi extends ApiBase {

  /**
   *
   * @param {Rule} rule
   * @param {number} variantId
   * @returns {Promise<Response>}
   */
  addVariantToRule (rule, variantId) {
    return fetch(
      this.makeUrl(ROUTES.RULES.ADD_PRODUCT, {id: rule.id}),
      this.makePostData({variant_id: variantId, shop_id: rule.shop_id, rule_id: rule.id}),
    );
  }

  /**
   *
   * @param {Rule} rule
   * @param {{name: string, frequency_number: number, frequency_unit: string}} frequency
   * @returns {Promise<Response>}
   */
  createSellingPlan (rule, frequency) {
    return fetch(
      this.makeUrl(ROUTES.RULES.ADD_PLAN, {id: rule.id}),
      this.makePostData({...frequency, shop_id: rule.shop_id, rule_id: rule.id}),
    ).then(response => response.text());
  }

  /**
   *
   * @param {number} offerId
   * @param {number} productId
   */
  trackAtc (offerId, productId) {
    return fetch(
      this.makeUrl(ROUTES.ANALYTICS.ADD),
      this.makePostData({shopId: +this.config.snippet.shopId, offerId, productId}),
    ).catch(e => console.log(e));
  }

  /**
   *
   * @param {number} offerId
   * @param {number} productId
   */
  trackView (offerId, productId) {
    fetch(
      this.makeUrl(ROUTES.ANALYTICS.VIEW),
      this.makePostData({shopId: +this.config.snippet.shopId, params: [{offerId, productId}]}),
    ).catch(e => console.log(e));
  }
}