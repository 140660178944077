import AccountPage from "./AccountPage";
import SubscriptionContract from "../../classes/SubscriptionContract";

const TABLE_SELECTOR = '.spurit-ros__table';
const unauthorizedStatusCode = 401;

export default class Dashboard extends AccountPage {

  /**
   *
   * @type {Object[]}
   */
  #subscriptions = [];

  /**
   * @param {Element} container
   */
  run (container) {
    container.innerHTML = this.templates.renderDashboard(
      this.config.settings.customer_portal_labels.subscriptions,
    );
    this.#addPageListeners();

    this.api.contract.getSubscriptions().then(subscriptions => {
      this.#subscriptions = subscriptions.map(s => new SubscriptionContract(s));
    })
      .catch(e => {
        if (e.status !== unauthorizedStatusCode) {
          e.json().then(errorResponse => console.error(errorResponse.message));
        }
      })
      .finally(() => this.#drawTable());
  }

  /**
   *
   */
  onResize () {
    this.#drawTable();
  }

  /**
   * @returns {Object[]}
   */
  get #filteredSubscriptions () {
    const status = Dashboard.#selectedStatus;
    if (!status) {
      return this.#subscriptions;
    }

    return this.#subscriptions.filter(subscription => subscription.status === status);
  }

  /**
   *
   */
  #addPageListeners () {
    this.addResizeListener();

    document.body.addEventListener('click', e => {
      const {target} = e;
      if (!target.classList.contains('spurit-ros__filter') || target.classList.contains('active')) {
        return;
      }

      Dashboard.#activeStatusLink.classList.remove('active');
      target.classList.add('active');

      this.#drawTable();
    });
  }

  /**
   *
   */
  #drawTable () {
    const tableWrapper = document.querySelector(TABLE_SELECTOR);
    tableWrapper.innerHTML = this.templates.renderSubscriptionsTable(
      this.variables.getDashboardVariables(this.#filteredSubscriptions),
      AccountPage.isMobile,
    );
  }

  /**
   * @returns {Element}
   */
  static get #activeStatusLink () {
    return document.querySelector('.spurit-ros__filter.active');
  }

  /**
   * @returns {string}
   */
  static get #selectedStatus () {
    return Dashboard.#activeStatusLink.getAttribute('data-status');
  }
}