export default class AbstractParent {

  get classMapping() {
    return {};
  };

  /* eslint new-cap: 0 */
  /**
   * @param  {{}} properties
   */
  setProperties(properties) {
    if (typeof properties === 'undefined') {
      return;
    }
    Object.keys(properties).forEach(propertyName => {
      if (propertyName === 'classMapping') {
        return;
      }
      const value = properties[propertyName];
      if (Array.isArray(value) && this.classMapping[propertyName]) {
        const propertyClass = this.classMapping[propertyName];
        const array = [];
        value.forEach((item) => array.push(new propertyClass(item)));
        this[propertyName] = array;
      } else if (typeof value === "object" && this.classMapping[propertyName] && value !== null) {
        const propertyClass = this.classMapping[propertyName];
        this[propertyName] = new propertyClass(value);
      } else {
        this[propertyName] = value;
      }
    });
  }

  /**
   *
   */
  checkFullness() {
    Object.getOwnPropertyNames(this).forEach(propertyName => {
      if (typeof this[propertyName] === 'undefined') {
        console.log(this[propertyName]);
        console.warn(`${this.constructor.name}.${propertyName} is empty`);
      }
    });
  }

  /**
  * @return  {object}
  */
  toSimpleObject() {
    const result = {};

    Object.entries(this).forEach(([key, value]) => {
      if (value !== null && typeof value === 'object' && typeof value.toSimpleObject === 'function') {
        result[key] = value.toSimpleObject();
      } else {
        result[key] = value;
      }
    });

    return result;
  }
}