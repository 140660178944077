//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class RuleProductVariant extends AbstractParent {


  /**
   * @type  {number}
   */
  id;

  /**
   * @type  {number}
   */
  rule_id;

  /**
   * @type  {number}
   */
  product_id;

  /**
   * @type  {number}
   */
  variant_id;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}