//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import SelectorRequired from './SelectorRequired';
import Selector from './Selector';


export default class SettingsGeneralSelectors extends AbstractParent {


  /**
   * @type  {SelectorRequired}
   */
  widget_placement;

  /**
   * @type  {SelectorRequired}
   */
  button_atc_product;

  /**
   * @type {Selector}
   */
  button_atc_collection;

  /**
   * @type  {Selector}
   */
  button_buy_now;

  /**
   * @type  {Selector}
   */
  cart_price_subtotal;

  /**
   * @type  {Selector}
   */
  price_individual;

  /**
   * @type  {Selector}
   */
  featured_product_widget;

  /**
   * @type  {Selector}
   */
  manage_subscriptions_button;

  /**
   * @type  {Selector}
   */
  quick_view_widget;

  /**
   * @type  {boolean}
   */
  is_ajax = false;

  /**
   * @type  {Selector}
   */
  ajax_cart_price_subtotal;

  /**
   * @type  {SelectorRequired}
   */
  ajax_checkout_button;

  /**
   * @type {Selector}
   */
  upsell_widget_placement_desktop;

  /**
   * @type {Selector}
   */
  upsell_widget_placement_mobile;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
      widget_placement: SelectorRequired,
      button_atc_product: SelectorRequired,
      button_atc_collection: Selector,
      button_buy_now: Selector,
      cart_price_subtotal: Selector,
      price_individual: Selector,
      featured_product_widget: Selector,
      manage_subscriptions_button: Selector,
      quick_view_widget: Selector,
      ajax_cart_price_subtotal: Selector,
      ajax_checkout_button: SelectorRequired,
      upsell_widget_placement_desktop: Selector,
      upsell_widget_placement_mobile: Selector,
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}