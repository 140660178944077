//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SubscriptionContractProduct extends AbstractParent {


  /**
   * @type  {number}
   */
  subscription_contract_id;

  /**
   * @type  {number}
   */
  product_id;

  /**
   * @type  {number}
   */
  variant_id;

  /**
   * @type  {string}
   */
  line_id;

  /**
   * @type  {number}
   */
  price;

  /**
   * @type  {number}
   */
  quantity;

  /**
   * @type  {boolean}
   */
  requires_shipping;

  /**
   * @type {string}
   */
  title;

  /**
   * @type {Object}
   */
  variant;

  /**
   * @type {number}
   */
  box_id;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }

  /**
   * @param {number} quantity
   */
  setQuantity (quantity) {
    this.quantity = quantity;
  }

  /**
   * @returns {string}
   */
  getFullTitle = () => SubscriptionContractProduct.getFullTitle(this);

  /**
   * @param {Object} item
   * @returns {string}
   */
  static getFullTitle = item => {
    if (item.variant.title === 'Default Title') {
      return item.product.title;
    }

    return `${item.product.title} - ${item.variant.title}`;
  }
}