//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsAddRemoveProduct extends AbstractParent {

  /**
   * @type {string}
   */
  add = ___('ADD PRODUCT');

  /**
   * @type {string}
   */
  add_modal = ___('Add product');

  /**
   * @type {string}
   */
  search = ___('Search products');

  /**
   * @type {string}
   */
  no_products = ___('No products found');

  /**
   * @type {string}
   */
  cancel = ___('CANCEL');

  /**
   * @type {string}
   */
  save = ___('SAVE');

  /**
   * @type {string}
   */
  sure = ___('Are you sure? This product will be removed from your subscription.');

  /**
   * @type {string}
   */
  ok = ___('OK');

  /**
   * @type {string}
   */
  added = ___('Successfully added to your subscription:');

  /**
   * @type {string}
   */
  failed = ___('Couldn’t be added to your subscription:');

  /**
   * @type {string}
   */
  removed = ___('Product has been successfully removed from your subscription.');

  /**
   * @type {string}
   */
  failed_shipping = ___('Physical products cannot be added without defining shipping address.');

  /**
   * @type {string}
   */
  no_available_products = ___('No available products found.');

  /**
   * @type {string}
   */
  how_often = ___('Choose how often you want to purchase a product');

  /**
   * @type {string}
   */
  basis = ___('SUBSCRIPTION BASIS');

  /**
   * @type {string}
   */
  one_time_purchase = ___('ONE-TIME PURCHASE');

  /**
   * @type {string}
   */
  swap_modal = ___('Swap product');


  /**
   * @type {string}
   */
  next = ___('NEXT');


  /**
   * @type {string}
   */
  confirm_btn = ___('CONFIRM');


  /**
   * @type {string}
   */
  confirm_label = ___('Confirm the product replacement for your next subscription order:');


  /**
   * @type {string}
   */
  replaced_with = ___('will be replaced with');


  /**
   * @type {string}
   */
  swap_successful = ___('Product has been successfully updated.');


  /**
   * @type {string}
   */
  swap_failed = ___('Product couldn’t be updated.');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}