import AbstractParent from './AbstractParent';

export const SUCCESS = 'SUCCESS';
export const CANCELLED = 'CANCELLED';

export default class Fulfillment extends AbstractParent {
  /**
   * @type {string}
   */
  status;

  /**
   * @type {string}
   */
  createdAt;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {};
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }

  /**
   *
   * @returns {boolean}
   */
  isFulfilled() {
    return this.status === SUCCESS;
  }
}