//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';
import {MONTH} from './TimeInterval';

export const IN_THE_MIDDLE_OF_INTERVAL = 'IN_THE_MIDDLE_OF_INTERVAL';
export const AFTER_THE_DUE_DATE = 'AFTER_THE_DUE_DATE';

export default class Invoice extends AbstractParent {


  /**
   * @type  {boolean}
   */
  is_enabled = false;

  /**
   * @type  {boolean}
   */
  should_stop_sending_invoices = false;

  /**
   * @type  {DAY|WEEK|MONTH|YEAR|'DAY'|'WEEK'|'MONTH'|'YEAR'}
   */
  stop_sending_after_unit = MONTH;

  /**
   * @type  {number}
   */
  stop_sending_after_number = 1;

  /**
   * @type  {boolean}
   */
  should_send_reminder = false;

  /**
   * @type  {IN_THE_MIDDLE_OF_INTERVAL|AFTER_THE_DUE_DATE|'IN_THE_MIDDLE_OF_INTERVAL'|'AFTER_THE_DUE_DATE'}
   */
  send_reminder_time = IN_THE_MIDDLE_OF_INTERVAL;

  /**
   * @type  {number}
   */
  remind_after_number_of_days = 1;

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {};
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}