//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsNotificationsInvoice extends AbstractParent {


  /**
   * @type  {boolean}
   */
  from_name = '{{shop.name}}';

  /**
   * @type  {string}
   */
  email_subject = ___('[{{shop.name}}] Your next invoice for your subscription');

  /**
   * @type  {string}
   */
  email_body = ___('<h2>Complete your purchase</h2><p>Thanks for your subscription!<br>We have included the payment link for your convenience so that you can receive your next order just in time!<br>You are always welcome to visit our store and we\'ll be happy to answer any questions.</p>');

  /**
   * @type  {string}
   */
  portal_link_text = ___('View or edit your subscription');

  /**
   * @type  {string}
   */
  store_link_text = ___('or Visit our store');

  /**
   * @type  {string}
   */
  checkout_link_text = ___('Complete your purchase');

  /**
   * @type  {string}
   */
  additional_instruction = ___('If you have any questions, reply to this email or contact us at {{shop.email}}');

  /**
   * @type  {string}
   */
  phrase_to_unsubscribe = ___('Cancel subscription to recurring invoices');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}