import Dashboard from "./subscriptions/Dashboard";
import Subscription from './subscriptions/View';
import Selectors, { POSITION_BEFORE } from "./Selectors";
import resolve from "../resolve";
import AppConfig from "../config/config";
import TemplatesManager from "./subscriptions/TemplatesManeger";

export const PAGE_NAME_SUBSCRIPTIONS = 'spurit-subscriptions';
export const PAGE_NAME_ORDERS = 'orders';
export const PAGE_ACCOUNT = 'account';
export const PAGE_CUSTOM = 'pages';

const BAR_WRAPPER_CLASS = 'spurit-ros__account-bar-wrapper';

export default class CustomerPortal {

  /**
   *
   * @type {string[]}
   */
  static supportedPages = [PAGE_ACCOUNT, PAGE_CUSTOM];

  /**
   *
   * @type {string[]}
   */
  static supportedPageNames = [PAGE_NAME_ORDERS, PAGE_NAME_SUBSCRIPTIONS];

  /**
   *
   */
  run (page) {
    if (page === PAGE_ACCOUNT) {
      this.runAccount();
    } else if (page === PAGE_CUSTOM) {
      const {subscriptionId} = this.constructor;
      if (subscriptionId) {
        this.runSubscription(subscriptionId);
      } else {
        this.runSubscriptions();
      }
    }
  }

  /**
   *
   */
  runAccount () {
    this.#insertBar(true);
  }

  /**
   *
   */
  runSubscriptions () {
    this.#insertBar();
    resolve(Dashboard).init();
  }

  /**
   * @param {number} id
   */
  runSubscription (id) {
    this.#insertBar();
    resolve(Subscription, [id]).init();
  }

  /**
   *
   * @param {boolean} useCustomPosition
   */
  #insertBar (useCustomPosition = false) {
    const barWrapper = document.createElement('div');
    barWrapper.className = BAR_WRAPPER_CLASS;
    barWrapper.innerHTML = resolve(TemplatesManager).renderPortalLink(
      resolve(AppConfig).settings.customer_portal_labels.general.manage_subscriptions,
    );

    const customPosition = useCustomPosition && resolve(Selectors).getManageSubscriptionsButtonPosition();

    if (customPosition.element) {
      if (customPosition.position === POSITION_BEFORE) {
        customPosition.element.before(barWrapper);
      } else {
        customPosition.element.after(barWrapper);
      }
    } else {
      document.body.prepend(barWrapper);
    }
  }

  /**
   * @param {string} type
   * @param {string|null} name
   * @returns {boolean|boolean}
   */
  static supportsPage (type, name = null) {
    return CustomerPortal.supportedPages.includes(type) && (!name || CustomerPortal.supportedPageNames.includes(name));
  }

  /**
   * @returns {number}
   */
  static get subscriptionId () {
    return +(new URLSearchParams(window.location.search)).get('id');
  }
};