//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCustomerPortalLabelsFulfillment extends AbstractParent {


  /**
   * @type  {string}
   */
  schedule = ___('Fulfillment schedule');

  /**
   * @type  {string}
   */
  date = ___('Date');

  /**
   * @type  {string}
   */
  status = ___('Status');

  /**
   * @type  {string}
   */
  fulfilled = ___('Fulfilled');

  /**
   * @type  {string}
   */
  unfulfilled = ___('Unfulfilled');

  /**
   * @type  {string}
   */
  scheduled = ___('Scheduled');

  /**
   * @type  {string}
   */
  details = ___('Details');

  /**
   * @type  {string}
   */
  change_date = ___('Change date');

  /**
   * @type  {string}
   */
  cancel = ___('Cancel');

  /**
   * @type  {string}
   */
  save = ___('Save');

  /**
   * @type  {string}
   */
  success = ___('Delivery date has been updated');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}